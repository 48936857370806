import { BASE_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiGuardianDto from '../ApiGuardianDto'
import IGuardianPoster from './IGuardianPoster'

class GuardianPoster implements IGuardianPoster {
  constructor(private readonly fetcher: IFetcher) {}

  public async attachGuardianToClients(
    clientId: string,
    guardianId: string,
    relationship: string
  ): Promise<void> {
    return await this.fetcher.fetch({
      body: {},
      method: 'POST',
      url: `${BASE_URL}clientGuardian/associate/client/${clientId}/guardian/${guardianId}?relationship=${relationship}`
    })
  }

  public async deleteRelatedClients(guardianId: string): Promise<void> {
    return await this.fetcher.fetch({
      body: {},
      method: 'DELETE',
      url: `${BASE_URL}clientGuardian/guardian/${guardianId}`
    })
  }

  public async createGuardian(guardian: ApiGuardianDto): Promise<ApiGuardianDto> {
    return await this.fetcher.fetch({
      body: guardian,
      method: 'POST',
      url: `${BASE_URL}guardian/create`
    })
  }

  public async updateGuardian(guardian: ApiGuardianDto): Promise<ApiGuardianDto> {
    return await this.fetcher.fetch({
      body: guardian,
      method: 'PUT',
      url: `${BASE_URL}guardian`
    })
  }
}

export default GuardianPoster
