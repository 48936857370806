const BcbaTableColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName'
  },
  {
    title: 'Id',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  }
]

export default BcbaTableColumns
