import { BASE_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import IPartialPersonInfoGetter from '../../EmployeeCrud/PartialEmployeeGetter/IPartialPersonInfoGetter'
import OptionObject from '../../Model/Interfaces/OptionObject'

class PartialClientGetter implements IPartialPersonInfoGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getPersonInfo(): Promise<OptionObject[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}client/partial`
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          name: `${r.firstName} ${r.lastName}`,
          id: r.uuid
        }
      })
    }

    return []
  }
}

export default PartialClientGetter
