import IBcbaPoster from '../../../BcbaCrud/BcbaCreator/IBcbaCreator'
import IBcbaGetter from '../../../BcbaCrud/BcbaGetter/Interfaces/IBcbaGetter'
import IClientCreator from '../../../ClientCrud/ClientCreator/IClientCreator'
import IClientGetter from '../../../ClientCrud/ClientGetter/IClientGetter'
import IEmployeeGetter from '../../../EmployeeCrud/EmployeeGetter/IEmployeeGetter'
import IEmployeePoster from '../../../EmployeeCrud/EmployeePoster/IEmployeePoster'
import IGuardianGetter from '../../../GuardianCrud/GuardianGetter/IGuardianGetter'
import IGuardianPoster from '../../../GuardianCrud/GuardianPoster/IGuardianPoster'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import IRegistrationGetter from '../../../Registration/RegistrationGetter/IRegistrationGetter'
import AddPersonFormPresenter from '../AddPersonForm/Presenter/AddPersonFormPresenter'
import IAddPersonFormPresenter from '../AddPersonForm/Presenter/IAddPersonFormPresenter'
import IResultTablePresenter from '../ResultTable/Presenter/IResultTablePresenter'
import ResultTablePresenter from '../ResultTable/Presenter/ResultTablePresenter'
import ISearchFormPresenter from '../SearchForm/Presenter/ISearchFormPresenter'
import SearchFormPresenter from '../SearchForm/Presenter/SearchFormPresenter'
import IDirectoryPresenter from './IDirectoryPresenter'

class DirectoryPresenter implements IDirectoryPresenter {
  private searchFormPresenter: ISearchFormPresenter
  private addPersonFormPresenter: IAddPersonFormPresenter
  private resultTablePresenter: IResultTablePresenter
  private view: ISubscribingView | null

  constructor(
    private readonly clientGetter: IClientGetter,
    private readonly employeeGetter: IEmployeeGetter,
    private readonly guardianGetter: IGuardianGetter,
    private readonly bcbaGetter: IBcbaGetter,
    private readonly registrationGetter: IRegistrationGetter,
    private readonly clientCreator: IClientCreator,
    private readonly employeePoster: IEmployeePoster,
    private readonly guardianPoster: IGuardianPoster,
    private readonly bcbaPoster: IBcbaPoster
  ) {
    this.searchFormPresenter = new SearchFormPresenter(this)
    this.addPersonFormPresenter = new AddPersonFormPresenter(
      this.clientGetter,
      this.clientCreator,
      this.employeePoster,
      this.guardianGetter,
      this.guardianPoster,
      this.bcbaPoster
    )
    this.resultTablePresenter = new ResultTablePresenter(
      this,
      this.clientGetter,
      this.clientCreator,
      this.employeePoster,
      this.guardianGetter,
      this.guardianPoster,
      this.bcbaPoster
    )
    this.view = null
    this.initialize()
  }

  public getSearchFormPresenter(): ISearchFormPresenter {
    return this.searchFormPresenter
  }

  public getAddPersonFormPresenter(): IAddPersonFormPresenter {
    return this.addPersonFormPresenter
  }

  public getResultTablePresenter(): IResultTablePresenter {
    return this.resultTablePresenter
  }

  public async searchDirectory(): Promise<void> {
    this.searchFormPresenter.setIsLoading(true)
    this.resultTablePresenter.setIsLoading(true)
    this.updateView()

    switch (this.searchFormPresenter.getFormState().entityType) {
      case 'CLIENT':
        await this.searchClients()
        break
      case 'THERAPIST':
        await this.searchTherapists()
        break
      case 'GUARDIAN':
        await this.searchGuardians()
        break
      case 'BCBA':
        await this.searchBcbas()
        break
      default:
        break
    }
    this.searchFormPresenter.setIsLoading(false)
    this.resultTablePresenter.setIsLoading(false)
    this.updateView()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private async searchClients(): Promise<void> {
    const { id, firstName, middleName, lastName, initials, serviceCenter, active } =
      this.searchFormPresenter.getFormState()
    const clients = []
    if (id) {
      const clientProfile = await this.clientGetter.getClientProfile(id)
      if (clientProfile) {
        clients.push(clientProfile)
      }
    } else {
      clients.push(
        ...(await this.clientGetter.searchClientProfiles(
          firstName,
          middleName,
          lastName,
          initials,
          serviceCenter,
          null,
          null,
          active
        ))
      )
    }
    const searchResults = await Promise.all(
      clients.map(async (client) => {
        const assignments = await this.registrationGetter.getClientAssignments(client.uuid as string)
        return {
          fullName: `${client.firstName || ''} ${client.middleName || ''} ${client.lastName || ''
            }`.trim(),
          key: client.uuid,
          therapists: assignments.map(assignment => assignment.therapist),
          bcbas: assignments.map(assignment => assignment.bcba),
          ...client
        }
      })
    )
    this.resultTablePresenter.setSearchResults(searchResults as any[], 'CLIENT')
  }

  private async searchTherapists(): Promise<void> {
    const { id, firstName, middleName, lastName, phone, email, serviceCenter, active } =
      this.searchFormPresenter.getFormState()
    const therapists = []
    if (id) {
      const therapistProfile = await this.employeeGetter.getEmployeeProfile(id)
      if (therapistProfile) {
        therapists.push(therapistProfile)
      }
    } else {
      therapists.push(
        ...(await this.employeeGetter.searchEmployeeProfiles(
          firstName,
          middleName,
          lastName,
          'THERAPIST',
          phone,
          email,
          serviceCenter,
          null,
          null,
          active
        ))
      )
    }
    const searchResults = await Promise.all(
      therapists.map(async (therapist) => {
        const assignments = await this.registrationGetter.getTherapistAssignments(therapist.uuid as string)
        return {
          fullName: `${therapist.firstName || ''} ${therapist.middleName || ''} ${therapist.lastName || ''
            }`.trim(),
          key: therapist.uuid,
          clients: assignments.map(assignment => assignment.client),
          bcbas: assignments.map(assignment => assignment.bcba),
          ...therapist
        }
      })
    )
    this.resultTablePresenter.setSearchResults(searchResults as any[], 'THERAPIST')
  }

  private async searchGuardians(): Promise<void> {
    const { id, firstName, middleName, lastName, phone, email } =
      this.searchFormPresenter.getFormState()
    const guardians = []
    if (id) {
      const guardianProfile = await this.guardianGetter.getGuardianProfile(id)
      if (guardianProfile) {
        guardians.push(guardianProfile)
      }
    } else {
      guardians.push(
        ...(await this.guardianGetter.searchGuardianProfiles(
          firstName,
          middleName,
          lastName,
          phone,
          email
        ))
      )
    }
    const searchResults = await Promise.all(
      guardians.map(async (guardian) => {
        const relatedClients = await this.clientGetter.getClientsRelatedToGuardian(
          guardian.uuid as string
        )
        return {
          fullName: `${guardian.firstName || ''} ${guardian.middleName || ''} ${guardian.lastName || ''
            }`.trim(),
          key: guardian.uuid,
          relatedClients,
          ...guardian
        }
      })
    )
    this.resultTablePresenter.setSearchResults(searchResults as any[], 'GUARDIAN')
  }

  private async searchBcbas(): Promise<void> {
    const { id, firstName, middleName, lastName, phone, email } =
      this.searchFormPresenter.getFormState()
    const bcbas = []
    if (id) {
      const bcbaProfile = await this.bcbaGetter.getBcbaProfileById(id)
      if (bcbaProfile) {
        bcbas.push(bcbaProfile)
      }
    } else {
      bcbas.push(
        ...(await this.bcbaGetter.searchBcbaProfiles(firstName, middleName, lastName, phone, email))
      )
    }
    const searchResults = bcbas.map((bcba) => ({
      fullName: `${bcba.firstName || ''} ${bcba.middleName || ''} ${bcba.lastName || ''}`.trim(),
      key: bcba.uuid,
      ...bcba
    }))
    this.resultTablePresenter.setSearchResults(searchResults as any[], 'BCBA')
  }
}

export default DirectoryPresenter
