import './style.css'
import 'antd/dist/antd.css'

import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Collapse, DatePicker, Divider, Empty, Form, Modal, Row, Tag } from 'antd'
import moment from 'moment-timezone'
import React, { Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import ValidEmployeeLeavePeriodRecordsType from '../../../Model/Records/LeavePeriodRecordsType'
import EmployeeLeaveReasonRecordsType from '../../../Model/Records/LeaveReasonRecordsType'
import ValidRequestStatusType, {
  approvedStatus,
  pendingStatus
} from '../../../Model/Types/ValidRequestStatusType'
import IRequestUpdateHandler from '../RequestUpdateHandler/IRequestUpdateHandler'
import ILeaverDashboardPresenter from './ILeaverDashboardPresenter'
import LeaveRequestWithStatus from './Interfaces/LeaveRequestWithStatus'

type Props = {
  formTitle: string
  presenter: ILeaverDashboardPresenter
  requestStatusUpdateHandler: IRequestUpdateHandler
}

type State = {
  isModalVisible: boolean
}

class LeaverDashboard extends Component<Props, State> implements ISubscribingView {
  state = {
    isModalVisible: false
  }

  componentDidMount(): void {
    const { presenter, requestStatusUpdateHandler } = this.props

    presenter.setView(this)
    requestStatusUpdateHandler.setView(this)
  }

  render(): ReactElement {
    const { formTitle, presenter, requestStatusUpdateHandler } = this.props
    const { Panel } = Collapse

    return (
      <div className='site-layout-background'>
        <Divider orientation='center'>{formTitle}</Divider>
        <Form
          initialValues={{
            startDate: presenter.getFilterStartDate()
              ? moment.tz(presenter.getFilterStartDate(), 'America/Denver')
              : null,
            endDate: presenter.getFilterEndDate()
              ? moment.tz(presenter.getFilterEndDate(), 'America/Denver')
              : null
          }}
          name='dateFilter'
          onFinish={() => presenter.getLeaveRequestsByRequesterRole()}
        >
          <Row className='filter' gutter={8}>
            <Col>
              <Form.Item label='Start Date' name='startDate'>
                <DatePicker
                  allowClear
                  disabled={presenter.isLoading()}
                  onChange={this.updateFilterStartDate}
                  value={
                    presenter.getFilterStartDate()
                      ? moment.tz(presenter.getFilterStartDate(), 'America/Denver')
                      : null
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='End Date' name='endDate'>
                <DatePicker
                  allowClear
                  disabled={presenter.isLoading()}
                  onChange={this.updateFilterEndDate}
                  value={
                    presenter.getFilterEndDate()
                      ? moment.tz(presenter.getFilterEndDate(), 'America/Denver')
                      : null
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                disabled={presenter.isLoading()}
                loading={presenter.isLoading()}
                htmlType='submit'
                type='primary'
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Divider orientation='center'>{formTitle}</Divider>
        {presenter.isLoading() || requestStatusUpdateHandler.isLoading() ? (
          <div className='site-layout-background'>
            <Empty description={<span>Loading...</span>} />
          </div>
        ) : !presenter.getAllRequests().length ? (
          <div className='site-layout-background'>
            <Empty description={<span>There are currently no leave requests</span>} />
          </div>
        ) : (
          <Collapse>
            {presenter.getAllRequests().map((request, i) => {
              return (
                <Panel
                  extra={
                    <Fragment>
                      {request.approvalStatus === approvedStatus && (
                        <Tag color={request.needsReschedule ? 'magenta' : 'cyan'}>
                          {request.needsReschedule ? 'Pending Reschedule' : 'Rescheduled'}
                        </Tag>
                      )}
                      <Tag
                        color={`${presenter.getClassNameByApprovalStatus(
                          request.approvalStatus as ValidRequestStatusType
                        )}`}
                      >
                        {presenter.getDisplayRequestStatus(request.approvalStatus)}
                      </Tag>
                    </Fragment>
                  }
                  header={
                    <Fragment>
                      Requester:<Tag style={{ marginLeft: '5px' }}>{presenter.getPersonNameById(request.requesterId)}</Tag>
                      Reference Id:<Tag style={{ marginLeft: '5px' }}>{request.id}</Tag>
                    </Fragment>
                  }
                  key={i}
                >
                  <div className='dashboard-leaveRequestDetails'>
                    <p>
                      Leave Type:{' '}
                      {presenter.getFormattedLeavePeriodTypeByRecord(
                        request.leaveType as ValidEmployeeLeavePeriodRecordsType
                      )}
                    </p>
                    <p>
                      Reason Type:{' '}
                      {presenter.getFormattedReasonTypeByRecord(
                        request.reasonType as EmployeeLeaveReasonRecordsType
                      )}
                    </p>
                    <p>Requested time period: {this.getRequestTimePeriod(request)}</p>
                    <p>
                      Submitted Date: {moment.tz(request.submitDate, 'America/Denver').format('MM/DD/YYYY hh:mm a')}
                    </p>
                    {request.additionalComments && <p>Comment: {request.additionalComments}</p>}
                    {request.approvalStatus === pendingStatus && this.getDecisionButtons(request)}
                  </div>
                </Panel>
              )
            })}
          </Collapse>
        )}
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  private updateFilterStartDate = (filterStartDate: any) => {
    if (filterStartDate) {
      const startDate = moment
        .tz('America/Denver')
        .year(filterStartDate.year())
        .month(filterStartDate.month())
        .date(filterStartDate.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      this.props.presenter.setFilterStartDate(startDate.toISOString())
    } else {
      this.props.presenter.setFilterStartDate(null)
    }
  }

  private updateFilterEndDate = (filterEndDate: any) => {
    if (filterEndDate) {
      const endDate = moment
        .tz('America/Denver')
        .year(filterEndDate.year())
        .month(filterEndDate.month())
        .date(filterEndDate.date())
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
      this.props.presenter.setFilterEndDate(endDate.toISOString())
    } else {
      this.props.presenter.setFilterEndDate(null)
    }
  }

  private getDecisionButtons = (item: LeaveRequestWithStatus): ReactElement => {
    const { presenter } = this.props

    return (
      <div className='leaverDashboard-actionButtons'>
        {presenter.getDenyButtonText() && (
          <Button danger onClick={() => this.denyRequest(item)}>
            {presenter.getDenyButtonText()}
          </Button>
        )}
        <Button className='leaverDashboard-approveButton' onClick={this.showConfirmModal}>
          {presenter.getApproveButtonText()}
        </Button>
        {this.state.isModalVisible && this.getApproveConfirmModal(item)}
      </div>
    )
  }

  private showConfirmModal = (): void => {
    this.setState({ isModalVisible: true })
  }

  private getApproveConfirmModal = (request: LeaveRequestWithStatus): ReactElement => {
    const { presenter } = this.props

    return (
      <Modal
        title='Confirm Request Approval'
        visible={this.state.isModalVisible}
        onOk={() => this.approveRequest(request)}
        okText='Yes'
        cancelText='Cancel'
        onCancel={() => this.setState({ isModalVisible: false })}
      >
        <p>
          Are you sure you want to approve this leave request? <br />
          All the appointments for {presenter.getPersonNameById(request.requesterId)} during{' '}
          {this.getRequestTimePeriod(request)} will be modified.
        </p>
      </Modal>
    )
  }

  private getRequestTimePeriod = (request: LeaveRequestWithStatus): string => {
    return `${moment.tz(request.leaveDatetime, 'America/Denver').format('MM/DD/YYYY hh:mm a')} to ${moment.tz(request.returnDatetime, 'America/Denver').format('MM/DD/YYYY hh:mm a')}`
  }

  private denyRequest = async (request: LeaveRequestWithStatus): Promise<void> => {
    this.props.requestStatusUpdateHandler.denyLeaveRequest(request.id)
  }

  private approveRequest = async (request: LeaveRequestWithStatus): Promise<void> => {
    this.setState({ isModalVisible: false })
    this.props.requestStatusUpdateHandler.approveLeaveRequest(request.id)
  }
}

export default LeaverDashboard
