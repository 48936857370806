import { SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import AssignmentFormData, {
  ScheduleData
} from '../../Routes/ClientAssignment/Interfaces/AssignmentFormData'
import IRegistrationCreator from './IRegistrationCreator'

class RegistrationCreator implements IRegistrationCreator {
  constructor(private readonly fetcher: IFetcher) { }

  public async register(assignmentFormData: AssignmentFormData): Promise<void> {
    return await this.fetcher.fetch({
      body: assignmentFormData,
      method: 'POST',
      url: `${SCHEDULER_URL}registration/register`
    })
  }

  public async updateSchedule(clientId: string, therapistId: string, schedule: ScheduleData): Promise<void> {
    return await this.fetcher.fetch({
      body: schedule,
      method: 'PUT',
      url: `${SCHEDULER_URL}registration/${clientId}/${therapistId}/hourPreferences`
    })
  }

  public async deleteRegistrationRecord(
    clientId: string,
    therapistId: string,
    bcbaId: string
  ): Promise<void> {
    let url = `${SCHEDULER_URL}registration?`
    const queryParams = {
      clientId,
      therapistId,
      bcbaId
    }
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value != null) {
        url = url.concat(`${key}=${value}&`)
      }
    })
    return await this.fetcher.fetch({
      body: {},
      method: 'DELETE',
      url
    })
  }
}

export default RegistrationCreator
