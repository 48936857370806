import moment from "moment-timezone"

import ApiClientDto from "../../../../ClientCrud/ApiClientDto"
import ApiEmployeeDto from "../../../../EmployeeCrud/ApiEmployeeDto"
import ISubscribingView from "../../../../Model/Interfaces/ISubscribingViewPresenter"
import INotificationCreator from "../../../../Notifications/NotificationCreator/INotificationCreator"
import IRescheduleCreator from "../../../../Scheduling/RescheduleCreator/IRescheduleCreator"
import IReschedulerPresenter from "../../Presenter/IReschedulerPresenter"
import NotificationDto from "../Interfaces/NotificationDto"
import StagingDataDto from "../Interfaces/StagingDataDto"
import IStagingModalPresenter from "./IStagingModalPresenter"

class StagingModalPresenter implements IStagingModalPresenter {
  private stagingData: StagingDataDto
  private stagedNotifications: any
  private isLoading: boolean
  private isOpenModal: boolean
  private errorMessage: string | null
  private view: ISubscribingView | null

  constructor(
    private readonly rescheduleCreator: IRescheduleCreator,
    private readonly notificationCreator: INotificationCreator,
    private readonly parentPresenter: IReschedulerPresenter
  ) {
    this.stagingData = {
      clientSessions: [],
      therapistSessions: []
    }
    this.stagedNotifications = {}
    this.isLoading = false
    this.isOpenModal = false
    this.errorMessage = null
    this.view = null
    this.initialize()
  }

  public getStagingData(): StagingDataDto {
    return this.stagingData
  }

  public getStagedNotifications(): any {
    return this.stagedNotifications
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getIsOpenModal(): boolean {
    return this.isOpenModal
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public getParentPresenter(): IReschedulerPresenter {
    return this.parentPresenter
  }

  public setStagingData(stagingData: StagingDataDto): void {
    this.stagingData = stagingData
    this.stageClientSessionNotifications()
    this.stageTherapistSessionNotifications()
  }

  private stageClientSessionNotifications(): void {
    this.stagingData.clientSessions.forEach(clientSession => {
      if (!clientSession.sendSms) {
        return
      }
      const client = this.parentPresenter.getClientOptions().find(c => c.uuid === clientSession.clientId) as ApiClientDto
      const therapist = this.parentPresenter.getTherapistOptions().find(t => t.uuid === clientSession.therapistId) as ApiEmployeeDto
      const [startTime, endTime] = [moment.tz(clientSession.scheduledStart, 'America/Denver'), moment.tz(clientSession.scheduledEnd, 'America/Denver')]

      const notifications: NotificationDto[] = []
      if (clientSession.action === 'RESCHEDULE') {
        client.guardians.forEach(guardianRelationship => {
          const { guardian } = guardianRelationship
          if (guardian.phone) {
            notifications.push({
              targetUuid: guardian.uuid as string,
              targetName: `${guardian.firstName || ''}${guardian.lastName ? ' ' + guardian.lastName : ''}`,
              targetPhone: guardian.phone,
              message: `Hi ${guardian.firstName}. Your child's usual therapist will not be coming on ${startTime.format('MM/DD/YYYY')}, so we have ` +
                `found a subtitute for your child to work with. The subtitute's name is ${therapist.firstName || ''}${therapist.lastName ? ' ' + therapist.lastName : ''}. ` +
                `Your session will be from ${startTime.format('MM/DD/YYYY hh:mm a')} to ${endTime.format('MM/DD/YYYY hh:mm a')}. Hope to see you soon!`
            })
          }
        })
        if (therapist.phone) {
          notifications.push({
            targetUuid: therapist.uuid as string,
            targetName: `${therapist.firstName || ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`,
            targetPhone: therapist.phone,
            message: `Hi ${therapist.firstName}. We have rescheduled you to work with: ${client.firstName || ''}${client.lastName ? ' ' + client.lastName : ''} from ` +
              `${startTime.format('MM/DD/YYYY hh:mm a')} to ${endTime.format('MM/DD/YYYY hh:mm a')}. Thank you so much for you work!`
          })
        }
      } else {
        client.guardians.forEach(guardianRelationship => {
          const { guardian } = guardianRelationship
          if (guardian.phone) {
            notifications.push({
              targetUuid: guardian.uuid as string,
              targetName: `${guardian.firstName || ''}${guardian.lastName ? ' ' + guardian.lastName : ''}`,
              targetPhone: guardian.phone,
              message: `Hi ${guardian.firstName}. Your child's usual therapist will not be coming on ${startTime.format('MM/DD/YYYY')}, and we were ` +
                `unable to find a subtitute for your child to work with. So as a heads up, your child's session from ${startTime.format('MM/DD/YYYY hh:mm a')} to ` +
                `${endTime.format('MM/DD/YYYY hh:mm a')} has been cancelled. We are sorry for any inconvenience caused!`
            })
          }
        })
      }
      this.stagedNotifications[clientSession.id] = notifications
    })
  }

  private stageTherapistSessionNotifications(): void {
    this.stagingData.therapistSessions.forEach(therapistSession => {
      if (!therapistSession.sendSms) {
        return
      }
      const client = this.parentPresenter.getClientOptions().find(c => c.uuid === therapistSession.clientId) as ApiClientDto
      const therapist = this.parentPresenter.getTherapistOptions().find(t => t.uuid === therapistSession.therapistId) as ApiEmployeeDto
      const [startTime, endTime] = [moment.tz(therapistSession.scheduledStart, 'America/Denver'), moment.tz(therapistSession.scheduledEnd, 'America/Denver')]

      const notifications: NotificationDto[] = []
      if (therapistSession.action === 'RESCHEDULE') {
        client.guardians.forEach(guardianRelationship => {
          const { guardian } = guardianRelationship
          if (guardian.phone) {
            notifications.push({
              targetUuid: guardian.uuid as string,
              targetName: `${guardian.firstName || ''}${guardian.lastName ? ' ' + guardian.lastName : ''}`,
              targetPhone: guardian.phone,
              message: `Hi ${guardian.firstName}. This is just a heads up that we have rescheduled your child: ${client.firstName} to work with a different therapist: ` +
                `${therapist.firstName || ''}${therapist.lastName ? ' ' + therapist.lastName : ''} from ${startTime.format('MM/DD/YYYY hh:mm a')} to ` +
                `${endTime.format('MM/DD/YYYY hh:mm a')}. Hope to see you soon!`
            })
          }
        })
        if (therapist.phone) {
          notifications.push({
            targetUuid: therapist.uuid as string,
            targetName: `${therapist.firstName || ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`,
            targetPhone: therapist.phone,
            message: `Hi ${therapist.firstName}. Your usual client will not be coming on ${startTime.format('MM/DD/YYYY')}, so we have ` +
              `found a subtitute client for you to work with. The new client's name is ${client.firstName || ''}${client.lastName ? ' ' + client.lastName : ''}. ` +
              `Your session will be from ${startTime.format('MM/DD/YYYY hh:mm a')} to ${endTime.format('MM/DD/YYYY hh:mm a')}. Thank you so much for you work!`
          })
        }
      } else {
        if (therapist.phone) {
          notifications.push({
            targetUuid: therapist.uuid as string,
            targetName: `${therapist.firstName || ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`,
            targetPhone: therapist.phone,
            message: `Hi ${therapist.firstName}. Your usual client will not be coming on ${startTime.format('MM/DD/YYYY')}, and we were ` +
              `unable to find a subtitute client for you to work with. So as a heads up, your session from ${startTime.format('MM/DD/YYYY hh:mm a')} to ` +
              `${endTime.format('MM/DD/YYYY hh:mm a')} has been cancelled. Thank you so much for you work!`
          })
        }
      }
      this.stagedNotifications[therapistSession.id] = notifications
    })
  }

  public setStagedNotifications(stagedNotifications: any): void {
    this.stagedNotifications = stagedNotifications
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public setIsOpenModal(isOpenModal: boolean): void {
    this.isOpenModal = isOpenModal
    this.updateView()
  }

  public async submitForRescheduling(): Promise<void> {
    this.setIsLoading(true)
    try {
      await this.rescheduleCreator.submitReschedulePlan(
        this.stagingData.clientSessions,
        this.stagingData.therapistSessions
      )
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.setIsLoading(false)
  }

  public async sendNotifications(): Promise<void> {
    this.setIsLoading(true)
    try {
      const notifications: NotificationDto[] = []
      Object.values(this.stagedNotifications).forEach(smsArray => {
        (smsArray as NotificationDto[]).forEach((sms: NotificationDto) => {
          notifications.push(sms)
        })
      })
      await Promise.all(notifications.map(async (sms) => await this.notificationCreator.sendNotification({
        phone: sms.targetPhone,
        message: sms.message
      })))
    } catch (error) {
      return
    }
    this.setIsLoading(false)
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private initialize(): void {
    this.updateView()
  }
}

export default StagingModalPresenter