import { SCHEDULER_URL } from "../../constant"
import IFetcher from "../../Drivers/IFetcher"
import AppointmentDto from "../AppointmentDto"
import IAppointmentCreator from "./IAppointmentCreator"

class AppointmentCreator implements IAppointmentCreator {
  constructor(private readonly fetcher: IFetcher) { }

  public async createAppointment(appointment: AppointmentDto): Promise<AppointmentDto> {
    return this.fetcher.fetch({
      body: {
        clientId: appointment.clientId,
        therapistId: appointment.therapistId,
        bcbaId: appointment.bcbaId,
        scheduledStart: appointment.scheduledStart,
        scheduledEnd: appointment.scheduledEnd
      },
      method: 'POST',
      url: `${SCHEDULER_URL}scheduler/create`
    })
  }

  public async modifyAppointment(appointment: AppointmentDto): Promise<AppointmentDto> {
    return this.fetcher.fetch({
      body: {
        id: appointment.id,
        clientId: appointment.clientId,
        therapistId: appointment.therapistId,
        bcbaId: appointment.bcbaId,
        scheduledStart: appointment.scheduledStart,
        scheduledEnd: appointment.scheduledEnd,
        comments: appointment.comments
      },
      method: 'PUT',
      url: `${SCHEDULER_URL}scheduler/modify`
    })
  }

  public async cancelAppointment(id: number, comments: string | null): Promise<AppointmentDto> {
    return this.fetcher.fetch({
      body: {
        comments
      },
      method: 'PUT',
      url: `${SCHEDULER_URL}scheduler/${id}/cancel`
    })
  }

  public async undoCancellation(id: number, comments: string | null): Promise<AppointmentDto> {
    return this.fetcher.fetch({
      body: {
        comments
      },
      method: 'PUT',
      url: `${SCHEDULER_URL}scheduler/${id}/undoCancellation`
    })
  }
}

export default AppointmentCreator