import LeaveReasonRecordsType, {
  appointmentLeaveReasonRecordType,
  covidLeaveReasonRecordType,
  otherLeaveReasonRecordType,
  ptoLeaveReasonRecordType,
  sickLeaveReasonRecordType
} from '../../../Model/Records/LeaveReasonRecordsType'
import ValidLeaveRequestReasonType, {
  appointmentLeaveRequestType,
  covidLeaveRequestType,
  otherLeaveRequestType,
  ptoLeaveRequestType,
  sickLeaveRequestType
} from '../../../Model/Types/ValidLeaveRequestReasonType'
import ILeaveReasonFormatter from './ILeaveReasonFormatter'

const typesOfLeaveReasonType = [
  appointmentLeaveRequestType,
  covidLeaveRequestType,
  ptoLeaveRequestType,
  sickLeaveRequestType,
  otherLeaveRequestType
]
class LeaveReasonFormatter implements ILeaveReasonFormatter {
  private leaveReasonTypeMap: Map<string, { record: LeaveReasonRecordsType }>

  constructor() {
    this.leaveReasonTypeMap = new Map()
    this.populateLeaveReasonTypeMap()
  }

  private populateLeaveReasonTypeMap(): void {
    for (let i = 0; i < typesOfLeaveReasonType.length; i++) {
      const type = typesOfLeaveReasonType[i]

      if (type === covidLeaveRequestType) {
        this.leaveReasonTypeMap.set(covidLeaveRequestType, { record: covidLeaveReasonRecordType })
      }

      if (type === ptoLeaveRequestType) {
        this.leaveReasonTypeMap.set(ptoLeaveRequestType, { record: ptoLeaveReasonRecordType })
      }

      if (type === sickLeaveRequestType) {
        this.leaveReasonTypeMap.set(sickLeaveRequestType, { record: sickLeaveReasonRecordType })
      }

      if (type === appointmentLeaveRequestType) {
        this.leaveReasonTypeMap.set(appointmentLeaveRequestType, {
          record: appointmentLeaveReasonRecordType
        })
      }

      if (type === otherLeaveRequestType) {
        this.leaveReasonTypeMap.set(otherLeaveRequestType, { record: otherLeaveReasonRecordType })
      }
    }
  }

  public getFormattedLeaveReasonType(
    reasonRecord: LeaveReasonRecordsType
  ): ValidLeaveRequestReasonType {
    const periodType = Array.from(this.leaveReasonTypeMap.keys()).find((key) => {
      const record = this.leaveReasonTypeMap.get(key)

      if (record) {
        return record.record === reasonRecord
      }
    })

    if (periodType) {
      return periodType as ValidLeaveRequestReasonType
    }

    return '' as ValidLeaveRequestReasonType
  }

  public getLeaveReasonRecordByType(type: ValidLeaveRequestReasonType): LeaveReasonRecordsType {
    const periodType = this.leaveReasonTypeMap.get(type)

    if (periodType) {
      return periodType.record
    }

    return '' as LeaveReasonRecordsType
  }
}

export default LeaveReasonFormatter
