import IBcbaCreator from '../../../../BcbaCrud/BcbaCreator/IBcbaCreator'
import ApiClientDto from '../../../../ClientCrud/ApiClientDto'
import IClientCreator from '../../../../ClientCrud/ClientCreator/IClientCreator'
import IClientGetter from '../../../../ClientCrud/ClientGetter/IClientGetter'
import IEmployeePoster from '../../../../EmployeeCrud/EmployeePoster/IEmployeePoster'
import ApiGuardianDto from '../../../../GuardianCrud/ApiGuardianDto'
import IGuardianGetter from '../../../../GuardianCrud/GuardianGetter/IGuardianGetter'
import IGuardianPoster from '../../../../GuardianCrud/GuardianPoster/IGuardianPoster'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import IDirectoryPresenter from '../../Presenter/IDirectoryPresenter'
import EditFormData from '../Interfaces/EditFormData'
import IEditPersonFormPresenter from './IEditPersonFormPresenter'

const initialFormState = {
  uuid: null,
  firstName: null,
  middleName: null,
  lastName: null,
  initials: null,
  position: null,
  phone: null,
  email: null,
  street1: null,
  street2: null,
  city: null,
  state: null,
  zipCode: null,
  serviceCenter: null,
  therapyStartDate: null,
  startDate: null,
  active: null,
  guardians: [],
  clients: []
}

class EditPersonFormPresenter implements IEditPersonFormPresenter {
  private entityType: string | null
  private openModal: boolean
  private isLoading: boolean
  private clientOptions: ApiClientDto[]
  private guardianOptions: ApiGuardianDto[]
  private formData: EditFormData
  private errorMessage: string | null
  private view: ISubscribingView | null

  constructor(
    private readonly directoryPresenter: IDirectoryPresenter,
    private readonly clientGetter: IClientGetter,
    private readonly clientCreator: IClientCreator,
    private readonly employeePoster: IEmployeePoster,
    private readonly guardianGetter: IGuardianGetter,
    private readonly guardianPoster: IGuardianPoster,
    private readonly bcbaCreator: IBcbaCreator
  ) {
    this.entityType = null
    this.openModal = false
    this.isLoading = false
    this.clientOptions = []
    this.guardianOptions = []
    this.formData = JSON.parse(JSON.stringify(initialFormState))
    this.errorMessage = null
    this.view = null
    this.initialize()
  }

  public async fetchGuardians(): Promise<void> {
    this.isLoading = true
    this.updateView()
    const profile = await this.clientGetter.getClientProfileWithGuardians(
      this.formData.uuid as string
    )
    this.formData.guardians = profile ? profile.guardians : []
    this.isLoading = false
    this.updateView()
  }

  public async fetchGuardianOptions(): Promise<void> {
    this.isLoading = true
    this.updateView()
    this.guardianOptions = await this.guardianGetter.searchGuardianProfiles(
      null,
      null,
      null,
      null,
      null
    )
    this.isLoading = false
    this.updateView()
  }

  public async fetchClientOptions(): Promise<void> {
    this.isLoading = true
    this.updateView()
    this.clientOptions = await this.clientGetter.searchClientProfiles(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
    this.isLoading = false
    this.updateView()
  }

  public getEntityType(): string | null {
    return this.entityType
  }

  public setEntityType(entityType: string | null): void {
    this.entityType = entityType
  }

  public getOpenModal(): boolean {
    return this.openModal
  }

  public setOpenModal(openModal: boolean): void {
    this.openModal = openModal
    this.updateView()
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public getGuardianOptions(): ApiGuardianDto[] {
    return this.guardianOptions
  }

  public getFormData(): EditFormData {
    return this.formData
  }

  public setFormData(formData: EditFormData): void {
    this.formData = formData
  }

  public resetFormData(): void {
    this.formData = JSON.parse(JSON.stringify(initialFormState))
  }

  public setFirstName(firstName: string | null): void {
    this.formData.firstName = firstName || null
  }

  public setMiddleName(middleName: string | null): void {
    this.formData.middleName = middleName || null
  }

  public setLastName(lastName: string | null): void {
    this.formData.lastName = lastName || null
  }

  public setInitials(initials: string | null): void {
    this.formData.initials = initials || null
  }

  public setPhone(phone: string | null): void {
    this.formData.phone = phone || null
  }

  public setEmail(email: string | null): void {
    this.formData.email = email || null
  }

  public setStreet1(street1: string | null): void {
    this.formData.street1 = street1 || null
  }

  public setStreet2(street2: string | null): void {
    this.formData.street2 = street2 || null
  }

  public setCity(city: string | null): void {
    this.formData.city = city || null
  }

  public setState(state: string | null): void {
    this.formData.state = state || null
  }

  public setZipCode(zipCode: string | null): void {
    this.formData.zipCode = zipCode || null
  }

  public setServiceCenter(serviceCenter: string | null): void {
    this.formData.serviceCenter = serviceCenter || null
  }

  public setTherapyStartDate(therapyStartDate: string | null): void {
    this.formData.therapyStartDate = therapyStartDate || null
  }

  public setTherapyEndDate(therapyEndDate: string | null): void {
    this.formData.therapyEndDate = therapyEndDate || null
  }

  public setStartDate(startDate: string | null): void {
    this.formData.startDate = startDate || null
  }

  public setTerminationDate(terminationDate: string | null): void {
    this.formData.terminationDate = terminationDate || null
  }

  public setActive(active: boolean | null): void {
    this.formData.active = active
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public async submitClientForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.clientCreator.updateClient({
        uuid: this.formData.uuid,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        initials: this.formData.initials,
        street1: this.formData.street1,
        street2: this.formData.street2,
        city: this.formData.city,
        state: this.formData.state,
        zipCode: this.formData.zipCode,
        serviceCenter: this.formData.serviceCenter,
        therapyStartDate: this.formData.therapyStartDate,
        therapyEndDate: this.formData.therapyEndDate,
        active: this.formData.active,
        guardians: this.formData.guardians
      })
      await this.directoryPresenter.searchDirectory()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async submitTherapistForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.employeePoster.updateEmployee({
        uuid: this.formData.uuid,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        position: this.formData.position,
        phone: this.formData.phone,
        email: this.formData.email,
        street1: this.formData.street1,
        street2: this.formData.street2,
        city: this.formData.city,
        state: this.formData.state,
        zipCode: this.formData.zipCode,
        serviceCenter: this.formData.serviceCenter,
        startDate: this.formData.startDate,
        terminationDate: this.formData.terminationDate,
        active: this.formData.active
      })
      await this.directoryPresenter.searchDirectory()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async submitGuardianForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.guardianPoster.updateGuardian({
        uuid: this.formData.uuid,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email
      })
      await this.guardianPoster.deleteRelatedClients(this.formData.uuid as string)
      await Promise.all(
        this.formData.clients.map(
          async (client) =>
            await this.guardianPoster.attachGuardianToClients(
              client.clientId as string,
              this.formData.uuid as string,
              client.relationship as string
            )
        )
      )
      await this.directoryPresenter.searchDirectory()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async submitBcbaForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.bcbaCreator.updateBcba({
        uuid: this.formData.uuid,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email
      })
      await this.directoryPresenter.searchDirectory()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private initialize(): void {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default EditPersonFormPresenter
