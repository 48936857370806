import { BASE_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiBcbaDto from '../ApiBcbaDto'
import BcbaDto from '../BcbaDto'
import IBcbaCreator from './IBcbaCreator'

class BcbaCreator implements IBcbaCreator {
  constructor(private readonly fetcher: IFetcher) {}

  public async createBcba(bcba: ApiBcbaDto): Promise<BcbaDto> {
    return this.fetcher.fetch({
      body: bcba,
      method: 'POST',
      url: `${BASE_URL}bcba/create`
    })
  }

  public async updateBcba(bcba: ApiBcbaDto): Promise<BcbaDto> {
    return this.fetcher.fetch({
      body: bcba,
      method: 'PUT',
      url: `${BASE_URL}bcba/update`
    })
  }
}

export default BcbaCreator
