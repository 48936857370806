import { BASE_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiEmployeDto from '../ApiEmployeeDto'
import EmployeeDto from '../EmployeeDto'
import IEmployeeGetter from './IEmployeeGetter'

class EmployeeGetter implements IEmployeeGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getEmployees(): Promise<EmployeeDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}employee/all`
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          active: r.active,
          email: r.email,
          name: `${r.firstName} ${r.lastName}`,
          id: r.uuid,
          position: r.position,
          serviceCenter: r.serviceCenter
        }
      })
    }

    return []
  }

  public async getEmployeeProfile(uuid: string): Promise<ApiEmployeDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}employee/${uuid}`
    })
    return result
  }

  public async searchEmployeeProfiles(
    firstName: string | null,
    middleName: string | null,
    lastName: string | null,
    position: string | null,
    phone: string | null,
    email: string | null,
    serviceCenter: string | null,
    startDate: string | null,
    terminationDate: string | null,
    active: boolean | null
  ): Promise<ApiEmployeDto[]> {
    let url = `${BASE_URL}search/employees?`
    const queryParams = {
      firstName,
      middleName,
      lastName,
      position,
      phone,
      email,
      serviceCenter,
      startDate,
      terminationDate,
      active
    }
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value != null) {
        url = url.concat(`&${key}=${value}`)
      }
    })

    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          uuid: r.uuid,
          firstName: r.firstName,
          middleName: r.middleName,
          lastName: r.lastName,
          position: r.position,
          phone: r.phone,
          email: r.email,
          street1: r.street1,
          street2: r.street2,
          city: r.city,
          state: r.state,
          zipCode: r.zipCode,
          serviceCenter: r.serviceCenter,
          startDate: r.startDate,
          terminationDate: r.terminationDate,
          active: r.active
        } as ApiEmployeDto
      })
    }
    return []
  }
}

export default EmployeeGetter
