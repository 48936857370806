import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Select } from 'antd'
import React, { Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import IAddPersonFormPresenter from './Presenter/IAddPersonFormPresenter'
import BcbaForm from './Subforms/BcbaForm'
import ClientForm from './Subforms/ClientForm'
import GuardianForm from './Subforms/GuardianForm'
import TherapistForm from './Subforms/TherapistForm'

type Props = {
  presenter: IAddPersonFormPresenter
}

type State = {
  entityType: string
}

class AddPersonForm extends Component<Props, State> implements ISubscribingView {
  state = {
    entityType: 'CLIENT'
  }

  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props

    let EntityForm = null
    switch (this.state.entityType) {
      case 'CLIENT':
        EntityForm = <ClientForm presenter={presenter} />
        break
      case 'THERAPIST':
        EntityForm = <TherapistForm presenter={presenter} />
        break
      case 'GUARDIAN':
        EntityForm = <GuardianForm presenter={presenter} />
        break
      case 'BCBA':
        EntityForm = <BcbaForm presenter={presenter} />
        break
      default:
        break
    }

    return (
      <Fragment>
        <Divider orientation='center'>Add New Profile</Divider>
        <Form
          initialValues={{ entityType: this.state.entityType }}
          name='addProfile'
          scrollToFirstError
        >
          <Form.Item
            label='Entity Type'
            name='entityType'
            rules={[
              {
                required: true,
                message: 'Please select an entity type'
              }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              disabled={presenter.getIsLoading()}
              onChange={(entityType) => {
                this.setState({
                  ...this.state,
                  entityType
                })
              }}
              value={this.state.entityType}
            >
              <Option value='CLIENT'>Client</Option>
              <Option value='THERAPIST'>Therapist</Option>
              <Option value='GUARDIAN'>Guardian</Option>
              <Option value='BCBA'>Bcba</Option>
            </Select>
          </Form.Item>
          <Button
            block
            disabled={presenter.getIsLoading()}
            icon={<PlusOutlined />}
            onClick={() => presenter.setOpenModal(true)}
            type='primary'
          >
            Create with details
          </Button>
        </Form>
        {EntityForm}
      </Fragment>
    )
  }

  update(): void {
    this.setState({
      ...this.state
    })
  }
}

export default AddPersonForm
