import './style.css'

import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd'
import React, { Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import ISearchFormPresenter from './Presenter/ISearchFormPresenter'

type Props = {
  presenter: ISearchFormPresenter
}

class SearchForm extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props

    const formInitialValues = {
      entityType: 'CLIENT',
      serviceCenter: null,
      active: null,
      id: null,
      firstName: null,
      middleName: null,
      lastName: null,
      phone: null,
      email: null
    }

    return (
      <Fragment>
        <Divider orientation='center'>Search Filter</Divider>
        <Form
          initialValues={formInitialValues}
          name='searchFilter'
          onFinish={this.submitForm}
          layout='vertical'
          scrollToFirstError
        >
          <Form.Item
            label='Entity Type'
            name='entityType'
            rules={[
              {
                required: true,
                message: 'Please select an entity type'
              }
            ]}
          >
            <Select
              disabled={presenter.getIsLoading()}
              onChange={(entityType) => presenter.setEntityType(entityType)}
              value={presenter.getFormState().entityType}
            >
              <Option value='CLIENT'>Client</Option>
              <Option value='THERAPIST'>Therapist</Option>
              <Option value='GUARDIAN'>Guardian</Option>
              <Option value='BCBA'>Bcba</Option>
            </Select>
          </Form.Item>
          <Form.Item label='Service Center' name='serviceCenter'>
            <Select
              allowClear
              disabled={presenter.getIsLoading()}
              onChange={(serviceCenter) => presenter.setServiceCenter(serviceCenter)}
              value={presenter.getFormState().serviceCenter}
            >
              <Option value='Aurora'>Aurora</Option>
              <Option value='Wheat Ridge'>Wheat Ridge</Option>
            </Select>
          </Form.Item>
          <Form.Item label='Status' name='active'>
            <Select
              allowClear
              disabled={presenter.getIsLoading()}
              onChange={(active) => presenter.setActive(active)}
              value={presenter.getFormState().active}
            >
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
          <Row gutter={8}>
            <Col span={19}>
              <Form.Item label='Id' name='id'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setId(e.target.value)}
                  value={presenter.getFormState().id || ''}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Initials' name='initials'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setInitials(e.target.value)}
                  value={presenter.getFormState().initials || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label='First Name' name='firstName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setFirstName(e.target.value)}
                  value={presenter.getFormState().firstName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Middle Name' name='middleName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setMiddleName(e.target.value)}
                  value={presenter.getFormState().middleName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Last Name' name='lastName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setLastName(e.target.value)}
                  value={presenter.getFormState().lastName || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label='Phone' name='phone'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setPhone(e.target.value)}
                  value={presenter.getFormState().phone || ''}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Email' name='email'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setEmail(e.target.value)}
                  value={presenter.getFormState().email || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              block
              disabled={presenter.getIsLoading()}
              icon={<SearchOutlined />}
              loading={presenter.getIsLoading()}
              type='primary'
              htmlType='submit'
            >
              Search directory
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    )
  }

  update = (): void => {
    this.setState({})
  }

  private submitForm = async (): Promise<void> => {
    await this.props.presenter.submitForm()
  }
}

export default SearchForm
