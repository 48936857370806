import IPartialPersonInfoGetter from '../../../EmployeeCrud/PartialEmployeeGetter/IPartialPersonInfoGetter'
import ILeaveRequestSubmitter from '../../../LeaveRequest/LeaveRequestSubmitter/ILeaveRequestSubmitter'
import LeaveRequestParam from '../../../LeaveRequest/LeaveRequestSubmitter/LeaveRequestParam'
import ILeaveFormPresenter from '../../../Model/Interfaces/ILeaveFormPresenter'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import OptionObject from '../../../Model/Interfaces/OptionObject'
import SelectOption from '../../../Model/Interfaces/SelectOption'
import ValidLeavePeriodType, {
  earlyEndPeriodType,
  lateStartPeriodType,
  multipleDaysPeriodType,
  singleDayPeriodType
} from '../../../Model/Types/ValidLeavePeriodType'
import ValidLeaveRequestType, {
  appointmentLeaveRequestType,
  otherLeaveRequestType,
  sickLeaveRequestType
} from '../../../Model/Types/ValidLeaveRequestReasonType'
import ILeavePeriodTypeFormatter from '../../Dashboard/LeavePeriodTypeFormatter/ILeavePeriodTypeFormatter'
import ILeaveReasonFormatter from '../../Dashboard/LeaveReasonFormatter/ILeaveReasonFormatter'

class ClientAbsenceNotificationPresenter implements ILeaveFormPresenter {
  private all: OptionObject[]
  private error: string
  private success: string
  private hasSubmitted: boolean
  private leaveReason: string
  private loading: boolean
  private leaveStartDate: string
  private leaveEndDate: string
  private comment: string | null
  private selectedPersonId: string
  private view: ISubscribingView | null
  private timePeriod: string
  private sendSms: boolean

  constructor(
    private readonly clientGetter: IPartialPersonInfoGetter,
    private readonly leaveRequestSubmitter: ILeaveRequestSubmitter,
    private readonly leaveReasonFormatter: ILeaveReasonFormatter,
    private readonly leavePeriodFormatter: ILeavePeriodTypeFormatter
  ) {
    this.all = []
    this.error = ''
    this.loading = false
    this.success = ''
    this.hasSubmitted = false
    this.comment = null
    this.leaveEndDate = ''
    this.leaveStartDate = ''
    this.timePeriod = ''
    this.leaveReason = ''
    this.selectedPersonId = ''
    this.sendSms = false
    this.view = null
    this.initialize()
  }

  private async initialize(): Promise<void> {
    this.all = await this.clientGetter.getPersonInfo()
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public get errorMessage(): string {
    return this.error
  }

  public get successMessage(): string {
    return this.success
  }

  public getLeaveReasons(): { value: string }[] {
    return [
      { value: appointmentLeaveRequestType },
      { value: sickLeaveRequestType },
      { value: otherLeaveRequestType }
    ]
  }

  public getLeaveTimePeriods(): { value: string }[] {
    return [
      { value: lateStartPeriodType },
      { value: earlyEndPeriodType },
      { value: singleDayPeriodType },
      { value: multipleDaysPeriodType }
    ]
  }

  public hasSubmittedForm(): boolean {
    return this.hasSubmitted
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getAllPersonOptions(): SelectOption[] {
    return this.all.map((e) => {
      return {
        id: e.id,
        value: e.name
      }
    })
  }

  public getSelectedLeaveReason(): ValidLeaveRequestType {
    return this.leaveReason as ValidLeaveRequestType
  }

  public getSelectedTimePeriod(): ValidLeavePeriodType {
    return this.timePeriod as ValidLeavePeriodType
  }

  public updateSelectedPerson(name: string): void {
    const person = this.all.find((n) => n.name === name)

    if (person) {
      this.selectedPersonId = person.id
    }
  }

  public updateAdditionalComment(text: string): void {
    this.comment = text
    this.updateView()
  }

  public updateLeaveRequestReason(reason: string): void {
    this.leaveReason = reason
  }

  public updateLeaveRequestStartDate(startDate: moment.Moment): void {
    this.leaveStartDate = startDate.toISOString()
  }

  public updateLeaveRequestEndDate(endDate: moment.Moment): void {
    this.leaveEndDate = endDate.toISOString()
  }

  public updateLeaveRequestTimePeriod(timePeriod: string): void {
    this.timePeriod = timePeriod
    this.updateView()
  }

  public updateSendSms(sendSms: boolean): void {
    this.sendSms = sendSms
  }

  public async submitForm(): Promise<void> {
    this.loading = true
    this.updateView()

    const response = await this.leaveRequestSubmitter.submitLeaveRequest(this.formRequestParam(), this.sendSms)
    this.loading = false
    this.updateView()

    if (!response.code) {
      this.error = response.message
      this.updateView()
    } else {
      this.hasSubmitted = true
      this.success = response.message
      this.updateView()
    }
  }

  private formRequestParam(): LeaveRequestParam {
    return {
      additionalComments: this.comment,
      clientId: this.selectedPersonId,
      employeeId: '',
      leaveType: this.leavePeriodFormatter.getLeavePeriodRecordByType(
        this.timePeriod as ValidLeavePeriodType
      ),
      leaveDatetime: this.leaveStartDate,
      returnDatetime: this.leaveEndDate,
      reasonType: this.leaveReasonFormatter.getLeaveReasonRecordByType(
        this.leaveReason as ValidLeaveRequestType
      ),
      requesterRole: 'CLIENT'
    }
  }
}

export default ClientAbsenceNotificationPresenter
