import moment from "moment-timezone"

import IAppointmentCreator from "../../../AppointmentCrud/AppointmentCreator/IAppointmentCreator"
import AppointmentDto from "../../../AppointmentCrud/AppointmentDto"
import IAppointmentGetter from "../../../AppointmentCrud/AppointmentGetter/IAppointmentGetter"
import ApiBcbaDto from "../../../BcbaCrud/ApiBcbaDto"
import IBcbaGetter from "../../../BcbaCrud/BcbaGetter/Interfaces/IBcbaGetter"
import ApiClientDto from "../../../ClientCrud/ApiClientDto"
import IClientGetter from "../../../ClientCrud/ClientGetter/IClientGetter"
import ApiEmployeeDto from "../../../EmployeeCrud/ApiEmployeeDto"
import IEmployeeGetter from "../../../EmployeeCrud/EmployeeGetter/IEmployeeGetter"
import ISubscribingView from "../../../Model/Interfaces/ISubscribingViewPresenter"
import AppointmentDetailsPresenter from "../AppointmentDetails/Presenter/AppointmentDetailsPresenter"
import IAppointmentDetailsPresenter from "../AppointmentDetails/Presenter/IAppointmentDetailsPresenter"
import ICalendarPresenter from "./ICalendarPresenter"

class CalendarPresenter implements ICalendarPresenter {
  private clientOptions: ApiClientDto[]
  private therapistOptions: ApiEmployeeDto[]
  private bcbaOptions: ApiBcbaDto[]
  private calendarData: any
  private selectedDate: string | null
  private showAppointmentList: boolean
  private selectedClient: string | null
  private selectedTherapist: string | null
  private formData: AppointmentDto
  private isLoading: boolean
  private isOpenModal: boolean
  private errorMessage: string | null
  private appointmentDetailsPresenter: IAppointmentDetailsPresenter
  private view: ISubscribingView | null

  constructor(
    private readonly appointmentGetter: IAppointmentGetter,
    private readonly appointmentCreator: IAppointmentCreator,
    private readonly clientGetter: IClientGetter,
    private readonly employeeGetter: IEmployeeGetter,
    private readonly bcbaGetter: IBcbaGetter
  ) {
    this.clientOptions = []
    this.therapistOptions = []
    this.bcbaOptions = []
    this.calendarData = {}
    this.selectedDate = null
    this.showAppointmentList = false
    this.selectedClient = null
    this.selectedTherapist = null
    this.formData = {
      id: null,
      clientId: null,
      therapistId: null,
      bcbaId: null,
      scheduledStart: null,
      scheduledEnd: null,
      status: null,
      comments: null
    }
    this.isLoading = false
    this.isOpenModal = false
    this.errorMessage = null
    this.appointmentDetailsPresenter = new AppointmentDetailsPresenter(this.appointmentCreator, this)
    this.view = null
    this.initialize()
  }

  public async fetchFilterOptions(): Promise<void> {
    const results = await Promise.all([
      this.clientGetter.searchClientProfiles(null, null, null, null, null, null, null, true),
      this.employeeGetter.searchEmployeeProfiles(null, null, null, 'THERAPIST', null, null, null, null, null, true),
      this.bcbaGetter.searchBcbaProfiles(null, null, null, null, null)
    ])
    this.clientOptions = results[0]
    this.therapistOptions = results[1]
    this.bcbaOptions = results[2]
  }

  public async fetchAppointments(startDate: string, endDate: string): Promise<void> {
    const appointments = (await this.appointmentGetter.searchAppointments(
      this.selectedClient,
      this.selectedTherapist,
      null,
      startDate,
      endDate,
      null
    )).sort((a, b) => {
      const momentA = moment.utc(a.scheduledStart)
      const momentB = moment.utc(b.scheduledStart)
      if (momentA.isAfter(momentB)) {
        return 1
      } else if (momentA.isBefore(momentB)) {
        return -1
      } else {
        if (moment.utc(a.scheduledEnd).isAfter(moment.utc(b.scheduledEnd))) {
          return 1
        } else {
          return -1
        }
      }
    })
    const data: any = {}
    appointments.forEach(appointment => {
      const scheduledStart = moment.tz(appointment.scheduledStart, 'America/Denver')
      const dateString = `${scheduledStart.month() + 1}-${scheduledStart.date()}-${scheduledStart.year()}`
      if (dateString in data) {
        data[dateString].push(appointment)
      } else {
        data[dateString] = [appointment]
      }
    })
    this.calendarData = data
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public getTherapistOptions(): ApiEmployeeDto[] {
    return this.therapistOptions
  }

  public getBcbaOptions(): ApiBcbaDto[] {
    return this.bcbaOptions
  }

  public getCalendarData(): any {
    return this.calendarData
  }

  public getShowAppointmentList(): boolean {
    return this.showAppointmentList
  }

  public getSelectedDate(): string | null {
    return this.selectedDate
  }

  public getAppointmentList(): AppointmentDto[] {
    if (!this.selectedDate) {
      return []
    }
    const date = moment.tz(this.selectedDate, 'America/Denver')
    return this.calendarData[`${date.month() + 1}-${date.date()}-${date.year()}`]
  }

  public getSelectedClient(): string | null {
    return this.selectedClient
  }

  public getAppointmentDetailsPresenter(): IAppointmentDetailsPresenter {
    return this.appointmentDetailsPresenter
  }

  public getSelectedTherapist(): string | null {
    return this.selectedTherapist
  }

  public getFormData(): AppointmentDto {
    return this.formData
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getIsOpenModal(): boolean {
    return this.isOpenModal
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public setShowAppointmentList(showAppointmentList: boolean): void {
    this.showAppointmentList = showAppointmentList
  }

  public setSelectedDate(selectedDate: string | null): void {
    this.selectedDate = selectedDate
  }

  public setSelectedClient(selectedClient: string | null): void {
    this.selectedClient = selectedClient
  }

  public setSelectedTherapist(selectedTherapist: string | null): void {
    this.selectedTherapist = selectedTherapist
  }

  public setFormData(formData: AppointmentDto) {
    this.formData = formData
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public setIsOpenModal(isOpenModal: boolean): void {
    this.isOpenModal = isOpenModal
    this.updateView()
  }

  public async submitAddForm(): Promise<void> {
    this.errorMessage = null
    this.setIsLoading(true)
    try {
      const startMoment = moment.utc(this.formData.scheduledStart)
      const endMoment = moment.utc(this.formData.scheduledEnd)
      if (startMoment.isSameOrAfter(endMoment)) {
        throw 'Appointment start time should be before end time'
      }
      if (startMoment.add(1, 'days').isBefore(endMoment)) {
        throw 'Appointment shouldn\'t last longer than 24 hours'
      }
      await this.appointmentCreator.createAppointment(this.formData)
      const startDate = this.selectedDate ? moment.tz(this.selectedDate, 'America/Denver').startOf('month') : moment.tz('America/Denver').startOf('month')
      const endDate = this.selectedDate ? moment.tz(this.selectedDate, 'America/Denver').endOf('month') : moment.tz('America/Denver').endOf('month')
      await this.fetchAppointments(startDate.toISOString(), endDate.toISOString())
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.setIsLoading(false)
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default CalendarPresenter