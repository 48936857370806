import PartialClientGetter from '../../../ClientCrud/PartialClientGetter/PartialClientGetter'
import Fetcher from '../../../Drivers/Fetcher'
import PartialEmployeeGetter from '../../../EmployeeCrud/PartialEmployeeGetter/PartialEmployeeGetter'
import LeaveRequestGetter from '../../../LeaveRequest/LeaveRequestGetter/LeaveRequestGetter'
import LeaveRequestStatusUpdater from '../../../LeaveRequest/LeaveRequestStatusUpdater/LeaveRequestStatusUpdater'
import clientStore from '../../../Model/ClientStore'
import employeeStore from '../../../Model/EmployeeStore'
import ValidRequestStatusType, {
  approvedStatus,
  deniedStatus
} from '../../../Model/Types/ValidRequestStatusType'
import LeavePeriodTypeFormatter from '../LeavePeriodTypeFormatter/LeavePeriodTypeFormatter'
import ILeaverDashboardPresenter from '../LeaverDashboard/ILeaverDashboardPresenter'
import LeaverDashboardPresenter from '../LeaverDashboard/LeaverDashboardPresenter'
import LeaveReasonFormatter from '../LeaveReasonFormatter/LeaveReasonFormatter'
import IRequestUpdateHandler from '../RequestUpdateHandler/IRequestUpdateHandler'
import RequestUpdateHandler from '../RequestUpdateHandler/RequestUpdateHandler'
import IDashboardPresenter from './IDashboardPresenterFactory'

class DashboardPresenterFactory implements IDashboardPresenter {
  constructor() {
    this.initialize()
  }

  private async initialize(): Promise<void> {
    employeeStore.getEmployees()
    clientStore.getClients()
  }

  public getClassNameByApprovalStatus(status: ValidRequestStatusType): string {
    if (status === approvedStatus) {
      return 'approvedStatus'
    }

    if (status === deniedStatus) {
      return 'deniedStatus'
    }

    return 'pendingStatus'
  }

  public getClientLeaverPresenter(): ILeaverDashboardPresenter {
    return new LeaverDashboardPresenter(
      new LeaveRequestGetter(new Fetcher()),
      new PartialClientGetter(new Fetcher()),
      new LeavePeriodTypeFormatter(),
      new LeaveReasonFormatter(),
      'CLIENT',
      { approve: 'Mark as viewed', deny: '' }
    )
  }

  public getEmployeeLeaverPresenter(): ILeaverDashboardPresenter {
    return new LeaverDashboardPresenter(
      new LeaveRequestGetter(new Fetcher()),
      new PartialEmployeeGetter(new Fetcher()),
      new LeavePeriodTypeFormatter(),
      new LeaveReasonFormatter(),
      'THERAPIST',
      { approve: 'Approve', deny: 'Deny' }
    )
  }

  public getLeaveRequestStatusUpdater(): IRequestUpdateHandler {
    return new RequestUpdateHandler(new LeaveRequestStatusUpdater(new Fetcher()))
  }
}

export default DashboardPresenterFactory
