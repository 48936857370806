import { CalendarOutlined, FormOutlined, HomeOutlined, LogoutOutlined, TableOutlined } from '@ant-design/icons'
import { Layout, Menu, MenuProps } from 'antd'
import React, { Component, ReactElement } from 'react'

import { signOut } from '../../constant'

type Props = {
  id: string
}

class Header extends Component<Props> {
  render(): ReactElement {
    const { Header } = Layout
    const { id } = this.props

    const items: MenuProps['items'] = [
      {
        label: <a href='/'>Dashboard</a>,
        key: 'dashboard',
        icon: <HomeOutlined />
      },
      {
        label: 'Trackers',
        key: 'trackers',
        icon: <TableOutlined />,
        children: [
          {
            label: <a href='/directory'>Directory</a>,
            key: 'directory'
          },
          {
            label: <a href='/assignments'>Assignments</a>,
            key: 'assignments'
          },
          {
            label: <a href='/rts'>Rts</a>,
            key: 'rts'
          }
        ]
      },
      {
        label: 'Scheduling',
        key: 'scheduling',
        icon: <CalendarOutlined />,
        children: [
          {
            label: <a href='/calendar'>Calendar</a>,
            key: 'calendar'
          },
          {
            label: <a href='/rescheduler'>Rescheduler</a>,
            key: 'rescheduler'
          }
        ]
      },
      {
        label: 'Forms',
        key: 'forms',
        icon: <FormOutlined />,
        children: [
          {
            label: <a href='/clientLeaveRequest'>Client Leave Request Form</a>,
            key: 'clientLeaveRequest'
          },
          {
            label: <a href='/employeeLeaveRequest'>Employee Leave Request Form</a>,
            key: 'employeeLeaveRequest'
          }
        ]
      },
      {
        label: 'Log Out',
        key: 'logOut',
        icon: <LogoutOutlined />,
        onClick: signOut
      }
    ]

    return (
      <Header id={id}>
        <div className='logo' />
        <Menu items={items} mode='horizontal' triggerSubMenuAction='click' />
      </Header>
    )
  }
}

export default Header
