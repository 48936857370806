import { ColumnsType } from 'antd/lib/table'

import IBcbaCreator from '../../../../BcbaCrud/BcbaCreator/IBcbaCreator'
import IClientCreator from '../../../../ClientCrud/ClientCreator/IClientCreator'
import IClientGetter from '../../../../ClientCrud/ClientGetter/IClientGetter'
import IEmployeePoster from '../../../../EmployeeCrud/EmployeePoster/IEmployeePoster'
import IGuardianGetter from '../../../../GuardianCrud/GuardianGetter/IGuardianGetter'
import IGuardianPoster from '../../../../GuardianCrud/GuardianPoster/IGuardianPoster'
import RequestResponse from '../../../../Model/Dtos/RequestResponse'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import EditPersonFormPresenter from '../../EditPersonForm/Presenter/EditPersonFormPresenter'
import IEditPersonFormPresenter from '../../EditPersonForm/Presenter/IEditPersonFormPresenter'
import IDirectoryPresenter from '../../Presenter/IDirectoryPresenter'
import TableData from '../Interfaces/TableData'
import BcbaTableColumns from '../TableColumnTypes/BcbaTableColumns'
import ClientTableColumns from '../TableColumnTypes/ClientTableColumns'
import GuardianTableColumns from '../TableColumnTypes/GuardianTableColumns'
import TherapistTableColumns from '../TableColumnTypes/TherapistTableColumns'
import IResultTablePresenter from './IResultTablePresenter'

class ResultTablePresenter implements IResultTablePresenter {
  private searchEntityType: string | null
  private searchResults: TableData[]
  private editPersonFormPresenter: IEditPersonFormPresenter
  private isLoading: boolean
  private view: ISubscribingView | null

  constructor(
    private readonly directoryPresenter: IDirectoryPresenter,
    private readonly clientGetter: IClientGetter,
    private readonly clientCreator: IClientCreator,
    private readonly employeePoster: IEmployeePoster,
    private readonly guardianGetter: IGuardianGetter,
    private readonly guardianPoster: IGuardianPoster,
    private readonly bcbaCreator: IBcbaCreator
  ) {
    this.searchEntityType = null
    this.searchResults = []
    this.editPersonFormPresenter = new EditPersonFormPresenter(
      this.directoryPresenter,
      this.clientGetter,
      this.clientCreator,
      this.employeePoster,
      this.guardianGetter,
      this.guardianPoster,
      this.bcbaCreator
    )
    this.isLoading = false
    this.view = null
    this.initialize()
  }

  public getSearchResults(): TableData[] {
    return this.searchResults
  }

  public getColumns(): ColumnsType<TableData> {
    if (this.searchResults.length) {
      switch (this.searchEntityType) {
        case 'CLIENT':
          return ClientTableColumns
        case 'THERAPIST':
          return TherapistTableColumns
        case 'GUARDIAN':
          return GuardianTableColumns
        case 'BCBA':
          return BcbaTableColumns
        default:
          break
      }
    }
    return []
  }

  public getSearchEntityType(): string | null {
    return this.searchEntityType
  }

  public getEditPersonFormPresenter(): IEditPersonFormPresenter {
    return this.editPersonFormPresenter
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public setSearchResults(
    searchResults: RequestResponse[] | TableData[],
    entityType: string
  ): void {
    this.searchEntityType = entityType

    if (!searchResults.length || this.receivedErrorResponse(searchResults[0])) {
      this.searchResults = []
    } else {
      // @ts-ignore
      this.searchResults = searchResults
    }
  }

  private receivedErrorResponse(result: object): result is RequestResponse {
    return (
      Object.prototype.hasOwnProperty.call(result, 'code') &&
      Object.prototype.hasOwnProperty.call(result, 'message')
    )
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private initialize(): void {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default ResultTablePresenter
