import { noContentHttpCode, SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import AssignmentDto from '../AssignmentDto'
import ClientAssignmentDto from '../ClientAssignmentDto'
import HourPreferencesDto from '../HourPreferencesDto'
import TherapistAssignmentDto from '../TherapistAssignmentDto'
import IRegistrationGetter from './IRegistrationGetter'

class RegistrationGetter implements IRegistrationGetter {
  constructor(private readonly fetcher: IFetcher) { }

  public async getAllAssignments(): Promise<AssignmentDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}registration/all`
    })
    if (result.code === noContentHttpCode) {
      return []
    }
    return result
  }

  public async getClientAssignments(clientId: string): Promise<ClientAssignmentDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}registration/assignment/client/${clientId}`
    })
    if (result.code === noContentHttpCode) {
      return []
    }
    return result
  }

  public async getTherapistAssignments(therapistId: string): Promise<TherapistAssignmentDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}registration/assignment/therapist/${therapistId}`
    })
    if (result.code === noContentHttpCode) {
      return []
    }
    return result
  }

  public async getHourPreferences(clientId: string, therapistId: string): Promise<HourPreferencesDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}registration/${clientId}/${therapistId}/hourPreferences`
    })
    if (result.code === noContentHttpCode) {
      return null
    }
    return result
  }
}

export default RegistrationGetter
