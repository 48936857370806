import { SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import DateRangeDto from '../../Model/Dtos/DateRangeDto'
import NeedSubsDto from '../Dtos/NeedSubsDto'
import IReschedulerGetter from './IReschedulerGetter'

class ReschedulerGetter implements IReschedulerGetter {
  constructor(private readonly fetcher: IFetcher) { }

  public async getAllSubs(dateRange: DateRangeDto): Promise<NeedSubsDto> {
    const { endDate, startDate } = dateRange
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}rescheduler/needSubs/all?startDate=${startDate}&endDate=${endDate}`
    })

    if (this.isValidNeedSubsResponse(result)) {
      return {
        clientSessions: result.clientSessions,
        therapistSessions: result.therapistSessions
      }
    }

    return {
      clientSessions: [],
      therapistSessions: []
    }
  }

  public isValidNeedSubsResponse(result: any): result is NeedSubsDto {
    return (
      Object.prototype.hasOwnProperty.call(result, 'clientSessions') &&
      Object.prototype.hasOwnProperty.call(result, 'therapistSessions') &&
      Array.isArray(result.clientSessions) &&
      Array.isArray(result.therapistSessions)
    )
  }
}

export default ReschedulerGetter
