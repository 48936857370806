import './style.css'

import { Card, Col, Divider, Modal, Row, Tag } from "antd"
import moment from "moment-timezone"
import React, { Component, Fragment, ReactElement } from "react"

import ISubscribingView from "../../../Model/Interfaces/ISubscribingViewPresenter"
import NotificationDto from './Interfaces/NotificationDto'
import IStagingModalPresenter from "./Presenter/IStagingModalPresenter"

type Props = {
  presenter: IStagingModalPresenter
}

class StagingModal extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <Modal
        centered
        confirmLoading={presenter.getIsLoading()}
        okText='Confirm and execute'
        onCancel={this.cancel}
        onOk={this.confirmReschedule}
        title='Confirm Reschedule Plan'
        visible={presenter.getIsOpenModal()}
        width={1200}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Row id='staging-modal' gutter={8}>
          <Col span={12}>
            <Divider orientation='center'>Therapist Fill Ins</Divider>
            <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
              {presenter.getStagingData().clientSessions.map(clientSession => {
                const client = presenter.getParentPresenter().getClientOptions().find(c => c.uuid === clientSession.clientId)
                const therapist = presenter.getParentPresenter().getTherapistOptions().find(t => t.uuid === clientSession.therapistId)
                const bcba = presenter.getParentPresenter().getBcbaOptions().find(b => b.uuid === clientSession.bcbaId)
                const [startTime, endTime] = [moment.tz(clientSession.scheduledStart, 'America/Denver'), moment.tz(clientSession.scheduledEnd, 'America/Denver')]
                return (
                  <Card
                    key={clientSession.id}
                    style={{ borderColor: '#dbdbdb', margin: '20px 15px' }}
                    title={<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }}>
                      <Col>
                        Client: {
                          client ? <Tag color='cyan'>
                            {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                          </Tag> : <Tag>VACANT</Tag>
                        }
                        Therapist: {
                          therapist ? <Tag color='cyan'>
                            {`${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`}
                          </Tag> : <Tag>VACANT</Tag>
                        }
                      </Col>
                      <Col>
                        <Tag color={clientSession.action === 'RESCHEDULE' ? 'orange' : 'red'}>{clientSession.action}</Tag>
                      </Col>
                    </Row>}
                  >
                    <p className='session-info'>Assigned Bcba: {bcba ? `${bcba.firstName || ''}${bcba.middleName ? ' ' + bcba.middleName : ''}${bcba.lastName ? ' ' + bcba.lastName : ''}` : 'None'}</p>
                    <p className='session-info'>Scheduled Time: {startTime.format('MM/DD/YYYY hh:mm a')} to {endTime.format('MM/DD/YYYY hh:mm a')}</p>
                    <p className='session-info'>Comments: {clientSession.comments}</p>
                    {clientSession.sendSms ? <Fragment>
                      <p style={{ marginBottom: '5px' }}>SMS Notifications:</p>
                      {presenter.getStagedNotifications()[clientSession.id].map((notification: NotificationDto) => (
                        <Fragment key={notification.targetUuid}>
                          <Row gutter={8}>
                            <Col>
                              To: <Tag>{notification.targetName}: {notification.targetPhone}</Tag>
                            </Col>
                          </Row>
                          <Row gutter={8} style={{ marginBottom: '5px' }}>
                            <Col>
                              Message: <span>{notification.message}</span>
                            </Col>
                          </Row>
                        </Fragment>
                      ))}
                    </Fragment> : <p style={{ color: 'red' }}>SMS Notifcations: Not Sending</p>}
                  </Card>
                )
              })}
            </div>
          </Col>
          <Col span={12}>
            <Divider orientation='center'>Client Fill Ins</Divider>
            <div style={{ maxHeight: '85vh', overflowY: 'scroll' }}>
              {presenter.getStagingData().therapistSessions.map(therapistSession => {
                const client = presenter.getParentPresenter().getClientOptions().find(c => c.uuid === therapistSession.clientId)
                const therapist = presenter.getParentPresenter().getTherapistOptions().find(t => t.uuid === therapistSession.therapistId)
                const bcba = presenter.getParentPresenter().getBcbaOptions().find(b => b.uuid === therapistSession.bcbaId)
                const [startTime, endTime] = [moment.tz(therapistSession.scheduledStart, 'America/Denver'), moment.tz(therapistSession.scheduledEnd, 'America/Denver')]
                return (
                  <Card
                    key={therapistSession.id}
                    style={{ borderColor: '#dbdbdb', margin: '20px 15px' }}
                    title={<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '13px' }}>
                      <Col>
                        Client: {
                          client ? <Tag color='cyan'>
                            {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                          </Tag> : <Tag>VACANT</Tag>
                        }
                        Therapist: {
                          therapist ? <Tag color='cyan'>
                            {`${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`}
                          </Tag> : <Tag>VACANT</Tag>
                        }
                      </Col>
                      <Col>
                        <Tag color={therapistSession.action === 'RESCHEDULE' ? 'orange' : 'red'}>{therapistSession.action}</Tag>
                      </Col>
                    </Row>}
                  >
                    <p className='session-info'>Assigned Bcba: {bcba ? `${bcba.firstName || ''}${bcba.middleName ? ' ' + bcba.middleName : ''}${bcba.lastName ? ' ' + bcba.lastName : ''}` : 'None'}</p>
                    <p className='session-info'>Scheduled Time: {startTime.format('MM/DD/YYYY hh:mm a')} to {endTime.format('MM/DD/YYYY hh:mm a')}</p>
                    <p className='session-info'>Comments: {therapistSession.comments}</p>
                    {therapistSession.sendSms ? <Fragment>
                      <p style={{ marginBottom: '5px' }}>SMS Notifications:</p>
                      {presenter.getStagedNotifications()[therapistSession.id].map((notification: NotificationDto) => (
                        <Fragment key={notification.targetUuid}>
                          <Row gutter={8}>
                            <Col>
                              To: <Tag>{notification.targetName}: {notification.targetPhone}</Tag>
                            </Col>
                          </Row>
                          <Row gutter={8} style={{ marginBottom: '5px' }}>
                            <Col>
                              Message: <span>{notification.message}</span>
                            </Col>
                          </Row>
                        </Fragment>
                      ))}
                    </Fragment> : <p style={{ color: 'red' }}>SMS Notifcations: Not Sending</p>}
                  </Card>
                )
              })}
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private confirmReschedule = async () => {
    await this.props.presenter.submitForRescheduling()
    this.props.presenter.sendNotifications()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancel()
      await this.props.presenter.getParentPresenter().fetchPendingRescheduleAppointments()
    }
  }

  private cancel = () => {
    this.props.presenter.setStagingData({
      clientSessions: [],
      therapistSessions: []
    })
    this.props.presenter.setIsOpenModal(false)
    this.props.presenter.getParentPresenter().setIsLoading(false)
  }
}

export default StagingModal