import { BASE_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiClientDto from '../ApiClientDto'
import IClientCreator from './IClientCreator'

class ClientCreator implements IClientCreator {
  constructor(private readonly fetcher: IFetcher) {}

  public async createClient(client: ApiClientDto): Promise<ApiClientDto> {
    return await this.fetcher.fetch({
      body: client,
      method: 'POST',
      url: `${BASE_URL}client/create`
    })
  }

  public async updateClient(client: ApiClientDto): Promise<ApiClientDto> {
    return await this.fetcher.fetch({
      body: client,
      method: 'PUT',
      url: `${BASE_URL}client/update`
    })
  }
}

export default ClientCreator
