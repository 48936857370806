import { Layout } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import React, { Component, ReactElement } from 'react'

import Footer from '../../Common/Footer/Footer'
import Header from '../../Common/Header/Header'
import { redirectIfNotAuthorized } from '../../constant'
import ISubscribingView from '../../Model/Interfaces/ISubscribingViewPresenter'
import AddPersonForm from './AddPersonForm/AddPersonForm'
import IDirectoryPresenter from './Presenter/IDirectoryPresenter'
import ResultTable from './ResultTable/ResultTable'
import SearchForm from './SearchForm/SearchForm'

type Props = {
  presenter: IDirectoryPresenter
}

class Directory extends Component<Props> implements ISubscribingView {
  async componentDidMount(): Promise<void> {
    await redirectIfNotAuthorized()
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { Content } = Layout
    const { presenter } = this.props

    return (
      <Layout>
        <Header id='dashboard-header' />
        <Content
          className='site-layout'
          style={{ backgroundColor: 'white', display: 'flex', padding: '0 50px' }}
        >
          <Sider id='search-form-sider' width={350}>
            <SearchForm presenter={presenter.getSearchFormPresenter()} />
            <AddPersonForm presenter={presenter.getAddPersonFormPresenter()} />
          </Sider>
          <ResultTable presenter={presenter.getResultTablePresenter()} />
        </Content>
        <Footer />
      </Layout>
    )
  }

  update = (): void => {
    this.setState({})
  }
}

export default Directory
