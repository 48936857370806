import { BASE_URL, noContentHttpCode } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiBcbaDto from '../ApiBcbaDto'
import BcbaDto from '../BcbaDto'
import IAllBcbaGetter from './Interfaces/IAllBcbaGetter'
import IBcbaGetter from './Interfaces/IBcbaGetter'

const BCBA_SEARCH_URL = `${BASE_URL}search/bcba?`
class BcbaGetter implements IBcbaGetter, IAllBcbaGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getBcbaProfileById(id: string): Promise<ApiBcbaDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}bcba/${id}`
    })
    if (result.code === noContentHttpCode) {
      return null
    }
    return result
  }

  public async getAllBcbas(): Promise<BcbaDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: BCBA_SEARCH_URL
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          id: r.uuid,
          name: `${r.firstName} ${r.lastName}`,
          firstName: r.firstName,
          lastName: r.lastName,
          middleName: r.middleName,
          phone: r.phone,
          email: r.email
        }
      })
    }

    return []
  }

  public async searchBcbaProfiles(
    firstName: string | null,
    middleName: string | null,
    lastName: string | null,
    phone: string | null,
    email: string | null
  ): Promise<ApiBcbaDto[]> {
    const queryParams = {
      firstName,
      middleName,
      lastName,
      phone,
      email
    }
    let url = BCBA_SEARCH_URL
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== null) {
        url = url.concat(`${key}=${value}&`)
      }
    })

    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: BCBA_SEARCH_URL
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          uuid: r.uuid,
          name: `${r.firstName} ${r.lastName}`,
          phone: r.phone,
          email: r.email,
          firstName: r.firstName,
          lastName: r.lastName,
          middleName: r.middleName
        }
      })
    }
    return []
  }
}

export default BcbaGetter
