import moment from 'moment-timezone'

import IPartialEmployeeGetter from '../../../EmployeeCrud/PartialEmployeeGetter/IPartialPersonInfoGetter'
import ILeaveRequestSubmitter from '../../../LeaveRequest/LeaveRequestSubmitter/ILeaveRequestSubmitter'
import LeaveRequestParam from '../../../LeaveRequest/LeaveRequestSubmitter/LeaveRequestParam'
import ILeaveFormPresenter from '../../../Model/Interfaces/ILeaveFormPresenter'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import OptionObject from '../../../Model/Interfaces/OptionObject'
import SelectOption from '../../../Model/Interfaces/SelectOption'
import ValidLeavePeriodType, {
  earlyEndPeriodType,
  lateStartPeriodType,
  multipleDaysPeriodType,
  singleDayPeriodType
} from '../../../Model/Types/ValidLeavePeriodType'
import ValidEmployeeLeaveRequestType, {
  covidLeaveRequestType,
  otherLeaveRequestType,
  ptoLeaveRequestType,
  sickLeaveRequestType
} from '../../../Model/Types/ValidLeaveRequestReasonType'
import ILeavePeriodTypeFormatter from '../../Dashboard/LeavePeriodTypeFormatter/ILeavePeriodTypeFormatter'

class EmployeeLeaveRequestPresenter implements ILeaveFormPresenter {
  private employeeId: string
  private employees: OptionObject[]
  private error: string
  private leaveReason: string
  private timePeriod: string
  private view: ISubscribingView | null
  private loading: boolean
  private leaveStartDate: string
  private leaveEndDate: string
  private leaveDetails: string | null
  private success: string
  private hasSubmitted: boolean
  private sendSms: boolean

  constructor(
    private readonly partialEmployeeGetter: IPartialEmployeeGetter,
    private readonly leaveRequestSubmitter: ILeaveRequestSubmitter,
    private readonly leavePeriodTypeFormatter: ILeavePeriodTypeFormatter
  ) {
    this.error = ''
    this.employeeId = ''
    this.employees = []
    this.leaveDetails = null
    this.leaveEndDate = ''
    this.leaveStartDate = ''
    this.leaveReason = ''
    this.loading = false
    this.timePeriod = ''
    this.view = null
    this.success = ''
    this.hasSubmitted = false
    this.sendSms = false
    this.initialize()
  }

  public get successMessage(): string {
    return this.success
  }

  public get errorMessage(): string {
    return this.error
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.employees = await this.partialEmployeeGetter.getPersonInfo()
    this.updateView()
  }

  public getSelectedLeaveReason(): ValidEmployeeLeaveRequestType {
    return this.leaveReason as ValidEmployeeLeaveRequestType
  }

  public getAllPersonOptions(): SelectOption[] {
    return this.employees.map((e) => {
      return {
        id: e.id,
        value: e.name
      }
    })
  }

  public getLeaveReasons(): { value: string }[] {
    return [
      { value: ptoLeaveRequestType },
      { value: sickLeaveRequestType },
      { value: covidLeaveRequestType },
      { value: otherLeaveRequestType }
    ]
  }

  public getLeaveTimePeriods(): { value: string }[] {
    return [
      { value: lateStartPeriodType },
      { value: earlyEndPeriodType },
      { value: singleDayPeriodType },
      { value: multipleDaysPeriodType }
    ]
  }

  public getSelectedTimePeriod(): ValidLeavePeriodType {
    return this.timePeriod as ValidLeavePeriodType
  }

  public updateSelectedPerson(name: string): void {
    const employee = this.employees.find((e) => e.name === name)

    if (employee) {
      this.employeeId = employee.id
    }
  }

  public updateAdditionalComment(text: string): void {
    this.leaveDetails = text
    this.updateView()
  }

  public updateLeaveRequestTimePeriod(timePeriod: string): void {
    this.timePeriod = timePeriod
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public updateLeaveRequestReason(reason: string): void {
    this.leaveReason = reason
  }

  public updateLeaveRequestStartDate(startDate: moment.Moment): void {
    this.leaveStartDate = startDate.toISOString()
  }

  public updateLeaveRequestEndDate(endDate: moment.Moment): void {
    this.leaveEndDate = endDate.toISOString()
  }

  public updateSendSms(sendSms: boolean): void {
    this.sendSms = sendSms
  }

  public isLoading(): boolean {
    return this.loading
  }

  public hasSubmittedForm(): boolean {
    return this.hasSubmitted
  }

  public async submitForm(): Promise<void> {
    this.loading = true
    this.updateView()

    const response = await this.leaveRequestSubmitter.submitLeaveRequest(this.formRequestParam(), this.sendSms)
    this.loading = false
    this.updateView()

    if (!response.code) {
      this.error = response.message
      this.updateView()
    } else {
      this.hasSubmitted = true
      this.success = response.message
      this.updateView()
    }
  }

  private formRequestParam(): LeaveRequestParam {
    return {
      additionalComments: this.leaveDetails,
      clientId: '',
      employeeId: this.employeeId,
      leaveType: this.leavePeriodTypeFormatter.getLeavePeriodRecordByType(
        this.timePeriod as ValidLeavePeriodType
      ),
      leaveDatetime: this.leaveStartDate,
      returnDatetime: this.leaveEndDate,
      reasonType: this.getRequiredLeaveReasonType(),
      requesterRole: 'THERAPIST'
    }
  }

  private getRequiredLeaveReasonType(): string {
    if (this.leaveReason === sickLeaveRequestType) {
      return 'SICKNESS'
    }

    if (this.leaveReason === ptoLeaveRequestType) {
      return 'PAID_TIME_OFF'
    }

    if (this.leaveReason === covidLeaveRequestType) {
      return 'COVID_19'
    }

    return 'OTHER'
  }
}

export default EmployeeLeaveRequestPresenter
