import SearchFormDto from '../../../../Model/Dtos/SearchFormDto'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import IDirectoryPresenter from '../../Presenter/IDirectoryPresenter'
import ISearchFormPresenter from './ISearchFormPresenter'

class SearchFormPresenter implements ISearchFormPresenter {
  private entityType: string
  private serviceCenter: string | null
  private active: boolean | null
  private id: string | null
  private initials: string | null
  private firstName: string | null
  private middleName: string | null
  private lastName: string | null
  private phone: string | null
  private email: string | null
  private isLoading: boolean
  private view: ISubscribingView | null

  constructor(private readonly parentPresenter: IDirectoryPresenter) {
    this.entityType = 'CLIENT'
    this.serviceCenter = null
    this.active = null
    this.id = null
    this.initials = null
    this.firstName = null
    this.middleName = null
    this.lastName = null
    this.phone = null
    this.email = null
    this.isLoading = false
    this.view = null
    this.initialize()
  }

  public getFormState(): SearchFormDto {
    return {
      entityType: this.entityType,
      serviceCenter: this.serviceCenter,
      active: this.active,
      id: this.id,
      initials: this.initials,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      phone: this.phone,
      email: this.email
    }
  }

  public async submitForm(): Promise<void> {
    await this.parentPresenter.searchDirectory()
  }

  public setEntityType(entityType: string): void {
    this.entityType = entityType
  }

  public setServiceCenter(serviceCenter: string): void {
    this.serviceCenter = serviceCenter
  }

  public setActive(active: boolean): void {
    this.active = active !== null ? active : null
  }

  public setId(id: string): void {
    this.id = id || null
  }

  public setInitials(initials: string): void {
    this.initials = initials || null
  }

  public setFirstName(firstName: string): void {
    this.firstName = firstName || null
  }

  public setMiddleName(middleName: string): void {
    this.middleName = middleName || null
  }

  public setLastName(lastName: string): void {
    this.lastName = lastName || null
  }

  public setPhone(phone: string): void {
    this.phone = phone || null
  }

  public setEmail(email: string): void {
    this.email = email || null
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private initialize(): void {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default SearchFormPresenter
