import ApiClientDto from "../../ClientCrud/ApiClientDto"
import { SCHEDULER_URL } from "../../constant"
import IFetcher from "../../Drivers/IFetcher"
import ApiEmployeeDto from "../../EmployeeCrud/ApiEmployeeDto"
import IRtsGetter from "./IRtsGetter"

class RtsGetter implements IRtsGetter {
  constructor(private readonly fetcher: IFetcher) { }

  public async getRtsForClient(clientId: string): Promise<ApiEmployeeDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}rts/${clientId}/availableTherapists`
    })
    if (Array.isArray(result)) {
      return result
    }
    return []
  }

  public async getRtsForTherapist(therapistId: string): Promise<ApiClientDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}rts/${therapistId}/availableClients`
    })
    if (Array.isArray(result)) {
      return result
    }
    return []
  }

}

export default RtsGetter