import './style.css'

import { Divider, Table, Tag } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import EditPersonForm from '../EditPersonForm/EditPersonForm'
import TableData from './Interfaces/TableData'
import IResultTablePresenter from './Presenter/IResultTablePresenter'

type Props = {
  presenter: IResultTablePresenter
}

class ResultTable extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <Content
        className='site-layout'
        style={{ backgroundColor: 'white', padding: '0 25px', overflowY: 'scroll' }}
      >
        <Divider orientation='center'>Search Results: {presenter.getSearchEntityType()}</Divider>
        <Table
          columns={presenter.getColumns()}
          dataSource={presenter.getSearchResults()}
          expandable={{
            expandedRowRender: (record) => {
              switch (presenter.getSearchEntityType()) {
                case 'CLIENT':
                  return <Fragment>
                    <div style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '5px' }}>Therapists:</span>
                      {record.therapists.map(t => <Tag key={t.uuid}>{`${t.firstName || ''} ${t.middleName || ''} ${t.lastName || ''}`.trim()}</Tag>)}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '5px' }}>Bcbas:</span>
                      {record.bcbas.map(b => <Tag key={b.id}>{`${b.firstName || ''} ${b.middleName || ''} ${b.lastName || ''}`.trim()}</Tag>)}
                    </div>
                  </Fragment>
                case 'THERAPIST':
                  return <Fragment>
                    <div style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '5px' }}>Clients:</span>
                      {record.clients.map(c => <Tag key={c.uuid}>{`${c.firstName || ''} ${c.middleName || ''} ${c.lastName || ''}`.trim()}</Tag>)}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '5px' }}>Bcbas:</span>
                      {record.bcbas.map(b => <Tag key={b.id}>{`${b.firstName || ''} ${b.middleName || ''} ${b.lastName || ''}`.trim()}</Tag>)}
                    </div>
                  </Fragment>
                case 'GUARDIAN':
                  return <Fragment>
                    <span style={{ marginRight: '8px' }}>Children:</span>
                    {record.relatedClients.map((client) => (
                      <Tag key={client.uuid}>
                        {`${client.firstName || ''} ${client.middleName || ''} ${client.lastName || ''
                          }`.trim()}
                      </Tag>
                    ))}
                  </Fragment>
                default:
                  return null
              }
            },
            rowExpandable: (record) => {
              switch (presenter.getSearchEntityType()) {
                case 'CLIENT':
                  return record.therapists && record.therapists.length > 0
                case 'THERAPIST':
                  return record.clients && record.clients.length > 0
                case 'GUARDIAN':
                  return record.relatedClients && record.relatedClients.length > 0
                default:
                  return false
              }
            }
          }}
          id='search-result-table'
          loading={presenter.getIsLoading()}
          onRow={this.selectRowCallback}
          pagination={{ position: ['bottomCenter'] }}
        />
        <EditPersonForm presenter={presenter.getEditPersonFormPresenter()} />
      </Content>
    )
  }

  update = (): void => {
    this.setState({})
  }

  private selectRowCallback = (record: TableData) => {
    return {
      onClick: async () => {
        const editPersonFormPresenter = this.props.presenter.getEditPersonFormPresenter()
        editPersonFormPresenter.setEntityType(this.props.presenter.getSearchEntityType())
        editPersonFormPresenter.setOpenModal(true)
        switch (this.props.presenter.getSearchEntityType()) {
          case 'CLIENT':
            editPersonFormPresenter.setFormData({
              uuid: record.uuid,
              firstName: record.firstName,
              middleName: record.middleName,
              lastName: record.lastName,
              initials: record.initials,
              position: null,
              phone: null,
              email: null,
              street1: record.street1,
              street2: record.street2,
              city: record.city,
              state: record.state,
              zipCode: record.zipCode,
              serviceCenter: record.serviceCenter,
              therapyStartDate: record.therapyStartDate,
              therapyEndDate: record.therapyEndDate,
              startDate: null,
              terminationDate: null,
              active: record.active,
              guardians: [],
              clients: []
            })
            await Promise.all([
              editPersonFormPresenter.fetchGuardianOptions(),
              editPersonFormPresenter.fetchGuardians()
            ])
            break
          case 'THERAPIST':
            editPersonFormPresenter.setFormData({
              uuid: record.uuid,
              firstName: record.firstName,
              middleName: record.middleName,
              lastName: record.lastName,
              initials: null,
              position: record.position,
              phone: record.phone,
              email: record.email,
              street1: record.street1,
              street2: record.street2,
              city: record.city,
              state: record.state,
              zipCode: record.zipCode,
              serviceCenter: record.serviceCenter,
              therapyStartDate: null,
              therapyEndDate: null,
              startDate: record.startDate,
              terminationDate: record.terminationDate,
              active: record.active,
              guardians: [],
              clients: []
            })
            await editPersonFormPresenter.fetchGuardianOptions()
            break
          case 'GUARDIAN':
            editPersonFormPresenter.setFormData({
              uuid: record.uuid,
              firstName: record.firstName,
              middleName: record.middleName,
              lastName: record.lastName,
              initials: null,
              position: null,
              phone: record.phone,
              email: record.email,
              street1: null,
              street2: null,
              city: null,
              state: null,
              zipCode: null,
              serviceCenter: null,
              therapyStartDate: null,
              therapyEndDate: null,
              startDate: null,
              terminationDate: null,
              active: null,
              guardians: [],
              clients: record.relatedClients.map((client) => {
                return {
                  clientId: client.uuid,
                  relationship: 'PARENT'
                }
              })
            })
            await editPersonFormPresenter.fetchClientOptions()
            break
          case 'BCBA':
            editPersonFormPresenter.setFormData({
              uuid: record.uuid,
              firstName: record.firstName,
              middleName: record.middleName,
              lastName: record.lastName,
              initials: null,
              position: null,
              phone: record.phone,
              email: record.email,
              street1: null,
              street2: null,
              city: null,
              state: null,
              zipCode: null,
              serviceCenter: null,
              therapyStartDate: null,
              therapyEndDate: null,
              startDate: null,
              terminationDate: null,
              active: null,
              guardians: [],
              clients: []
            })
            await editPersonFormPresenter.fetchClientOptions()
            break
          default:
            break
        }
      }
    }
  }
}

export default ResultTable
