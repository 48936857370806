import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, FormInstance, Input, Modal, Row, Select } from 'antd'
import React, { Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import IEditPersonFormPresenter from '../Presenter/IEditPersonFormPresenter'

type Props = {
  presenter: IEditPersonFormPresenter
}

class GuardianForm extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  componentDidUpdate(): void {
    this.formRef.current!.setFields([
      {
        name: ['guardianFirstName'],
        value: this.props.presenter.getFormData().firstName || ''
      },
      {
        name: ['guardianMiddleName'],
        value: this.props.presenter.getFormData().middleName || ''
      },
      {
        name: ['guardianLastName'],
        value: this.props.presenter.getFormData().lastName || ''
      },
      {
        name: ['guardianPhone'],
        value: this.props.presenter.getFormData().phone || ''
      },
      {
        name: ['guardianEmail'],
        value: this.props.presenter.getFormData().email || ''
      },
      {
        name: ['guardianClients'],
        value: this.props.presenter.getFormData().clients || []
      }
    ])
    this.props.presenter.getFormData().clients.forEach((client, index) => {
      this.formRef.current!.setFields([
        {
          name: ['guardianClients', index, 'client'],
          value: client.clientId
        },
        {
          name: ['guardianClients', index, 'relationship'],
          value: client.relationship
        }
      ])
    })
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props
    const clientOptions = this.props.presenter.getClientOptions()

    return (
      <Modal
        centered
        title={`Update Guardian Profile: ${presenter.getFormData().uuid}`}
        visible={presenter.getOpenModal()}
        okText='Update Guardian Profile'
        onOk={this.submitForm}
        confirmLoading={presenter.getIsLoading()}
        onCancel={this.cancelForm}
        width={800}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Form
          initialValues={presenter.getFormData()}
          layout='vertical'
          name='guardianDetailsForm'
          scrollToFirstError
          ref={this.formRef}
        >
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label='First Name'
                name='guardianFirstName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a first name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setFirstName(e.target.value)}
                  value={presenter.getFormData().firstName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Middle Name' name='guardianMiddleName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setMiddleName(e.target.value)}
                  value={presenter.getFormData().middleName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='Last Name'
                name='guardianLastName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a last name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setLastName(e.target.value)}
                  value={presenter.getFormData().lastName || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label='Phone'
                name='guardianPhone'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a phone number'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setPhone(e.target.value)}
                  value={presenter.getFormData().phone || ''}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Email' name='guardianEmail'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setEmail(e.target.value)}
                  value={presenter.getFormData().email || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='center' style={{ marginTop: 0 }}>
            Attach Clients
          </Divider>
          <Form.List name='guardianClients'>
            {(fields, { add, remove }) => (
              <Fragment>
                {fields.map(({ key, name, ...restField }) => {
                  const data = presenter.getFormData().clients[name]
                  if (!data) {
                    return null
                  }
                  return (
                    <Row gutter={8} key={key}>
                      <Col span={14}>
                        <Form.Item {...restField} label='Client' name={[name, 'client']}>
                          <Select
                            allowClear
                            disabled={presenter.getIsLoading()}
                            filterOption={(input, option) => {
                              const isGroup = Array.isArray(option!.options)
                              if (isGroup) {
                                return false
                              }
                              return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }}
                            onChange={(uuid) => this.selectExistingClient(uuid, name)}
                            value={data.clientId}
                            showSearch
                          >
                            {clientOptions.map((client) => (
                              <Option key={client.uuid} value={client.uuid}>
                                {`${client.firstName || ''} ${client.middleName || ''} ${client.lastName || ''
                                  }`.trim()}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <Form.Item
                          {...restField}
                          label='Relationship'
                          name={[name, 'relationship']}
                          rules={[
                            {
                              required: true,
                              message: 'Please select a relationship'
                            }
                          ]}
                        >
                          <Select
                            onChange={(relationship) => (data.relationship = relationship)}
                            value={data.relationship || ''}
                          >
                            <Option value='PARENT'>PARENT</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        span={1}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            const { clients } = presenter.getFormData()
                            clients.splice(name, 1)
                            remove(name)
                          }}
                        />
                      </Col>
                    </Row>
                  )
                })}
                <Form.Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const { clients } = presenter.getFormData()
                      clients.push({
                        clientId: null,
                        relationship: null
                      })
                      add()
                    }}
                    type='dashed'
                  >
                    Add Client
                  </Button>
                </Form.Item>
              </Fragment>
            )}
          </Form.List>
        </Form>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private selectExistingClient = (uuid: string, fieldName: number) => {
    const data = this.props.presenter.getFormData().clients[fieldName]
    data.clientId = uuid
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitGuardianForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.props.presenter.resetFormData()
    this.formRef.current!.resetFields()
    this.props.presenter.setOpenModal(false)
  }
}

export default GuardianForm
