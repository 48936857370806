import Cookies from 'js-cookie'

import Fetcher from './Drivers/Fetcher'

export const SCHEDULER_URL = 'https://bs-scheduler.behaviorspan.xyz/api/v1/'
export const BASE_URL = 'https://bs-ims.behaviorspan.xyz/api/v1/'
export const badRequestHttpCode = 400
export const okHttpCode = 200
export const acceptedHttpCode = 202
export const noContentHttpCode = 204
export const messageDisplayTime = 2000
export const redirectIfNotAuthorized = async (): Promise<void> => {
  const cookie = Cookies.get('auth')
  if (!cookie) {
    signOut()
    return
  }

  try {
    const response = await new Fetcher().fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}auth/authenticate/query?jwt=${cookie}&roles=OPERATION_MANAGER`
    })
    if (!response.code || response.code !== acceptedHttpCode) {
      signOut()
      return
    }
  } catch (error) {
    signOut()
    return
  }
}
export const isPersonAuthorized = (): boolean => {
  return !!Cookies.get('auth')
}

export const signOut = (): void => {
  Cookies.remove('auth')
  localStorage.clear()
  history.pushState('', document.title, window.location.pathname + window.location.search)
  window.location.pathname = '/login'
}
