import { Tag } from 'antd'
import React from 'react'

const ClientTableColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName'
  },
  {
    title: 'Id',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Initials',
    dataIndex: 'initials',
    key: 'initials'
  },
  {
    title: 'Service Center',
    dataIndex: 'serviceCenter',
    key: 'serviceCenter',
    render: (serviceCenter: string) => (
      <Tag color={serviceCenter === 'Aurora' ? 'cyan' : 'gold'}>{serviceCenter}</Tag>
    )
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (active: boolean) => (
      <Tag color={active ? 'success' : 'error'}>{active ? 'Active' : 'Inactive'}</Tag>
    )
  }
]

export default ClientTableColumns
