import { Col, Form, FormInstance, Input, Modal, Row } from 'antd'
import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import IAddPersonFormPresenter from '../Presenter/IAddPersonFormPresenter'

type Props = {
  presenter: IAddPersonFormPresenter
}

class BcbaForm extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <Modal
        centered
        title='Create New Bcba Profile'
        visible={presenter.getOpenModal()}
        okText='Create Bcba Profile'
        onOk={this.submitForm}
        confirmLoading={presenter.getIsLoading()}
        onCancel={this.cancelForm}
        width={800}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Form
          initialValues={presenter.getFormData()}
          layout='vertical'
          name='therapistDetailsForm'
          scrollToFirstError
          ref={this.formRef}
        >
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label='First Name'
                name='bcbaFirstName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a first name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setFirstName(e.target.value)}
                  value={presenter.getFormData().firstName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Middle Name' name='bcbaMiddleName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setMiddleName(e.target.value)}
                  value={presenter.getFormData().middleName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='Last Name'
                name='bcbaLastName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a last name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setLastName(e.target.value)}
                  value={presenter.getFormData().lastName || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label='Phone' name='bcbaPhone'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setPhone(e.target.value)}
                  value={presenter.getFormData().phone || ''}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Email' name='bcbaEmail'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setEmail(e.target.value)}
                  value={presenter.getFormData().email || ''}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitBcbaForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.props.presenter.resetFormData()
    this.formRef.current!.resetFields()
    this.props.presenter.setOpenModal(false)
  }
}

export default BcbaForm
