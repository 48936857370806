import moment from "moment-timezone"

import IAppointmentCreator from "../../../../AppointmentCrud/AppointmentCreator/IAppointmentCreator"
import AppointmentDto from "../../../../AppointmentCrud/AppointmentDto"
import ISubscribingView from "../../../../Model/Interfaces/ISubscribingViewPresenter"
import ICalendarPresenter from "../../Presenter/ICalendarPresenter"
import IAppointmentDetailsPresenter from "./IAppointmentDetailsPresenter"

class AppointmentDetailsPresenter implements IAppointmentDetailsPresenter {
  private editableAppointment: AppointmentDto | null
  private modalComments: string | null
  private isEditable: boolean
  private isLoading: boolean
  private openCancelModal: boolean
  private openUndoModal: boolean
  private errorMessage: string | null
  private view: ISubscribingView | null

  constructor(private readonly appointmentCreator: IAppointmentCreator, private readonly parentPresenter: ICalendarPresenter) {
    this.editableAppointment = null
    this.modalComments = null
    this.isEditable = false
    this.isLoading = false
    this.openCancelModal = false
    this.openUndoModal = false
    this.errorMessage = null
    this.view = null
    this.initialize()
  }

  public getEditableAppointment(): AppointmentDto | null {
    return this.editableAppointment
  }

  public getModalComments(): string | null {
    return this.modalComments
  }

  public getParentPresenter(): ICalendarPresenter {
    return this.parentPresenter
  }

  public getIsEditable(): boolean {
    return this.isEditable
  }

  public getOpenCancelModal(): boolean {
    return this.openCancelModal
  }

  public getOpenUndoModal(): boolean {
    return this.openUndoModal
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public setEditableAppointment(appointment: AppointmentDto | null): void {
    this.editableAppointment = JSON.parse(JSON.stringify(appointment))
  }

  public setModalComments(modalComments: string | null) {
    this.modalComments = modalComments || null
  }

  public setIsEditable(isEditable: boolean) {
    this.isEditable = isEditable
    this.updateView()
  }

  public setOpenCancelModal(openCancelModal: boolean): void {
    this.openCancelModal = openCancelModal
    this.updateView()
  }

  public setOpenUndoModal(openUndoModal: boolean): void {
    this.openUndoModal = openUndoModal
    this.updateView()
  }

  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
    this.updateView()
  }

  public async modifyAppointment(): Promise<void> {
    this.errorMessage = null
    this.setIsLoading(true)
    try {
      if (!this.editableAppointment!.clientId && !this.editableAppointment!.therapistId) {
        throw 'Client and therapist cannot both be empty'
      }
      const startMoment = moment.utc(this.editableAppointment!.scheduledStart)
      const endMoment = moment.utc(this.editableAppointment!.scheduledEnd)
      if (startMoment.isSameOrAfter(endMoment)) {
        throw 'Appointment start time should be before end time'
      }
      if (startMoment.add(1, 'days').isBefore(endMoment)) {
        throw 'Appointment shouldn\'t last longer than 24 hours'
      }
      await this.appointmentCreator.modifyAppointment(this.editableAppointment as AppointmentDto)
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.setIsLoading(false)
  }

  public async cancelAppointment(): Promise<void> {
    this.errorMessage = null
    this.setIsLoading(true)
    try {
      await this.appointmentCreator.cancelAppointment(this.editableAppointment!.id as number, this.modalComments)
      this.modalComments = null
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.setIsLoading(false)
  }

  public async undoAppointmentCancellation(): Promise<void> {
    this.errorMessage = null
    this.setIsLoading(true)
    try {
      await this.appointmentCreator.undoCancellation(this.editableAppointment!.id as number, this.modalComments)
      this.modalComments = null
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.setIsLoading(false)
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default AppointmentDetailsPresenter