import { badRequestHttpCode, okHttpCode } from '../../../constant'
import ILeaveRequestStatusUpdater from '../../../LeaveRequest/LeaveRequestStatusUpdater/ILeaveRequestStatusUpdater'
import RequestResponse from '../../../Model/Dtos/RequestResponse'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import IRequestUpdateHandler from './IRequestUpdateHandler'

class RequestUpdateHandler implements IRequestUpdateHandler {
  private view: ISubscribingView | null
  private loading: boolean

  constructor(private readonly statusUpdater: ILeaveRequestStatusUpdater) {
    this.loading = false
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  public isLoading(): boolean {
    return this.loading
  }

  public async approveLeaveRequest(requestId: number): Promise<RequestResponse> {
    this.loading = true
    this.updateView()
    const result = await this.statusUpdater.updateLeaveRequestStatus(requestId, 'approve')

    this.loading = false
    this.updateView()

    if (this.failedToUpdateRequest(result)) {
      return {
        code: badRequestHttpCode,
        message: 'Failed to update request status. Please try again.'
      }
    }

    window.location.reload()
    return {
      code: okHttpCode,
      message: 'Successfully updated request status.'
    }
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private failedToUpdateRequest(result: object): boolean {
    return (
      Object.prototype.hasOwnProperty.call(result, 'message') &&
      Object.prototype.hasOwnProperty.call(result, 'code')
    )
  }

  public async denyLeaveRequest(requestId: number): Promise<RequestResponse> {
    this.loading = true
    this.updateView()
    const result = await this.statusUpdater.updateLeaveRequestStatus(requestId, 'deny')
    this.loading = false
    this.updateView()

    if (this.failedToUpdateRequest(result)) {
      return {
        code: badRequestHttpCode,
        message: 'Failed to update request status. Please try again.'
      }
    }

    window.location.reload()
    return {
      code: okHttpCode,
      message: 'Successfully updated request status.'
    }
  }
}

export default RequestUpdateHandler
