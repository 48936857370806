import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch
} from 'antd'
import { FieldData } from 'rc-field-form/es/interface'
import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import IEditAssignmentPresenter from './Presenter/IEditAssignmentPresenter'

type Props = {
  presenter: IEditAssignmentPresenter
}

class EditAssignmentForm extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  componentDidMount() {
    this.props.presenter.setView(this)
  }

  componentDidUpdate(): void {
    this.initializeFormData()
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props

    return (
      <Modal
        centered
        confirmLoading={presenter.getIsLoading()}
        footer={[
          <Button
            danger
            key='delete'
            loading={presenter.getIsLoading()}
            onClick={() => presenter.setOpenConfirmDeleteModal(true)}
            type='primary'
          >
            Delete
          </Button>,
          <Button key='cancel' loading={presenter.getIsLoading()} onClick={this.cancelForm}>
            Cancel
          </Button>,
          <Button
            key='update'
            loading={presenter.getIsLoading()}
            onClick={this.submitForm}
            type='primary'
          >
            Update
          </Button>
        ]}
        title='Update Schedule'
        visible={presenter.getOpenModal()}
        onOk={this.submitForm}
        onCancel={this.cancelForm}
        width={800}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Form
          initialValues={presenter.getFormData()}
          layout='vertical'
          name='hourPreferenceEditForm'
          scrollToFirstError
          ref={this.formRef}
        >
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label='Client'
                name='client'
                rules={[
                  {
                    required: true,
                    message: 'Please select a client'
                  }
                ]}
              >
                <Select disabled value={presenter.getFormData().clientId}>
                  {presenter.getClientOptions().map((client) => (
                    <Option key={client.uuid} value={client.uuid}>
                      {`${client.firstName || ''} ${client.middleName || ''} ${client.lastName || ''
                        }`.trim()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Therapist'>
                <Select disabled value={presenter.getFormData().therapistId}>
                  {presenter.getTherapistOptions().map((therapist) => (
                    <Option key={therapist.uuid} value={therapist.uuid}>
                      {`${therapist.firstName || ''} ${therapist.middleName || ''} ${therapist.lastName || ''
                        }`.trim()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Bcba' name='bcba'>
                <Select disabled value={presenter.getFormData().bcbaId}>
                  {presenter.getBcbaOptions().map((bcba) => (
                    <Option key={bcba.uuid} value={bcba.uuid}>
                      {`${bcba.firstName || ''} ${bcba.middleName || ''} ${bcba.lastName || ''
                        }`.trim()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
            <Row key={day} gutter={8}>
              <Col span={4}>
                <Form.Item label='Day'>
                  <Input disabled value={day} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label='Drop Off Hour'
                  name={`${day.toLowerCase()}DropOffHour`}
                  rules={[
                    {
                      required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                      message: 'Please enter a valid hour (0 - 23)'
                    }
                  ]}
                >
                  <InputNumber
                    disabled={
                      presenter.getIsLoading() ||
                      !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                    }
                    min={0}
                    max={23}
                    onChange={(dropOffHour) =>
                      presenter.setDropOffHour(day.toLowerCase(), dropOffHour)
                    }
                    style={{ width: '100%' }}
                    value={
                      (presenter.getFormData().schedule as any)[day.toLowerCase()]
                        ? (presenter.getFormData().schedule as any)[day.toLowerCase()].dropOffHour
                        : null
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label='Drop Off Minute'
                  name={`${day.toLowerCase()}DropOffMinute`}
                  rules={[
                    {
                      required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                      message: 'Please enter a valid minute (0 - 59)'
                    }
                  ]}
                >
                  <InputNumber
                    disabled={
                      presenter.getIsLoading() ||
                      !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                    }
                    min={0}
                    max={59}
                    onChange={(dropOffMinute) =>
                      presenter.setDropOffMinute(day.toLowerCase(), dropOffMinute)
                    }
                    style={{ width: '100%' }}
                    value={
                      (presenter.getFormData().schedule as any)[day.toLowerCase()]
                        ? (presenter.getFormData().schedule as any)[day.toLowerCase()].dropOffMinute
                        : null
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label='Pick Up Hour'
                  name={`${day.toLowerCase()}PickUpHour`}
                  rules={[
                    {
                      required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                      message: 'Please enter a valid hour (0 - 23)'
                    }
                  ]}
                >
                  <InputNumber
                    disabled={
                      presenter.getIsLoading() ||
                      !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                    }
                    min={0}
                    max={23}
                    onChange={(pickUpHour) =>
                      presenter.setPickUpHour(day.toLowerCase(), pickUpHour)
                    }
                    style={{ width: '100%' }}
                    value={
                      (presenter.getFormData().schedule as any)[day.toLowerCase()]
                        ? (presenter.getFormData().schedule as any)[day.toLowerCase()].pickUpHour
                        : null
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label='Pick Up Minute'
                  name={`${day.toLowerCase()}PickUpMinute`}
                  rules={[
                    {
                      required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                      message: 'Please enter a valid minute (0 - 59)'
                    }
                  ]}
                >
                  <InputNumber
                    disabled={
                      presenter.getIsLoading() ||
                      !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                    }
                    min={0}
                    max={59}
                    onChange={(pickUpMinute) =>
                      presenter.setPickUpMinute(day.toLowerCase(), pickUpMinute)
                    }
                    style={{ width: '100%' }}
                    value={
                      (presenter.getFormData().schedule as any)[day.toLowerCase()]
                        ? (presenter.getFormData().schedule as any)[day.toLowerCase()].pickUpMinute
                        : null
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Include?' name={`${day.toLowerCase()}Included`}>
                  <Switch
                    checked={(presenter.getFormData().schedule as any)[day.toLowerCase()]}
                    checkedChildren='Yes'
                    onChange={(dayIncluded) =>
                      this.updateDayIncluded(day.toLowerCase(), dayIncluded)
                    }
                    unCheckedChildren='No'
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
        <Modal
          centered
          confirmLoading={presenter.getIsLoading()}
          footer={[
            <Button
              disabled={presenter.getIsLoading()}
              key='cancel'
              loading={presenter.getIsLoading()}
              onClick={this.closeDeleteConfirmationModal}
            >
              Cancel
            </Button>,
            <Button
              danger
              disabled={presenter.getIsLoading()}
              key='delete'
              loading={presenter.getIsLoading()}
              onClick={this.deleteRecord}
              type='primary'
            >
              Delete
            </Button>
          ]}
          title='Confirm Delete'
          visible={presenter.getOpenConfirmDeleteModal()}
          onOk={this.deleteRecord}
          onCancel={this.closeDeleteConfirmationModal}
        >
          {presenter.getErrorMessage() ? (
            <div className='error'>* {presenter.getErrorMessage()} *</div>
          ) : null}
          <p>Are you sure you want to delete this record?</p>
        </Modal>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.props.presenter.resetFormData()
    this.formRef.current!.resetFields()
    this.props.presenter.setOpenModal(false)
  }

  private deleteRecord = async () => {
    await this.props.presenter.deleteRecord()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private closeDeleteConfirmationModal = () => {
    this.props.presenter.setOpenConfirmDeleteModal(false)
  }

  private updateDayIncluded = (day: string, dayIncluded: boolean) => {
    if (dayIncluded) {
      const originalSchedule = (this.props.presenter.getOriginalSchedule() as any)[day]
      if (originalSchedule) {
        this.props.presenter.setDaySchedule(day, JSON.parse(JSON.stringify(originalSchedule)))
      } else {
        this.props.presenter.setDaySchedule(day, {
          dropOffHour: 8,
          dropOffMinute: 0,
          pickUpHour: 15,
          pickUpMinute: 0
        })
      }
      this.update()
    } else {
      this.props.presenter.setDaySchedule(day, null)
      this.formRef.current!.setFields([
        {
          name: [`${day}DropOffHour`],
          value: null
        },
        {
          name: [`${day}DropOffMinute`],
          value: null
        },
        {
          name: [`${day}PickUpHour`],
          value: null
        },
        {
          name: [`${day}PickUpMinute`],
          value: null
        }
      ])
      this.update()
    }
  }

  private initializeFormData(): void {
    if (!this.formRef.current) {
      return
    }
    const fields: FieldData[] = []
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
    days.forEach((day) => {
      if ((this.props.presenter.getFormData().schedule as any)[day]) {
        fields.push(
          ...[
            {
              name: [`${day}DropOffHour`],
              value: (this.props.presenter.getFormData().schedule as any)[day].dropOffHour
            },
            {
              name: [`${day}DropOffMinute`],
              value: (this.props.presenter.getFormData().schedule as any)[day].dropOffMinute
            },
            {
              name: [`${day}PickUpHour`],
              value: (this.props.presenter.getFormData().schedule as any)[day].pickUpHour
            },
            {
              name: [`${day}PickUpMinute`],
              value: (this.props.presenter.getFormData().schedule as any)[day].pickUpMinute
            },
            {
              name: [`${day}Included`],
              value: true
            }
          ]
        )
      } else {
        fields.push(
          ...[
            {
              name: [`${day}Included`],
              value: false
            }
          ]
        )
      }
    })
    this.formRef.current!.setFields(fields)
    this.formRef.current!.setFields([
      {
        name: ['client'],
        value: this.props.presenter.getFormData().clientId
      },
      {
        name: ['therapist'],
        value: this.props.presenter.getFormData().therapistId
      },
      {
        name: ['bcba'],
        value: this.props.presenter.getFormData().bcbaId
      }
    ])
  }
}

export default EditAssignmentForm
