import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import IEditPersonFormPresenter from './Presenter/IEditPersonFormPresenter'
import BcbaForm from './Subforms/BcbaForm'
import ClientForm from './Subforms/ClientForm'
import GuardianForm from './Subforms/GuardianForm'
import TherapistForm from './Subforms/TherapistForm'

type Props = {
  presenter: IEditPersonFormPresenter
}

class EditPersonForm extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    switch (presenter.getEntityType()) {
      case 'CLIENT':
        return <ClientForm presenter={presenter} />
      case 'THERAPIST':
        return <TherapistForm presenter={presenter} />
      case 'GUARDIAN':
        return <GuardianForm presenter={presenter} />
      case 'BCBA':
        return <BcbaForm presenter={presenter} />
      default:
        return <div></div>
    }
  }

  update(): void {
    this.setState({})
  }
}

export default EditPersonForm
