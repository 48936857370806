import './theme.css'

import React, { Component, ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import AppointmentCreator from './AppointmentCrud/AppointmentCreator/AppointmentCreator'
import AppointmentGetter from './AppointmentCrud/AppointmentGetter/AppointmentGetter'
import BcbaCreator from './BcbaCrud/BcbaCreator/BcbaCreator'
import BcbaGetter from './BcbaCrud/BcbaGetter/BcbaGetter'
import ClientCreator from './ClientCrud/ClientCreator/ClientCreator'
import ClientGetter from './ClientCrud/ClientGetter/ClientGetter'
import PartialClientGetter from './ClientCrud/PartialClientGetter/PartialClientGetter'
import Fetcher from './Drivers/Fetcher'
import EmployeeGetter from './EmployeeCrud/EmployeeGetter/EmployeeGetter'
import EmployeePoster from './EmployeeCrud/EmployeePoster/EmployeePoster'
import PartialEmployeeGetter from './EmployeeCrud/PartialEmployeeGetter/PartialEmployeeGetter'
import GuardianGetter from './GuardianCrud/GuardianGetter/GuardianGetter'
import GuardianPoster from './GuardianCrud/GuardianPoster/GuardianPoster'
import LeaveRequestSubmitter from './LeaveRequest/LeaveRequestSubmitter/LeaveRequestSubmitter'
import NotificationCreator from './Notifications/NotificationCreator/NotificationCreator'
import RegistrationCreator from './Registration/RegistrationCreator/RegistrationCreator'
import RegistrationGetter from './Registration/RegistrationGetter/RegistrationGetter'
import AppointmentCalendar from './Routes/AppointmentCalendar/AppointmentCalendar'
import CalendarPresenter from './Routes/AppointmentCalendar/Presenter/CalendarPresenter'
import ClientAbsenceNotification from './Routes/ClientAbsenceNotification/ClientAbsenceNotification'
import ClientAbsenceNotificationPresenter from './Routes/ClientAbsenceNotification/Presenter/ClientAbsenceNotificationPresenter'
import ClientAssignment from './Routes/ClientAssignment/ClientAssignment'
import ClientAssignmentPresenter from './Routes/ClientAssignment/Presenter/ClientAssignmentPresenter'
import Dashboard from './Routes/Dashboard/Dashboard'
import {
  default as LeavePeriodTypeFormatter,
  default as LeaveTypeFormatter
} from './Routes/Dashboard/LeavePeriodTypeFormatter/LeavePeriodTypeFormatter'
import LeaveReasonFormatter from './Routes/Dashboard/LeaveReasonFormatter/LeaveReasonFormatter'
import DashboardPresenterFactory from './Routes/Dashboard/Presenter/DashboardPresenterFactory'
import Directory from './Routes/Directory/Directory'
import DirectoryPresenter from './Routes/Directory/Presenter/DirectoryPresenter'
import EmployeeLeaveRequest from './Routes/EmployeeLeaveRequestForm/EmployeeLeaveRequest'
import EmployeeLeaveRequestPresenter from './Routes/EmployeeLeaveRequestForm/Presenter/EmployeeLeaveRequestPresenter'
import Login from './Routes/Login'
import RtsPresenter from './Routes/Rts/Presenter/RtsPresenter'
import Rts from './Routes/Rts/Rts'
import ReschedulerPresenter from './Routes/Scheduling/Presenter/ReschedulerPresenter'
import Rescheduler from './Routes/Scheduling/Rescheduler'
import RtsCreator from './RtsCrud/RtsCreator/RtsCreator'
import RtsGetter from './RtsCrud/RtsGetter/RtsGetter'
import RescheduleCreator from './Scheduling/RescheduleCreator/RescheduleCreator'
import ReschedulerGetter from './Scheduling/ReschedulerGetter/ReschedulerGetter'

class App extends Component {
  render(): ReactElement {
    return (
      <Switch>
        <Route path='/login' component={Login} />
        <Route
          exact
          path='/'
          component={() => {
            const presenter = new DashboardPresenterFactory()
            return <Dashboard presenter={presenter} />
          }}
        />
        <Route
          path='/employeeLeaveRequest'
          component={() => {
            const presenter = new EmployeeLeaveRequestPresenter(
              new PartialEmployeeGetter(new Fetcher()),
              new LeaveRequestSubmitter(new Fetcher()),
              new LeaveTypeFormatter()
            )
            return <EmployeeLeaveRequest presenter={presenter} />
          }}
        />
        <Route
          path='/clientLeaveRequest'
          component={() => {
            const presenter = new ClientAbsenceNotificationPresenter(
              new PartialClientGetter(new Fetcher()),
              new LeaveRequestSubmitter(new Fetcher()),
              new LeaveReasonFormatter(),
              new LeavePeriodTypeFormatter()
            )
            return <ClientAbsenceNotification presenter={presenter} />
          }}
        />
        <Route
          path='/rescheduler'
          component={() => {
            return (
              <Rescheduler
                presenter={
                  new ReschedulerPresenter(
                    new ReschedulerGetter(new Fetcher()),
                    new RescheduleCreator(new Fetcher()),
                    new NotificationCreator(new Fetcher()),
                    new ClientGetter(new Fetcher()),
                    new EmployeeGetter(new Fetcher()),
                    new BcbaGetter(new Fetcher())
                  )
                }
              />
            )
          }}
        />
        <Route
          path='/directory'
          component={() => {
            const presenter = new DirectoryPresenter(
              new ClientGetter(new Fetcher()),
              new EmployeeGetter(new Fetcher()),
              new GuardianGetter(new Fetcher()),
              new BcbaGetter(new Fetcher()),
              new RegistrationGetter(new Fetcher()),
              new ClientCreator(new Fetcher()),
              new EmployeePoster(new Fetcher()),
              new GuardianPoster(new Fetcher()),
              new BcbaCreator(new Fetcher())
            )
            return <Directory presenter={presenter} />
          }}
        />
        <Route
          path='/assignments'
          component={() => {
            const presenter = new ClientAssignmentPresenter(
              new RegistrationGetter(new Fetcher()),
              new RegistrationCreator(new Fetcher()),
              new ClientGetter(new Fetcher()),
              new EmployeeGetter(new Fetcher()),
              new BcbaGetter(new Fetcher())
            )
            return <ClientAssignment presenter={presenter} />
          }}
        />
        <Route
          path='/rts'
          component={() => {
            const presenter = new RtsPresenter(
              new RtsGetter(new Fetcher()),
              new RtsCreator(new Fetcher()),
              new ClientGetter(new Fetcher()),
              new EmployeeGetter(new Fetcher())
            )
            return <Rts presenter={presenter} />
          }}
        />
        <Route
          path='/calendar'
          component={() => {
            const presenter = new CalendarPresenter(
              new AppointmentGetter(new Fetcher()),
              new AppointmentCreator(new Fetcher()),
              new ClientGetter(new Fetcher()),
              new EmployeeGetter(new Fetcher()),
              new BcbaGetter(new Fetcher())
            )
            return <AppointmentCalendar presenter={presenter} />
          }}
        />
      </Switch>
    )
  }
}

export default App
