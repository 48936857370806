export const lateArrivalRecordType = 'LATE_ARRIVAL'
export const earlyLeaveRecordType = 'EARLY_LEAVE'
export const oneDayRecordType = 'ONE_DAY'
export const multipleDayRecordType = 'MULTIPLE_DAY'

type ValidEmployeeLeavePeriodRecordsType =
  | 'LATE_ARRIVAL'
  | 'EARLY_LEAVE'
  | 'ONE_DAY'
  | 'MULTIPLE_DAY'
export default ValidEmployeeLeavePeriodRecordsType
