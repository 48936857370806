import './style.css'

import { Layout } from 'antd'
import React, { Component, ReactElement } from 'react'

class Footer extends Component {
  render(): ReactElement {
    const { Footer } = Layout
    return <Footer className='footer'>Behavior Span ©2022</Footer>
  }
}

export default Footer
