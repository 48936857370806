import { BASE_URL, noContentHttpCode } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiGuardianDto from '../ApiGuardianDto'
import IGuardianGetter from './IGuardianGetter'

class GuardianGetter implements IGuardianGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getGuardianProfile(uuid: string): Promise<ApiGuardianDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}guardian/${uuid}`
    })
    if (result.code === noContentHttpCode) {
      return null
    }
    return result
  }

  public async searchGuardianProfiles(
    firstName: string | null,
    middleName: string | null,
    lastName: string | null,
    phone: string | null,
    email: string | null
  ): Promise<ApiGuardianDto[]> {
    let url = `${BASE_URL}search/guardians?`
    const queryParams = {
      firstName,
      middleName,
      lastName,
      phone,
      email
    }
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== null) {
        url = url.concat(`${key}=${value}&`)
      }
    })

    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          uuid: r.uuid,
          firstName: r.firstName,
          middleName: r.middleName,
          lastName: r.lastName,
          phone: r.phone,
          email: r.email
        }
      })
    }
    return []
  }
}

export default GuardianGetter
