import { Col, Form, FormInstance, Modal, Row, Select } from "antd"
import React, { Component, ReactElement } from "react"

import ISubscribingView from "../../../Model/Interfaces/ISubscribingViewPresenter"
import IAddRtsFormPresenter from "./Presenter/IAddRtsFormPresenter"

type Props = {
  presenter: IAddRtsFormPresenter
}

class AddRtsForm extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const { Option } = Select

    return (
      <Modal
        centered
        title='Add RTS Record'
        visible={presenter.getOpenModal()}
        okText='Assign Pair'
        onOk={this.submitForm}
        confirmLoading={presenter.getIsLoading()}
        onCancel={this.cancelForm}
        width={800}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Form
          initialValues={{
            client: null,
            therapist: null
          }}
          layout='vertical'
          name='addRtsForm'
          scrollToFirstError
          ref={this.formRef}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label='Client'
                name='client'
                rules={[
                  {
                    required: true,
                    message: 'Please select a client'
                  }
                ]}
              >
                <Select
                  disabled={presenter.getIsLoading()}
                  filterOption={(input, option) => {
                    const isGroup = Array.isArray(option!.options)
                    if (isGroup) {
                      return false
                    }
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                  onChange={(clientId) => presenter.setClientId(clientId)}
                  showSearch
                  value={presenter.getClientId()}
                >
                  {presenter.getClientOptions().map((client) => (
                    <Option key={client.uuid} value={client.uuid}>
                      {`${client.firstName || ''} ${client.middleName || ''} ${client.lastName || ''
                        }`.trim()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Therapist'
                name='therapist'
                rules={[
                  {
                    required: true,
                    message: 'Please select a therapist'
                  }
                ]}
              >
                <Select
                  disabled={presenter.getIsLoading()}
                  filterOption={(input, option) => {
                    const isGroup = Array.isArray(option!.options)
                    if (isGroup) {
                      return false
                    }
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                  onChange={(therapistId) => presenter.setTherapistId(therapistId)}
                  showSearch
                  value={presenter.getTherapistId()}
                >
                  {presenter.getTherapistOptions().map((therapist) => (
                    <Option key={therapist.uuid} value={therapist.uuid}>
                      {`${therapist.firstName || ''} ${therapist.middleName || ''} ${therapist.lastName || ''
                        }`.trim()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.formRef.current!.resetFields()
    this.props.presenter.setClientId(null)
    this.props.presenter.setTherapistId(null)
    this.props.presenter.setOpenModal(false)
  }
}

export default AddRtsForm