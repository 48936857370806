import 'antd/dist/antd.css'
import './style.css'

import { Button, Form, Input } from 'antd'
import Cookies from 'js-cookie'
import React, { Component, ReactElement } from 'react'

import { BASE_URL } from '../../constant'
import Fetcher from '../../Drivers/Fetcher'
import LoginResponseDto from './LoginResponseDto'

type State = {
  apiError: string
  busy: boolean
  password: string
  username: string
}

class Login extends Component<{}, State> {
  state = {
    apiError: '',
    busy: false,
    password: '',
    username: ''
  }

  render(): ReactElement {
    const { apiError, busy } = this.state

    return (
      <Form
        className='login'
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={this.submit}
        autoComplete='off'
      >
        {apiError && <p className='error'>{apiError}</p>}
        <Form.Item
          label='Username'
          name='username'
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input onChange={this.updateUserName} />
        </Form.Item>
        <Form.Item
          label='Password'
          name='password'
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password onChange={this.updatePassword} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={busy} type='primary' htmlType='submit'>
            Log in
          </Button>
        </Form.Item>
      </Form>
    )
  }

  private updateUserName = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.setState({ username: target.value })
  }

  private updatePassword = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.setState({ password: target.value })
  }

  private submit = async (): Promise<void> => {
    this.setState({ apiError: '', busy: true })
    await this.login()
  }

  private saferUserInput = (value: string): string => {
    if (
      value.toLowerCase().indexOf('and') !== -1 &&
      value.indexOf('1=') !== -1 &&
      value.indexOf('--') !== -1
    ) {
      return value.replace(/1=/g, '').replace(/--/g, '')
    }

    return value
  }

  private login = async (): Promise<void> => {
    const { password, username } = this.state
    const url = `${BASE_URL}auth/login`
    const fetcher = new Fetcher()
    try {
      const result: LoginResponseDto = await fetcher.fetch({
        body: {
          username: this.saferUserInput(username),
          password: this.saferUserInput(password)
        },
        method: 'POST',
        url
      })
      if (result.jwt && result.accountId) {
        Cookies.set('auth', result.jwt, { expires: 1 })
        window.location.pathname = '/'
      } else {
        this.setState({ apiError: 'Something went wrong. Please try again.' })
      }
    } catch (error) {
      this.setState({ apiError: JSON.stringify(error) })
    }
    this.setState({ busy: false })
  }
}

export default Login
