import { BASE_URL, noContentHttpCode } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiGuardianDto from '../../GuardianCrud/ApiGuardianDto'
import GuardianDto from '../../GuardianCrud/GuardianDto'
import ApiClientDto from '../ApiClientDto'
import ClientDto from '../ClientDto'
import IClientGetter from './IClientGetter'

class ClientGetter implements IClientGetter {
  constructor(private readonly fetcher: IFetcher) { }

  public async getClients(): Promise<ClientDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}client/all`
    })

    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          name: `${r.firstName} ${r.lastName}`,
          guardians: this.formGuardians(r.guardians),
          id: r.uuid,
          serviceCenter: r.serviceCenter
        } as ClientDto
      })
    }

    return []
  }

  public async getClientProfile(uuid: string): Promise<ApiClientDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}client/${uuid}?getGuardians=false`
    })
    if (result.code === noContentHttpCode) {
      return null
    }
    return result
  }

  public async getClientProfileWithGuardians(uuid: string): Promise<ApiClientDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}client/${uuid}?getGuardians=true`
    })
    if (result.code === noContentHttpCode) {
      return null
    }
    return result
  }

  public async getClientsRelatedToGuardian(guardianId: string): Promise<ApiClientDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${BASE_URL}client/fromGuardian/${guardianId}`
    })
    if (Array.isArray(result)) {
      return result.map((r) => {
        return {
          uuid: r.uuid,
          firstName: r.firstName,
          middleName: r.middleName,
          lastName: r.lastName,
          guardians: [],
          initials: r.initials,
          street1: r.street1,
          street2: r.street2,
          city: r.city,
          state: r.state,
          zipCode: r.zipCode,
          serviceCenter: r.serviceCenter,
          therapyStartDate: r.therapyStartDate,
          therapyEndDate: r.therapyEndDate,
          active: r.active
        }
      })
    }
    return []
  }

  public async searchClientProfiles(
    firstName: string,
    middleName: string,
    lastName: string,
    initials: string,
    serviceCenter: string | null,
    therapyStartDate: string,
    therapyEndDate: string,
    active: boolean,
    getGuardians: boolean = false
  ): Promise<ApiClientDto[]> {
    let url = `${BASE_URL}search/clients?`
    const queryParams = {
      firstName,
      middleName,
      lastName,
      initials,
      serviceCenter,
      therapyStartDate,
      therapyEndDate,
      active,
      getGuardians
    }
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value != null) {
        url = url.concat(`${key}=${value}&`)
      }
    })

    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url
    })

    return result
  }

  private formGuardians(guardians: ApiGuardianDto[]): GuardianDto[] {
    return guardians.map((g) => {
      return {
        email: g.email as string,
        id: g.uuid as string,
        name: `${g.firstName} ${g.lastName}`,
        phone: g.phone as string
      }
    })
  }
}

export default ClientGetter
