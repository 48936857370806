const ColumnTypes = [
  {
    title: 'Client Id',
    dataIndex: 'clientId',
    key: 'clientId'
  },
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName'
  },
  {
    title: 'Therapist Id',
    dataIndex: 'therapistId',
    key: 'therapistId'
  },
  {
    title: 'Therapist Name',
    dataIndex: 'therapistName',
    key: 'therapistName'
  },
  {
    title: 'Bcba Id',
    dataIndex: 'bcbaId',
    key: 'bcbaId'
  },
  {
    title: 'Bcba Name',
    dataIndex: 'bcbaName',
    key: 'bcbaName'
  }
]

export default ColumnTypes
