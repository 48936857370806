import { badRequestHttpCode, SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import RequestResponse from '../../Model/Dtos/RequestResponse'
import LeaveRequestDto from '../LeaveRequestGetter/LeaveRequestDto'
import ILeaveRequestStatusUpdater from './ILeaveRequestStatusUpdater'

class LeaveRequestStatusUpdater implements ILeaveRequestStatusUpdater {
  constructor(private readonly fetcher: IFetcher) {}

  public async updateLeaveRequestStatus(
    requestId: number,
    status: 'approve' | 'deny'
  ): Promise<RequestResponse | LeaveRequestDto> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'PUT',
      url: `${SCHEDULER_URL}leaveRequest/${requestId}/process?decision=${status}`
    })

    if (this.hasRequestFailed(result)) {
      return {
        code: badRequestHttpCode,
        message: result.message
      }
    }

    return result
  }

  private hasRequestFailed(result: any): boolean {
    return !!result['message']
  }
}

export default LeaveRequestStatusUpdater
