import { Popconfirm, Tag } from 'antd'
import React from 'react'

import IRtsPresenter from '../Presenter/IRtsPresenter'

const ClientRtsColumns = (clientId: string, presenter: IRtsPresenter) => [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName'
  },
  {
    title: 'Id',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Service Center',
    dataIndex: 'serviceCenter',
    key: 'serviceCenter',
    render: (serviceCenter: string) => (
      <Tag
        color={serviceCenter === 'Aurora' ? 'cyan' : serviceCenter === 'Wheat Ridge' ? 'gold' : ''}
      >
        {serviceCenter}
      </Tag>
    )
  },
  {
    title: 'Delete',
    dataIndex: 'delete',
    render: (_: any, record: any) => {
      return (
        <Popconfirm title='Are you sure about this?' onConfirm={async () => {
          presenter.setIsLoading(true)
          await presenter.deleteRts(clientId, record.uuid)
          presenter.setIsLoading(false)
        }}>
          <a>Delete</a>
        </Popconfirm>
      )
    }
  }
]

export default ClientRtsColumns
