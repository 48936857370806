import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from 'antd'
import type { FormInstance } from 'antd/es/form'
import moment from 'moment-timezone'
import React, { Component, Fragment, ReactElement } from 'react'

import AmericanStates from '../../../../Common/StateOptions'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import IAddPersonFormPresenter from '../Presenter/IAddPersonFormPresenter'

type Props = {
  presenter: IAddPersonFormPresenter
}

class ClientForm extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  async componentDidMount(): Promise<void> {
    this.props.presenter.setView(this)
    await this.props.presenter.fetchGuardianOptions()
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props
    const guardianOptions = this.props.presenter.getGuardianOptions()

    return (
      <Modal
        centered
        title='Create New Client Profile'
        visible={presenter.getOpenModal()}
        okText='Create Client Profile'
        onOk={this.submitForm}
        confirmLoading={presenter.getIsLoading()}
        onCancel={this.cancelForm}
        width={800}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Form
          initialValues={presenter.getFormData()}
          layout='vertical'
          name='clientdetailsForm'
          scrollToFirstError
          ref={this.formRef}
        >
          <Row gutter={8}>
            <Col span={7}>
              <Form.Item
                label='First Name'
                name='clientFirstName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a first name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setFirstName(e.target.value)}
                  value={presenter.getFormData().firstName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label='Middle Name' name='clientMiddleName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setMiddleName(e.target.value)}
                  value={presenter.getFormData().middleName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label='Last Name'
                name='clientLastName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a last name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setLastName(e.target.value)}
                  value={presenter.getFormData().lastName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label='Initials'
                name='clientInitials'
                rules={[
                  {
                    required: true,
                    message: 'Please enter client initials'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setInitials(e.target.value)}
                  value={presenter.getFormData().initials || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item label='Street 1' name='clientStreet1'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setStreet1(e.target.value)}
                  value={presenter.getFormData().street1 || ''}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label='Street 2' name='clientStreet2'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setStreet2(e.target.value)}
                  value={presenter.getFormData().street2 || ''}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='City' name='clientCity'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setCity(e.target.value)}
                  value={presenter.getFormData().city || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item label='State' name='clientState'>
                <Select
                  disabled={presenter.getIsLoading()}
                  onChange={(state) => presenter.setState(state)}
                  value={presenter.getFormData().state}
                >
                  {AmericanStates.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Zip Code' name='clientZipCode'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setZipCode(e.target.value)}
                  value={presenter.getFormData().zipCode || ''}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Service Center'
                name='clientServiceCenter'
                rules={[
                  {
                    required: true,
                    message: 'Please select a service center'
                  }
                ]}
              >
                <Select
                  disabled={presenter.getIsLoading()}
                  onChange={(serviceCenter) => presenter.setServiceCenter(serviceCenter)}
                  value={presenter.getFormData().serviceCenter}
                >
                  <Option value='Aurora'>Aurora</Option>
                  <Option value='Wheat Ridge'>Wheat Ridge</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Therapy Start Date' name='clientTherapyStartDate'>
                <DatePicker
                  allowClear
                  onChange={this.updateTherapyStartDate}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='center' style={{ marginTop: 0 }}>
            Add Guardians
          </Divider>
          <Form.List name='clientGuardians'>
            {(fields, { add, remove }) => (
              <Fragment>
                {fields.map(({ key, name, ...restField }) => {
                  const data = presenter.getFormData().guardians[name]
                  return (
                    <Row gutter={8} key={key}>
                      <Col span={4}>
                        <Form.Item {...restField} label='Guardian' name={[name, 'guardian']}>
                          <Select
                            allowClear
                            filterOption={(input, option) => {
                              const isGroup = Array.isArray(option!.options)
                              if (isGroup) {
                                return false
                              }
                              return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }}
                            onChange={(guardianJson) =>
                              this.selectExistingGuardian(guardianJson, name)
                            }
                            showSearch
                            value={JSON.stringify(data.guardian)}
                          >
                            {guardianOptions.map((guardian) => (
                              <Option key={guardian.uuid} value={JSON.stringify(guardian)}>
                                {`${guardian.firstName || ''} ${guardian.middleName || ''} ${guardian.lastName || ''
                                  }`.trim()}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...restField}
                          label='First Name'
                          name={[name, 'guardianFirstName']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a first name'
                            }
                          ]}
                        >
                          <Input
                            onChange={(e) => (data.guardian.firstName = e.target.value || null)}
                            value={data.guardian.firstName || ''}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...restField}
                          label='Middle Name'
                          name={[name, 'guardianMiddleName']}
                        >
                          <Input
                            onChange={(e) => (data.guardian.middleName = e.target.value || null)}
                            value={data.guardian.middleName || ''}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...restField}
                          label='Last Name'
                          name={[name, 'guardianLastName']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a last name'
                            }
                          ]}
                        >
                          <Input
                            onChange={(e) => (data.guardian.lastName = e.target.value || null)}
                            value={data.guardian.lastName || ''}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          label='Phone'
                          name={[name, 'guardianPhone']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a phone number'
                            }
                          ]}
                        >
                          <Input
                            onChange={(e) => (data.guardian.phone = e.target.value || null)}
                            value={data.guardian.phone || ''}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item {...restField} label='Email' name={[name, 'guardianEmail']}>
                          <Input
                            onChange={(e) => (data.guardian.email = e.target.value || null)}
                            value={data.guardian.email || ''}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={1}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            const { guardians } = presenter.getFormData()
                            guardians.splice(name, 1)
                            remove(name)
                          }}
                        />
                      </Col>
                    </Row>
                  )
                })}
                <Form.Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const { guardians } = presenter.getFormData()
                      guardians.push({
                        guardian: {
                          uuid: null,
                          firstName: null,
                          middleName: null,
                          lastName: null,
                          phone: null,
                          email: null
                        },
                        relationship: 'PARENT'
                      })
                      add()
                    }}
                    type='dashed'
                  >
                    Add Guardian
                  </Button>
                </Form.Item>
              </Fragment>
            )}
          </Form.List>
        </Form>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private selectExistingGuardian = (guardianJson: string, fieldName: number) => {
    const data = this.props.presenter.getFormData().guardians[fieldName]
    if (guardianJson) {
      const guardian = JSON.parse(guardianJson)
      data.guardian.uuid = guardian.uuid
      data.guardian.firstName = guardian.firstName
      data.guardian.middleName = guardian.middleName
      data.guardian.lastName = guardian.lastName
      data.guardian.phone = guardian.phone
      data.guardian.email = guardian.email
      this.formRef.current!.setFields([
        {
          name: ['clientGuardians', fieldName, 'guardianFirstName'],
          value: guardian.firstName
        },
        {
          name: ['clientGuardians', fieldName, 'guardianMiddleName'],
          value: guardian.middleName
        },
        {
          name: ['clientGuardians', fieldName, 'guardianLastName'],
          value: guardian.lastName
        },
        {
          name: ['clientGuardians', fieldName, 'guardianPhone'],
          value: guardian.phone
        },
        {
          name: ['clientGuardians', fieldName, 'guardianEmail'],
          value: guardian.email
        }
      ])
    } else {
      data.guardian.uuid = null
      data.guardian.firstName = null
      data.guardian.middleName = null
      data.guardian.lastName = null
      data.guardian.phone = null
      data.guardian.email = null
      this.formRef.current!.resetFields([
        ['clientGuardians', fieldName, 'guardianFirstName'],
        ['clientGuardians', fieldName, 'guardianMiddleName'],
        ['clientGuardians', fieldName, 'guardianLastName'],
        ['clientGuardians', fieldName, 'guardianPhone'],
        ['clientGuardians', fieldName, 'guardianEmail']
      ])
    }
  }

  private updateTherapyStartDate = (date: any): void => {
    if (date) {
      const startDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      this.props.presenter.setTherapyStartDate(startDate.toISOString())
    } else {
      this.props.presenter.setTherapyStartDate(null)
    }
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitClientForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.props.presenter.resetFormData()
    this.formRef.current!.resetFields()
    this.props.presenter.setOpenModal(false)
  }
}

export default ClientForm
