import { SCHEDULER_URL } from "../../constant"
import IFetcher from "../../Drivers/IFetcher"
import IRtsCreator from "./IRtsCreator"

class RtsCreator implements IRtsCreator {
  constructor(private readonly fetcher: IFetcher) { }

  public async addRtsRecord(clientId: string, therapistId: string): Promise<void> {
    return await this.fetcher.fetch({
      body: {},
      method: 'POST',
      url: `${SCHEDULER_URL}rts?clientId=${clientId}&therapistId=${therapistId}`
    })
  }

  public async deleteRtsRecord(clientId: string, therapistId: string): Promise<void> {
    return await this.fetcher.fetch({
      body: {},
      method: 'DELETE',
      url: `${SCHEDULER_URL}rts?clientId=${clientId}&therapistId=${therapistId}`
    })
  }
}

export default RtsCreator