import { okHttpCode, SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import RequestResponse from '../../Model/Dtos/RequestResponse'
import ILeaveRequestSubmitter from './ILeaveRequestSubmitter'
import LeaveRequestParam from './LeaveRequestParam'

class LeaveRequestSubmitter implements ILeaveRequestSubmitter {
  constructor(private readonly fetcher: IFetcher) { }

  public async submitLeaveRequest(params: LeaveRequestParam, sendSms: boolean): Promise<RequestResponse> {
    const result = await this.fetcher.fetch({
      body: params,
      method: 'POST',
      url: `${SCHEDULER_URL}leaveRequest/new?sendSms=${sendSms || false}`
    })

    if (result['message']) {
      return {
        code: null,
        message: result.message
      }
    } else {
      return {
        code: okHttpCode,
        message: 'Successfully submitted your leave request! You may close the tab now.'
      }
    }
  }
}

export default LeaveRequestSubmitter
