import moment from 'moment-timezone'

import IPartialPersonInfoGetter from '../../../EmployeeCrud/PartialEmployeeGetter/IPartialPersonInfoGetter'
import ILeaveRequestGetter from '../../../LeaveRequest/LeaveRequestGetter/ILeaveRequestGetter'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import OptionObject from '../../../Model/Interfaces/OptionObject'
import ValidEmployeeLeavePeriodRecordsType from '../../../Model/Records/LeavePeriodRecordsType'
import EmployeeLeaveReasonRecordsType from '../../../Model/Records/LeaveReasonRecordsType'
import RequesterRoleType from '../../../Model/Types/RequesterRoleType'
import ValidLeavePeriodType from '../../../Model/Types/ValidLeavePeriodType'
import ValidEmployeeLeaveRequestType from '../../../Model/Types/ValidLeaveRequestReasonType'
import ValidRequestStatusType, {
  approvedStatus,
  deniedStatus,
  pendingStatus
} from '../../../Model/Types/ValidRequestStatusType'
import ILeavePeriodTypeFormatter from '../LeavePeriodTypeFormatter/ILeavePeriodTypeFormatter'
import ILeaveReasonFormatter from '../LeaveReasonFormatter/ILeaveReasonFormatter'
import ILeaverDashboardPresenter from './ILeaverDashboardPresenter'
import LeaveRequestWithStatus from './Interfaces/LeaveRequestWithStatus'

class LeaverDashboardPresenter implements ILeaverDashboardPresenter {
  private filterStartDate: string | null
  private filterEndDate: string | null
  private leaveRequestsWithStatus: LeaveRequestWithStatus[]
  private allUsers: OptionObject[]
  private view: ISubscribingView | null
  private loading: boolean
  constructor(
    private readonly leaveRequestGetter: ILeaveRequestGetter,
    private readonly personInfoGetter: IPartialPersonInfoGetter,
    private readonly leavePeriodTypeFormatter: ILeavePeriodTypeFormatter,
    private readonly leaveReasonTypeFormatter: ILeaveReasonFormatter,
    private readonly requesterRole: RequesterRoleType,
    private readonly actionButtonsText: { approve: string; deny: string }
  ) {
    const day = moment.tz('America/Denver').day()
    this.filterStartDate = moment
      .tz('America/Denver')
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(day, 'days')
      .toISOString()
    this.filterEndDate = moment
      .tz('America/Denver')
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      .add(6 - day, 'days')
      .toISOString()
    this.loading = true
    this.leaveRequestsWithStatus = []
    this.allUsers = []
    this.view = null
    this.initialize()
  }

  private async initialize(): Promise<void> {
    this.allUsers = await this.personInfoGetter.getPersonInfo()
    await this.getLeaveRequestsByRequesterRole()
    this.loading = false
    this.updateView()
  }

  public async getLeaveRequestsByRequesterRole(): Promise<void> {
    this.loading = true
    this.updateView()
    const leaveRequests = await this.leaveRequestGetter.searchLeaveRequests(
      null,
      this.requesterRole,
      null,
      null,
      this.filterStartDate,
      this.filterEndDate,
      null,
      null,
      null
    )
    this.leaveRequestsWithStatus = await Promise.all(
      leaveRequests.map(async (leaveRequest) => {
        const rescheduleStatus = await this.leaveRequestGetter.getLeaveRequestRescheduleStatus(
          leaveRequest.id
        )
        return {
          ...leaveRequest,
          needsReschedule: rescheduleStatus ? rescheduleStatus.needsReschedule : false
        }
      })
    )
    this.loading = false
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getAllRequests(): LeaveRequestWithStatus[] {
    return this.leaveRequestsWithStatus
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public getFilterStartDate(): string | null {
    return this.filterStartDate
  }

  public getFilterEndDate(): string | null {
    return this.filterEndDate
  }

  public getPersonNameById(id: string): string {
    const result = this.allUsers.find((c) => c.id === id)

    if (result) {
      return result.name
    }

    return 'Unknown submitter'
  }

  public getFormattedReasonTypeByRecord(
    record: EmployeeLeaveReasonRecordsType
  ): ValidEmployeeLeaveRequestType {
    return this.leaveReasonTypeFormatter.getFormattedLeaveReasonType(record)
  }

  public getFormattedLeavePeriodTypeByRecord(
    record: ValidEmployeeLeavePeriodRecordsType
  ): ValidLeavePeriodType {
    return this.leavePeriodTypeFormatter.getFormattedLeavePeriodType(record)
  }

  public getClassNameByApprovalStatus(status: ValidRequestStatusType): string {
    if (status === approvedStatus) {
      return 'success'
    }

    if (status === deniedStatus) {
      return 'error'
    }

    return 'warning'
  }

  public getApproveButtonText(): string {
    return this.actionButtonsText.approve
  }

  public getDenyButtonText(): string {
    return this.actionButtonsText.deny
  }

  public getDisplayRequestStatus(status: ValidRequestStatusType): string {
    if (this.canDenyRequest()) {
      return status[0].toUpperCase() + status.toLowerCase().substring(1)
    }

    return status === pendingStatus ? 'Processing' : 'Viewed'
  }

  private canDenyRequest(): boolean {
    return !!this.actionButtonsText.deny
  }

  public setFilterStartDate(filterStartDate: string | null): void {
    this.filterStartDate = filterStartDate
  }

  public setFilterEndDate(filterEndDate: string | null): void {
    this.filterEndDate = filterEndDate
  }
}

export default LeaverDashboardPresenter
