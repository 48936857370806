import { okHttpCode, SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import RequestResponse from '../../Model/Dtos/RequestResponse'
import ReschedulePlanSessionDto from '../Dtos/ReschedulePlanSessionDto'
import IRescheduleCreator from './IRescheduleCreator'

export const successfullyRescheduledMessage = 'Rescheduled appointments'
class RescheduleCreator implements IRescheduleCreator {
  constructor(private readonly fetcher: IFetcher) { }

  public async submitReschedulePlan(clientSessions: ReschedulePlanSessionDto[], therapistSessions: ReschedulePlanSessionDto[]): Promise<RequestResponse> {
    const result = await this.fetcher.fetch({
      body: {
        clientSessions,
        therapistSessions
      },
      method: 'POST',
      url: `${SCHEDULER_URL}rescheduler/reschedule`
    })

    if (result.message === successfullyRescheduledMessage) {
      return {
        code: okHttpCode,
        message: 'Successfully rescheduled appointments'
      }
    }

    return {
      code: null,
      message: result.message
    }
  }
}

export default RescheduleCreator
