import './style.css'

import { Badge, Button, Collapse, DatePicker, Descriptions, Form, FormInstance, Modal, Select, Tag } from "antd"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment-timezone"
import React, { Component, Fragment, ReactElement } from "react"

import AppointmentDto from "../../../AppointmentCrud/AppointmentDto"
import ApiBcbaDto from "../../../BcbaCrud/ApiBcbaDto"
import ApiClientDto from "../../../ClientCrud/ApiClientDto"
import ApiEmployeeDto from "../../../EmployeeCrud/ApiEmployeeDto"
import ISubscribingView from "../../../Model/Interfaces/ISubscribingViewPresenter"
import IAppointmentDetailsPresenter from "./Presenter/IAppointmentDetailsPresenter"

type Props = {
  presenter: IAppointmentDetailsPresenter
}

class AppointmentDetails extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()
  cancelFormRef = React.createRef<FormInstance>()

  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const { Option } = Select
    const { Panel } = Collapse

    return (
      <Fragment>
        <Collapse accordion onChange={this.selectPanel}>
          {presenter.getParentPresenter().getAppointmentList().map((appointment: AppointmentDto) => {
            const client = presenter.getParentPresenter().getClientOptions().find(c => c.uuid === appointment.clientId) as ApiClientDto
            const therapist = presenter.getParentPresenter().getTherapistOptions().find(t => t.uuid === appointment.therapistId) as ApiEmployeeDto
            const bcba = presenter.getParentPresenter().getBcbaOptions().find(b => b.uuid === appointment.bcbaId) as ApiBcbaDto
            const scheduledStart = moment.tz(appointment.scheduledStart, 'America/Denver')
            const scheduledEnd = moment.tz(appointment.scheduledEnd, 'America/Denver')

            const clientFullName = client ? `${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}` : null
            const therapistFullName = therapist ? `${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}` : null

            const header = (
              <Fragment>
                Client:<Tag color={clientFullName ? 'orange' : undefined} style={{ marginLeft: '5px' }}>{clientFullName || 'VACANT'}</Tag>
                Therapist:<Tag color={therapistFullName ? 'orange' : undefined} style={{ marginLeft: '5px' }}>{therapistFullName || 'VACANT'}</Tag>
              </Fragment>
            )

            const hasConflictingSessions = appointment.status === 'CANCELLED' || appointment.status === 'COMPLETE' ? false : presenter.getParentPresenter().getAppointmentList().some(otherSession => {
              if (otherSession.id === appointment.id) {
                return false
              }
              if (otherSession.status === 'CANCELLED') {
                return false
              }
              if (otherSession.clientId !== appointment.clientId && otherSession.therapistId !== appointment.therapistId) {
                return false
              }
              return this.hasTimeOverlap(
                [appointment.scheduledStart as string, appointment.scheduledEnd as string],
                [otherSession.scheduledStart as string, otherSession.scheduledEnd as string]
              )
            })

            return (
              <Panel
                extra={
                  <Fragment>
                    {hasConflictingSessions ? (
                      <Tag color='volcano'>Conflict</Tag>
                    ) : null}
                    <Tag>{scheduledStart.format('hh:mm A')}</Tag>
                    <Tag>{scheduledEnd.format('hh:mm A')}</Tag>
                    <Tag color={this.getStatusColor(appointment.status)}>{this.getStatusString(appointment.status)}</Tag>
                  </Fragment>
                }
                header={header}
                key={appointment.id as number}
              >
                {presenter.getErrorMessage() ? (
                  <div className='error'>* {presenter.getErrorMessage()} *</div>
                ) : null}
                {
                  presenter.getIsEditable() ? (
                    <Form
                      id='edit-form'
                      initialValues={{
                        client: client ? client.uuid : null,
                        therapist: therapist ? therapist.uuid : null,
                        bcba: bcba.uuid,
                        start: scheduledStart,
                        end: scheduledEnd,
                        comments: appointment.comments
                      }}
                      ref={this.formRef}
                    >
                      <Descriptions bordered column={3} title='Appointment Details'>
                        <Descriptions.Item label='Client' span={1}>
                          <Form.Item name='client'>
                            <Select
                              allowClear
                              disabled={presenter.getIsLoading()}
                              filterOption={(input, option) => {
                                const isGroup = Array.isArray(option!.options)
                                if (isGroup) {
                                  return false
                                }
                                return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                              }}
                              onChange={uuid => presenter.setEditableAppointment({
                                ...presenter.getEditableAppointment() as AppointmentDto,
                                clientId: uuid || null
                              })}
                              showSearch
                              value={presenter.getEditableAppointment()!.clientId}
                            >
                              {presenter.getParentPresenter().getClientOptions().map(c => (
                                <Option key={c.uuid} value={c.uuid}>{`${c.firstName || ''}${c.middleName ? ' ' + c.middleName : ''}${c.lastName ? ' ' + c.lastName : ''}`}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='Assigned Therapist' span={1}>
                          <Form.Item name='therapist'>
                            <Select
                              allowClear
                              disabled={presenter.getIsLoading()}
                              filterOption={(input, option) => {
                                const isGroup = Array.isArray(option!.options)
                                if (isGroup) {
                                  return false
                                }
                                return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                              }}
                              onChange={uuid => presenter.setEditableAppointment({
                                ...presenter.getEditableAppointment() as AppointmentDto,
                                therapistId: uuid || null
                              })}
                              showSearch
                              value={presenter.getEditableAppointment()!.therapistId}
                            >
                              {presenter.getParentPresenter().getTherapistOptions().map(t => (
                                <Option key={t.uuid} value={t.uuid}>{`${t.firstName || ''}${t.middleName ? ' ' + t.middleName : ''}${t.lastName ? ' ' + t.lastName : ''}`}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='Assigned Bcba' span={1}>
                          <Form.Item name='bcba'>
                            <Select
                              allowClear
                              disabled={presenter.getIsLoading()}
                              filterOption={(input, option) => {
                                const isGroup = Array.isArray(option!.options)
                                if (isGroup) {
                                  return false
                                }
                                return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                              }}
                              onChange={uuid => presenter.setEditableAppointment({
                                ...presenter.getEditableAppointment() as AppointmentDto,
                                bcbaId: uuid || null
                              })}
                              showSearch
                              value={presenter.getEditableAppointment()!.bcbaId}
                            >
                              {presenter.getParentPresenter().getBcbaOptions().map(b => (
                                <Option key={b.uuid} value={b.uuid}>{`${b.firstName || ''}${b.middleName ? ' ' + b.middleName : ''}${b.lastName ? ' ' + b.lastName : ''}`}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='Scheduled Start Time' span={1}>
                          <Form.Item
                            name='start'
                            rules={[
                              {
                                required: true,
                                message: 'Please select a start time'
                              }
                            ]}
                          >
                            <DatePicker
                              disabled={presenter.getIsLoading()}
                              format='MM/DD/YYYY hh:mm a'
                              onChange={this.updateStartDate}
                              showTime={{ format: 'HH:mm' }}
                              value={presenter.getEditableAppointment()!.scheduledStart ? moment.tz(presenter.getEditableAppointment()!.scheduledStart, 'America/Denver') : null}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='Scheduled End Time' span={2}>
                          <Form.Item
                            name='end'
                            rules={[
                              {
                                required: true,
                                message: 'Please select a end time'
                              }
                            ]}
                          >
                            <DatePicker
                              disabled={presenter.getIsLoading()}
                              format='MM/DD/YYYY hh:mm a'
                              onChange={this.updateEndDate}
                              showTime={{ format: 'HH:mm' }}
                              value={presenter.getEditableAppointment()!.scheduledEnd ? moment.tz(presenter.getEditableAppointment()!.scheduledEnd, 'America/Denver') : null}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='Status' span={3}>
                          <Badge color={this.getStatusColor(appointment.status)} text={this.getStatusString(appointment.status)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Comments'>
                          <Form.Item name='comments'>
                            <TextArea
                              disabled={presenter.getIsLoading()}
                              onChange={e => presenter.setEditableAppointment({
                                ...presenter.getEditableAppointment() as AppointmentDto,
                                comments: e.target.value || null
                              })}
                              value={presenter.getEditableAppointment()!.comments || undefined}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                      <div className='button-row'>
                        <Button
                          disabled={presenter.getIsLoading()}
                          loading={presenter.getIsLoading()}
                          onClick={() => {
                            presenter.setEditableAppointment(appointment)
                            presenter.setIsEditable(false)
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={presenter.getIsLoading()}
                          loading={presenter.getIsLoading()}
                          onClick={this.confirmEdit}
                          style={{ marginLeft: '10px' }}
                          type='primary'
                        >
                          Confirm Edit
                        </Button>
                      </div>
                    </Form>
                  ) : (
                    <Fragment>
                      <Descriptions bordered column={3} title='Appointment Details'>
                        <Descriptions.Item label='Client' span={1}>
                          {client ? `${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}` : null}
                        </Descriptions.Item>
                        <Descriptions.Item label='Assigned Therapist' span={1}>
                          {therapist ? `${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}` : null}
                        </Descriptions.Item>
                        <Descriptions.Item label='Assigned Bcba' span={1}>
                          {`${bcba.firstName || ''}${bcba.middleName ? ' ' + bcba.middleName : ''}${bcba.lastName ? ' ' + bcba.lastName : ''}`}
                        </Descriptions.Item>
                        <Descriptions.Item label='Scheduled Start Time' span={1}>{scheduledStart.format('MM/DD/YYYY hh:mm A')}</Descriptions.Item>
                        <Descriptions.Item label='Scheduled End Time' span={2}>{scheduledEnd.format('MM/DD/YYYY hh:mm A')}</Descriptions.Item>
                        <Descriptions.Item label='Status' span={3}>
                          <Badge color={this.getStatusColor(appointment.status)} text={this.getStatusString(appointment.status)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Comments'>{appointment.comments}</Descriptions.Item>
                      </Descriptions>
                      {appointment.status === 'COMPLETE' ? (
                        null
                      ) : appointment.status === 'CANCELLED' ? (
                        <div className='button-row'>
                          <Button
                            danger
                            disabled={presenter.getIsLoading()}
                            loading={presenter.getIsLoading()}
                            onClick={() => presenter.setOpenUndoModal(true)}
                            style={{ marginLeft: '10px', background: '#3cadad', borderColor: '#3cadad' }}
                            type='primary'
                          >
                            Undo Cancellation
                          </Button>
                        </div>
                      ) : (
                        <div className='button-row'>
                          <Button
                            danger
                            disabled={presenter.getIsLoading()}
                            loading={presenter.getIsLoading()}
                            onClick={() => presenter.setOpenCancelModal(true)}
                            style={{ marginLeft: '10px' }}
                            type='primary'
                          >
                            Cancel Appointment
                          </Button>
                          <Button
                            disabled={presenter.getIsLoading()}
                            loading={presenter.getIsLoading()}
                            onClick={() => presenter.setIsEditable(true)}
                            style={{ marginLeft: '10px', background: '#19b525', borderColor: '#19b525' }}
                            type='primary'
                          >
                            Edit Appointment
                          </Button>
                        </div>
                      )}
                    </Fragment>
                  )
                }
              </Panel>
            )
          })}
        </Collapse>
        <Modal
          centered
          confirmLoading={presenter.getIsLoading()}
          footer={[
            <Button
              disabled={presenter.getIsLoading()}
              key='cancel'
              loading={presenter.getIsLoading()}
              onClick={() => {
                presenter.setModalComments(null)
                presenter.setOpenCancelModal(false)
              }}
            >
              Cancel
            </Button>,
            <Button
              danger
              disabled={presenter.getIsLoading()}
              key='confirmCancellation'
              loading={presenter.getIsLoading()}
              onClick={this.confirmCancellation}
              type='primary'
            >
              Yes, go ahead
            </Button>
          ]}
          onCancel={() => {
            presenter.setModalComments(null)
            presenter.setOpenCancelModal(false)
          }}
          title='Confirm Cancellation'
          visible={presenter.getOpenCancelModal()}
        >
          {presenter.getErrorMessage() ? (
            <div className='error'>* {presenter.getErrorMessage()} *</div>
          ) : null}
          <Form id='cancel-form' initialValues={{ cancelComments: undefined }} ref={this.cancelFormRef}>
            <Form.Item name='cancelComments'>
              <TextArea
                disabled={presenter.getIsLoading()}
                onChange={(e) => presenter.setModalComments(e.target.value)}
                value={presenter.getModalComments() || undefined}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          centered
          confirmLoading={presenter.getIsLoading()}
          footer={[
            <Button
              disabled={presenter.getIsLoading()}
              key='cancel'
              loading={presenter.getIsLoading()}
              onClick={() => {
                presenter.setModalComments(null)
                presenter.setOpenUndoModal(false)
              }}
            >
              Cancel
            </Button>,
            <Button
              disabled={presenter.getIsLoading()}
              key='confirmUndoCancellation'
              loading={presenter.getIsLoading()}
              onClick={this.confirmUndoCancellation}
              style={{ marginLeft: '10px', background: '#3cadad', borderColor: '#3cadad' }}
              type='primary'
            >
              Yes, go ahead
            </Button>
          ]}
          onCancel={() => {
            presenter.setModalComments(null)
            presenter.setOpenUndoModal(false)
          }}
          title='Confirm Undo Cancellation'
          visible={presenter.getOpenUndoModal()}
        >
          {presenter.getErrorMessage() ? (
            <div className='error'>* {presenter.getErrorMessage()} *</div>
          ) : null}
          <Form id='cancel-form' initialValues={{ cancelComments: undefined }} ref={this.cancelFormRef}>
            <Form.Item name='cancelComments'>
              <TextArea
                disabled={presenter.getIsLoading()}
                onChange={(e) => presenter.setModalComments(e.target.value)}
                value={presenter.getModalComments() || undefined}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Fragment>
    )
  }

  update(): void {
    this.setState({})
  }

  private confirmEdit = async () => {
    await this.formRef.current!.validateFields()
    const selectedDate = this.props.presenter.getParentPresenter().getSelectedDate()
    const startDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').startOf('month') : moment.tz('America/Denver').startOf('month')
    const endDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').endOf('month') : moment.tz('America/Denver').endOf('month')
    await this.props.presenter.modifyAppointment()
    if (!this.props.presenter.getErrorMessage()) {
      this.props.presenter.setIsLoading(true)
      await this.props.presenter.getParentPresenter().fetchAppointments(startDate.toISOString(), endDate.toISOString())
      const newEditableAppointment = this.props.presenter.getParentPresenter().getAppointmentList().find(a => a.id as number === this.props.presenter.getEditableAppointment()!.id)
      this.props.presenter.setEditableAppointment(newEditableAppointment as AppointmentDto)
      this.props.presenter.setIsEditable(false)
      this.props.presenter.setIsLoading(false)
    }
  }

  private confirmCancellation = async () => {
    await this.props.presenter.cancelAppointment()
    this.cancelFormRef.current!.resetFields()
    if (!this.props.presenter.getErrorMessage()) {
      this.props.presenter.setIsLoading(true)
      const selectedDate = this.props.presenter.getParentPresenter().getSelectedDate()
      const startDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').startOf('month') : moment.tz('America/Denver').startOf('month')
      const endDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').endOf('month') : moment.tz('America/Denver').endOf('month')
      await this.props.presenter.getParentPresenter().fetchAppointments(startDate.toISOString(), endDate.toISOString())
      const newEditableAppointment = this.props.presenter.getParentPresenter().getAppointmentList().find(a => a.id as number === this.props.presenter.getEditableAppointment()!.id)
      this.props.presenter.setEditableAppointment(newEditableAppointment as AppointmentDto)
      this.props.presenter.setOpenCancelModal(false)
      this.props.presenter.setIsLoading(false)
    }
  }

  private confirmUndoCancellation = async () => {
    await this.props.presenter.undoAppointmentCancellation()
    this.cancelFormRef.current!.resetFields()
    if (!this.props.presenter.getErrorMessage()) {
      this.props.presenter.setIsLoading(true)
      const selectedDate = this.props.presenter.getParentPresenter().getSelectedDate()
      const startDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').startOf('month') : moment.tz('America/Denver').startOf('month')
      const endDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').endOf('month') : moment.tz('America/Denver').endOf('month')
      await this.props.presenter.getParentPresenter().fetchAppointments(startDate.toISOString(), endDate.toISOString())
      const newEditableAppointment = this.props.presenter.getParentPresenter().getAppointmentList().find(a => a.id as number === this.props.presenter.getEditableAppointment()!.id)
      this.props.presenter.setEditableAppointment(newEditableAppointment as AppointmentDto)
      this.props.presenter.setOpenUndoModal(false)
      this.props.presenter.setIsLoading(false)
    }
  }

  private selectPanel = (appointmentId: string | string[]) => {
    if (!appointmentId) {
      this.props.presenter.setEditableAppointment(null)
    } else {
      const appointment = this.props.presenter.getParentPresenter().getAppointmentList().find(a => (a.id as number).toString() === (appointmentId as any))
      if (appointment) {
        this.props.presenter.setEditableAppointment(appointment)
      }
    }
    this.props.presenter.setIsEditable(false)
  }

  private updateStartDate = (date: any): void => {
    if (date) {
      const startDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(date.hour())
        .minute(date.minute())
        .second(0)
        .millisecond(0)
      this.props.presenter.setEditableAppointment({
        ...this.props.presenter.getEditableAppointment() as AppointmentDto,
        scheduledStart: startDate.toISOString()
      })
    } else {
      this.props.presenter.setEditableAppointment({
        ...this.props.presenter.getEditableAppointment() as AppointmentDto,
        scheduledStart: null
      })
    }
  }

  private updateEndDate = (date: any): void => {
    if (date) {
      const endDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(date.hour())
        .minute(date.minute())
        .second(0)
        .millisecond(0)
      this.props.presenter.setEditableAppointment({
        ...this.props.presenter.getEditableAppointment() as AppointmentDto,
        scheduledEnd: endDate.toISOString()
      })
    } else {
      this.props.presenter.setEditableAppointment({
        ...this.props.presenter.getEditableAppointment() as AppointmentDto,
        scheduledEnd: null
      })
    }
  }

  private getStatusColor = (status: string | null) => {
    switch (status) {
      case 'SCHEDULED':
        return 'green'
      case 'MODIFIED':
        return 'gold'
      case 'COMPLETE':
        return 'blue'
      case 'CANCELLED':
        return 'red'
      case 'PENDING_RESCHEDULE':
        return 'purple'
      default:
        return undefined
    }
  }

  private getStatusString = (status: string | null) => {
    if (status === 'PENDING_RESCHEDULE') {
      return 'PENDING RESCHEDULE'
    } else {
      return status
    }
  }

  private hasTimeOverlap = (rangeA: string[], rangeB: string[]) => {
    const intervals = [[moment.utc(rangeA[0]), moment.utc(rangeA[1])], [moment.utc(rangeB[0]), moment.utc(rangeB[1])]]
    intervals.sort((a, b) => {
      if (a[0].isBefore(b[0])) {
        return -1
      }
      if (a[0].isAfter(b[0])) {
        return 1
      }
      if (a[1].isSameOrBefore(b[1])) {
        return -1
      }
      if (a[1].isAfter(b[1])) {
        return 1
      }
      return 0
    })
    return intervals[0][1].isAfter(intervals[1][0])
  }
}

export default AppointmentDetails