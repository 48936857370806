import { SCHEDULER_URL } from "../../constant"
import IFetcher from "../../Drivers/IFetcher"
import AppointmentDto from "../AppointmentDto"
import IAppointmentGetter from "./IAppointmentGetter"

class AppointmentGetter implements IAppointmentGetter {
  constructor(private readonly fetcher: IFetcher) { }

  public async searchAppointments(clientId: string | null, therapistId: string | null, bcbaId: string | null, scheduledStart: string | null, scheduledEnd: string | null, status: string | null): Promise<AppointmentDto[]> {
    const queryParams = {
      clientId,
      therapistId,
      bcbaId,
      scheduledStart,
      scheduledEnd,
      status
    }
    let url = `${SCHEDULER_URL}scheduler/search?`
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== null) {
        url = url.concat(`${key}=${value}&`)
      }
    })

    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url
    })

    if (Array.isArray(result)) {
      return result
    }

    return []
  }
}

export default AppointmentGetter