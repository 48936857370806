import { noContentHttpCode, SCHEDULER_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ValidEmployeeLeavePeriodRecordsType from '../../Model/Records/LeavePeriodRecordsType'
import LeaveReasonRecordType from '../../Model/Records/LeaveReasonRecordsType'
import RequesterRoleType from '../../Model/Types/RequesterRoleType'
import ValidRequestStatusType from '../../Model/Types/ValidRequestStatusType'
import ILeaveRequestGetter from './ILeaveRequestGetter'
import LeaveRequestDto from './LeaveRequestDto'
import LeaveRequestRescheduleStatusDto from './LeaveRequestRescheduleStatusDto'

class LeaveRequestGetter implements ILeaveRequestGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async searchLeaveRequests(
    requesterId: string | null,
    requesterRole: RequesterRoleType | null,
    reasonType: LeaveReasonRecordType | null,
    leaveType: ValidEmployeeLeavePeriodRecordsType | null,
    leaveDatetime: string | null,
    returnDatetime: string | null,
    approvalStatus: ValidRequestStatusType | null,
    submitDateMin: string | null,
    submitDateMax: string | null
  ): Promise<LeaveRequestDto[]> {
    let url = `${SCHEDULER_URL}leaveRequest/search?`
    const queryParams = {
      requesterId,
      requesterRole,
      reasonType,
      leaveType,
      leaveDatetime,
      returnDatetime,
      approvalStatus,
      submitDateMin,
      submitDateMax
    }
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value != null) {
        url = url.concat(`${key}=${value}&`)
      }
    })
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url
    })

    if (Array.isArray(result)) {
      return result
    }

    return []
  }

  public async getAllLeaveRequests(): Promise<LeaveRequestDto[]> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}leaveRequest/search`
    })

    if (Array.isArray(result)) {
      return result
    }

    return []
  }

  public async getLeaveRequestRescheduleStatus(
    id: number
  ): Promise<LeaveRequestRescheduleStatusDto | null> {
    const result = await this.fetcher.fetch({
      body: {},
      method: 'GET',
      url: `${SCHEDULER_URL}leaveRequest/${id}/rescheduleStatus`
    })
    if (result.code === noContentHttpCode) {
      return null
    }
    return result
  }
}

export default LeaveRequestGetter
