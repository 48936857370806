import './style.css'

import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import moment from 'moment-timezone'
import { FieldData } from 'rc-field-form/es/interface'
import React, { Component, Fragment, ReactElement } from 'react'

import Footer from '../../Common/Footer/Footer'
import Header from '../../Common/Header/Header'
import { redirectIfNotAuthorized } from '../../constant'
import ISubscribingView from '../../Model/Interfaces/ISubscribingViewPresenter'
import { HourPreference } from '../../Registration/HourPreferencesDto'
import EditAssignmentForm from './EditAssignmentForm/EditAssignmentForm'
import AssignmentTableData from './Interfaces/AssignmentTableData'
import IClientAssignmentPresenter from './Presenter/IClientAssignmentPresenter'
import ColumnTypes from './TableColumnTypes/ColumnTypes'

type Props = {
  presenter: IClientAssignmentPresenter
}

class ClientAssignment extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  async componentDidMount(): Promise<void> {
    this.props.presenter.setView(this)
    await this.props.presenter.fetchTableData()
  }

  async componentDidUpdate(): Promise<void> {
    await redirectIfNotAuthorized()
    this.initializeFields()
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props

    return (
      <Layout>
        <Header id='dashboard-header' />
        <Content
          className='site-layout'
          style={{ backgroundColor: 'white', padding: '0 50px', overflowY: 'scroll' }}
        >
          <Divider orientation='center'>Client Assignments and Time Preferences</Divider>
          <Button
            disabled={presenter.getIsLoading()}
            icon={<PlusOutlined />}
            onClick={this.openModal}
            style={{ marginBottom: '15px' }}
            type='primary'
          >
            Assign New Pair
          </Button>
          <Table
            columns={ColumnTypes}
            dataSource={presenter.getTableData()}
            expandable={{
              expandedRowRender: (assignment) => (
                <Fragment>
                  <h4>Schedule</h4>
                  {Object.entries(assignment.hourPreferences).map(([day, preferences]) => {
                    let dayString = null
                    switch (day) {
                      case '1':
                        dayString = 'MON'
                        break
                      case '2':
                        dayString = 'TUE'
                        break
                      case '3':
                        dayString = 'WED'
                        break
                      case '4':
                        dayString = 'THU'
                        break
                      case '5':
                        dayString = 'FRI'
                        break
                    }
                    return (
                      <div key={`${assignment.clientId}-${day}`}>
                        <Tag color='geekblue' style={{ marginBottom: '5px' }}>
                          {dayString}
                        </Tag>
                        <Tag>{this.formatTimeString(preferences)}</Tag>
                      </div>
                    )
                  })}
                </Fragment>
              )
            }}
            id='client-assignment-table'
            loading={presenter.getIsLoading()}
            onRow={this.selectRowCallback}
            pagination={{ position: ['bottomCenter'] }}
          />
          <Modal
            centered
            title='Assign Client to Therapist'
            visible={presenter.getOpenModal()}
            okText='Assign Pair'
            onOk={this.submitForm}
            confirmLoading={presenter.getIsLoading()}
            onCancel={this.cancelForm}
            width={800}
          >
            {presenter.getErrorMessage() ? (
              <div className='error'>* {presenter.getErrorMessage()} *</div>
            ) : null}
            <Form
              initialValues={presenter.getFormData()}
              layout='vertical'
              name='assignmentForm'
              scrollToFirstError
              ref={this.formRef}
            >
              <Row gutter={8}>
                <Col span={6}>
                  <Form.Item
                    label='Client'
                    name='client'
                    rules={[
                      {
                        required: true,
                        message: 'Please select a client'
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      disabled={presenter.getIsLoading()}
                      filterOption={(input, option) => {
                        const isGroup = Array.isArray(option!.options)
                        if (isGroup) {
                          return false
                        }
                        return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                      }}
                      onChange={(clientId) => presenter.setClientId(clientId)}
                      showSearch
                      value={presenter.getFormData().clientId}
                    >
                      {presenter.getClientOptions().map((client) => (
                        <Option key={client.uuid} value={client.uuid}>
                          {`${client.firstName || ''} ${client.middleName || ''} ${client.lastName || ''
                            }`.trim()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Therapist'
                    name='therapist'
                    rules={[
                      {
                        required: true,
                        message: 'Please select a therapist'
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      disabled={presenter.getIsLoading()}
                      filterOption={(input, option) => {
                        const isGroup = Array.isArray(option!.options)
                        if (isGroup) {
                          return false
                        }
                        return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                      }}
                      onChange={(therapistId) => presenter.setTherapistId(therapistId)}
                      showSearch
                      value={presenter.getFormData().therapistId}
                    >
                      {presenter.getTherapistOptions().map((therapist) => (
                        <Option key={therapist.uuid} value={therapist.uuid}>
                          {`${therapist.firstName || ''} ${therapist.middleName || ''} ${therapist.lastName || ''
                            }`.trim()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Bcba'
                    name='bcba'
                    rules={[
                      {
                        required: true,
                        message: 'Please select a bcba'
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      disabled={presenter.getIsLoading()}
                      filterOption={(input, option) => {
                        const isGroup = Array.isArray(option!.options)
                        if (isGroup) {
                          return false
                        }
                        return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                      }}
                      onChange={(bcbaId) => presenter.setBcbaId(bcbaId)}
                      showSearch
                      value={presenter.getFormData().bcbaId}
                    >
                      {presenter.getBcbaOptions().map((bcba) => (
                        <Option key={bcba.uuid} value={bcba.uuid}>
                          {`${bcba.firstName || ''} ${bcba.middleName || ''} ${bcba.lastName || ''
                            }`.trim()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Therapy Start Date' name='therapyStartDate'>
                    <DatePicker
                      allowClear
                      disabled={presenter.getIsLoading()}
                      onChange={this.updateTherapyStartDate}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                <Row key={day} gutter={8}>
                  <Col span={4}>
                    <Form.Item label='Day'>
                      <Input disabled value={day} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label='Drop Off Hour'
                      name={`${day.toLowerCase()}DropOffHour`}
                      rules={[
                        {
                          required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                          message: 'Please enter a valid hour (0 - 23)'
                        }
                      ]}
                    >
                      <InputNumber
                        disabled={
                          presenter.getIsLoading() ||
                          !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                        }
                        min={0}
                        max={23}
                        onChange={(dropOffHour) =>
                          presenter.setDropOffHour(day.toLowerCase(), dropOffHour)
                        }
                        style={{ width: '100%' }}
                        value={
                          (presenter.getFormData().schedule as any)[day.toLowerCase()]
                            ? (presenter.getFormData().schedule as any)[day.toLowerCase()]
                              .dropOffHour
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label='Drop Off Minute'
                      name={`${day.toLowerCase()}DropOffMinute`}
                      rules={[
                        {
                          required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                          message: 'Please enter a valid minute (0 - 59)'
                        }
                      ]}
                    >
                      <InputNumber
                        disabled={
                          presenter.getIsLoading() ||
                          !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                        }
                        min={0}
                        max={59}
                        onChange={(dropOffMinute) =>
                          presenter.setDropOffMinute(day.toLowerCase(), dropOffMinute)
                        }
                        style={{ width: '100%' }}
                        value={
                          (presenter.getFormData().schedule as any)[day.toLowerCase()]
                            ? (presenter.getFormData().schedule as any)[day.toLowerCase()]
                              .dropOffMinute
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label='Pick Up Hour'
                      name={`${day.toLowerCase()}PickUpHour`}
                      rules={[
                        {
                          required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                          message: 'Please enter a valid hour (0 - 23)'
                        }
                      ]}
                    >
                      <InputNumber
                        disabled={
                          presenter.getIsLoading() ||
                          !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                        }
                        min={0}
                        max={23}
                        onChange={(pickUpHour) =>
                          presenter.setPickUpHour(day.toLowerCase(), pickUpHour)
                        }
                        style={{ width: '100%' }}
                        value={
                          (presenter.getFormData().schedule as any)[day.toLowerCase()]
                            ? (presenter.getFormData().schedule as any)[day.toLowerCase()]
                              .pickUpHour
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label='Pick Up Minute'
                      name={`${day.toLowerCase()}PickUpMinute`}
                      rules={[
                        {
                          required: (presenter.getFormData().schedule as any)[day.toLowerCase()],
                          message: 'Please enter a valid minute (0 - 59)'
                        }
                      ]}
                    >
                      <InputNumber
                        disabled={
                          presenter.getIsLoading() ||
                          !(presenter.getFormData().schedule as any)[day.toLowerCase()]
                        }
                        min={0}
                        max={59}
                        onChange={(pickUpMinute) =>
                          presenter.setPickUpMinute(day.toLowerCase(), pickUpMinute)
                        }
                        style={{ width: '100%' }}
                        value={
                          (presenter.getFormData().schedule as any)[day.toLowerCase()]
                            ? (presenter.getFormData().schedule as any)[day.toLowerCase()]
                              .pickUpMinute
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label='Include?' name={`${day.toLowerCase()}Included`}>
                      <Switch
                        checked={(presenter.getFormData().schedule as any)[day.toLowerCase()]}
                        checkedChildren='Yes'
                        onChange={(dayIncluded) =>
                          this.updateDayIncluded(day.toLowerCase(), dayIncluded)
                        }
                        unCheckedChildren='No'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Form>
          </Modal>
          <EditAssignmentForm presenter={presenter.getEditAssignmentPresenter()} />
        </Content>
        <Footer />
      </Layout>
    )
  }

  update(): void {
    this.setState({})
  }

  private openModal = async () => {
    this.props.presenter.setOpenModal(true)
    await this.props.presenter.fetchFormOptions()
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.props.presenter.resetFormData()
    this.initializeFields()
    this.props.presenter.setOpenModal(false)
  }

  private updateTherapyStartDate = (date: any): void => {
    if (date) {
      const startDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      this.props.presenter.setTherapyStartDate(startDate.toISOString())
    } else {
      this.props.presenter.setTherapyStartDate(null)
    }
  }

  private updateDayIncluded = (day: string, dayIncluded: boolean) => {
    if (dayIncluded) {
      this.props.presenter.setDaySchedule(day, {
        dropOffHour: 8,
        dropOffMinute: 0,
        pickUpHour: 15,
        pickUpMinute: 0
      })
      this.update()
    } else {
      this.props.presenter.setDaySchedule(day, null)
      this.formRef.current!.setFields([
        {
          name: [`${day}DropOffHour`],
          value: null
        },
        {
          name: [`${day}DropOffMinute`],
          value: null
        },
        {
          name: [`${day}PickUpHour`],
          value: null
        },
        {
          name: [`${day}PickUpMinute`],
          value: null
        }
      ])
      this.update()
    }
  }

  private selectRowCallback = (record: AssignmentTableData) => {
    return {
      onClick: async () => {
        const editAssignmentPresenter = this.props.presenter.getEditAssignmentPresenter()
        editAssignmentPresenter.setFormData({
          clientId: record.clientId,
          therapistId: record.therapistId,
          bcbaId: record.bcbaId,
          therapyStartDate: null,
          schedule: {
            monday: JSON.parse(JSON.stringify(record.hourPreferences[1])),
            tuesday: JSON.parse(JSON.stringify(record.hourPreferences[2])),
            wednesday: JSON.parse(JSON.stringify(record.hourPreferences[3])),
            thursday: JSON.parse(JSON.stringify(record.hourPreferences[4])),
            friday: JSON.parse(JSON.stringify(record.hourPreferences[5]))
          }
        })
        editAssignmentPresenter.setOriginalSchedule({
          monday: JSON.parse(JSON.stringify(record.hourPreferences[1])),
          tuesday: JSON.parse(JSON.stringify(record.hourPreferences[2])),
          wednesday: JSON.parse(JSON.stringify(record.hourPreferences[3])),
          thursday: JSON.parse(JSON.stringify(record.hourPreferences[4])),
          friday: JSON.parse(JSON.stringify(record.hourPreferences[5]))
        })
        editAssignmentPresenter.setOpenModal(true)
        await editAssignmentPresenter.fetchFormOptions()
      }
    }
  }

  private formatTimeString = (preferences: HourPreference) => {
    if (!preferences) {
      return null
    }
    const { dropOffHour, dropOffMinute, pickUpHour, pickUpMinute } = preferences
    let dropOffHourString = dropOffHour.toString()
    if (dropOffHourString.length === 1) {
      dropOffHourString = '0' + dropOffHourString
    }
    let dropOffMinuteString = dropOffMinute.toString()
    if (dropOffMinuteString.length === 1) {
      dropOffMinuteString = '0' + dropOffMinuteString
    }
    let pickUpHourString = pickUpHour.toString()
    if (pickUpHourString.length === 1) {
      pickUpHourString = '0' + pickUpHourString
    }
    let pickUpMinuteString = pickUpMinute.toString()
    if (pickUpMinuteString.length === 1) {
      pickUpMinuteString = '0' + pickUpMinuteString
    }
    const dropOffAmPm = dropOffHour < 12 ? 'AM' : 'PM'
    const pickUpAmPm = pickUpHour < 12 ? 'AM' : 'PM'
    return `${dropOffHourString}:${dropOffMinuteString} ${dropOffAmPm} - ${pickUpHourString}:${pickUpMinuteString} ${pickUpAmPm}`
  }

  private initializeFields = () => {
    if (!this.formRef.current) {
      return
    }
    const fields: FieldData[] = []
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
    days.forEach((day) => {
      if ((this.props.presenter.getFormData().schedule as any)[day]) {
        fields.push(
          ...[
            {
              name: [`${day}DropOffHour`],
              value: (this.props.presenter.getFormData().schedule as any)[day].dropOffHour
            },
            {
              name: [`${day}DropOffMinute`],
              value: (this.props.presenter.getFormData().schedule as any)[day].dropOffMinute
            },
            {
              name: [`${day}PickUpHour`],
              value: (this.props.presenter.getFormData().schedule as any)[day].pickUpHour
            },
            {
              name: [`${day}PickUpMinute`],
              value: (this.props.presenter.getFormData().schedule as any)[day].pickUpMinute
            },
            {
              name: [`${day}Included`],
              value: true
            }
          ]
        )
      } else {
        fields.push(
          ...[
            {
              name: [`${day}Included`],
              value: false
            }
          ]
        )
      }
    })
    this.formRef.current!.setFields(fields)
    this.formRef.current!.setFields([
      {
        name: ['client'],
        value: this.props.presenter.getFormData().clientId || null
      },
      {
        name: ['therapist'],
        value: this.props.presenter.getFormData().therapistId || null
      },
      {
        name: ['bcba'],
        value: this.props.presenter.getFormData().bcbaId || null
      },
      {
        name: ['therapyStartDate'],
        value: this.props.presenter.getFormData().therapyStartDate
          ? moment.utc(this.props.presenter.getFormData().therapyStartDate)
          : null
      }
    ])
  }
}

export default ClientAssignment
