import ApiBcbaDto from '../../../BcbaCrud/ApiBcbaDto'
import IBcbaGetter from '../../../BcbaCrud/BcbaGetter/Interfaces/IBcbaGetter'
import ApiClientDto from '../../../ClientCrud/ApiClientDto'
import IClientGetter from '../../../ClientCrud/ClientGetter/IClientGetter'
import ApiEmployeeDto from '../../../EmployeeCrud/ApiEmployeeDto'
import IEmployeeGetter from '../../../EmployeeCrud/EmployeeGetter/IEmployeeGetter'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import { HourPreference } from '../../../Registration/HourPreferencesDto'
import IRegistrationCreator from '../../../Registration/RegistrationCreator/IRegistrationCreator'
import IRegistrationGetter from '../../../Registration/RegistrationGetter/IRegistrationGetter'
import EditAssignmentPresenter from '../EditAssignmentForm/Presenter/EditAssignmentPresenter'
import IEditAssignmentPresenter from '../EditAssignmentForm/Presenter/IEditAssignmentPresenter'
import AssignmentFormData from '../Interfaces/AssignmentFormData'
import AssignmentTableData from '../Interfaces/AssignmentTableData'
import IClientAssignmentPresenter from './IClientAssignmentPresenter'

const initialFormData = {
  clientId: null,
  therapistId: null,
  bcbaId: null,
  therapyStartDate: null,
  schedule: {
    monday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    tuesday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    wednesday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    thursday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    friday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    }
  }
}

class ClientAssignmentPresenter implements IClientAssignmentPresenter {
  private clientOptions: ApiClientDto[]
  private therapistOptions: ApiEmployeeDto[]
  private bcbaOptions: ApiBcbaDto[]
  private formData: AssignmentFormData
  private tableData: AssignmentTableData[]
  private isLoading: boolean
  private isOpenModal: boolean
  private errorMessage: string | null
  private view: ISubscribingView | null
  private editAssignmentPresenter: IEditAssignmentPresenter

  constructor(
    private readonly registrationGetter: IRegistrationGetter,
    private readonly registrationCreator: IRegistrationCreator,
    private readonly clientGetter: IClientGetter,
    private readonly employeeGetter: IEmployeeGetter,
    private readonly bcbaGetter: IBcbaGetter
  ) {
    this.clientOptions = []
    this.therapistOptions = []
    this.bcbaOptions = []
    this.formData = JSON.parse(JSON.stringify(initialFormData))
    this.tableData = []
    this.isLoading = false
    this.isOpenModal = false
    this.errorMessage = null
    this.view = null
    this.editAssignmentPresenter = new EditAssignmentPresenter(
      this,
      this.registrationCreator,
      this.clientGetter,
      this.employeeGetter,
      this.bcbaGetter
    )
    this.initialize()
  }

  public async fetchTableData(): Promise<void> {
    this.isLoading = true
    this.updateView()
    const assignments = await this.registrationGetter.getAllAssignments()
    if (!assignments || !Array.isArray(assignments)) {
      this.isLoading = false
      this.updateView()
      return
    }
    this.tableData = await Promise.all(
      assignments.map(async (assignment) => {
        const profiles = await Promise.all([
          this.clientGetter.getClientProfile(assignment.clientId),
          this.employeeGetter.getEmployeeProfile(assignment.therapistId),
          this.bcbaGetter.getBcbaProfileById(assignment.bcbaId),
          this.registrationGetter.getHourPreferences(assignment.clientId, assignment.therapistId)
        ])
        return {
          clientId: assignment.clientId,
          clientName: `${profiles[0]!.firstName || ''} ${profiles[0]!.middleName || ''} ${profiles[0]!.lastName || ''
            }`.trim(),
          therapistId: assignment.therapistId,
          therapistName: `${profiles[1]!.firstName || ''} ${profiles[1]!.middleName || ''} ${profiles[1]!.lastName || ''
            }`.trim(),
          bcbaId: assignment.bcbaId,
          bcbaName: `${profiles[2]!.firstName || ''} ${profiles[2]!.middleName || ''} ${profiles[2]!.lastName || ''
            }`.trim(),
          hourPreferences: profiles[3] || {
            1: null,
            2: null,
            3: null,
            4: null,
            5: null
          },
          key: `${assignment.clientId}-${assignment.therapistId}-${assignment.bcbaId}`
        }
      })
    )
    this.tableData.sort((a, b) => {
      if (a.clientName < b.clientName) {
        return -1
      } else if (a.clientName > b.clientName) {
        return 1
      } else {
        if (a.therapistName < b.therapistName) {
          return -1
        } else if (a.therapistName > b.therapistName) {
          return 1
        } else {
          if (a.bcbaName <= b.bcbaName) {
            return -1
          } else {
            return 1
          }
        }
      }
    })
    this.isLoading = false
    this.updateView()
  }

  public async fetchFormOptions(): Promise<void> {
    this.isLoading = true
    this.updateView()
    const responses = await Promise.all([
      this.clientGetter.searchClientProfiles(null, null, null, null, null, null, null, true),
      this.employeeGetter.searchEmployeeProfiles(null, null, null, 'THERAPIST', null, null, null, null, null, true),
      this.bcbaGetter.searchBcbaProfiles(null, null, null, null, null)
    ])
    this.clientOptions = responses[0]
    this.therapistOptions = responses[1]
    this.bcbaOptions = responses[2]
    this.isLoading = false
    this.updateView()
  }

  public getEditAssignmentPresenter(): IEditAssignmentPresenter {
    return this.editAssignmentPresenter
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public getTherapistOptions(): ApiEmployeeDto[] {
    return this.therapistOptions
  }

  public getBcbaOptions(): ApiBcbaDto[] {
    return this.bcbaOptions
  }

  public getFormData(): AssignmentFormData {
    return this.formData
  }

  public getTableData(): AssignmentTableData[] {
    return this.tableData
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public getOpenModal(): boolean {
    return this.isOpenModal
  }

  public setOpenModal(isOpenModal: boolean): void {
    this.isOpenModal = isOpenModal
    this.updateView()
  }

  public setClientId(clientId: string | null): void {
    this.formData.clientId = clientId || null
  }

  public setTherapistId(therapistId: string | null): void {
    this.formData.therapistId = therapistId || null
  }

  public setBcbaId(bcbaId: string | null): void {
    this.formData.bcbaId = bcbaId || null
  }

  public setTherapyStartDate(therapyStartDate: string | null): void {
    this.formData.therapyStartDate = therapyStartDate || null
  }

  public setDaySchedule(day: string, daySchedule: HourPreference | null): void {
    ; (this.formData.schedule as any)[day] = daySchedule
  }

  public setDropOffHour(day: string, dropOffHour: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].dropOffHour = dropOffHour
    }
  }

  public setDropOffMinute(day: string, dropOffMinute: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].dropOffMinute = dropOffMinute
    }
  }

  public setPickUpHour(day: string, pickUpHour: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].pickUpHour = pickUpHour
    }
  }

  public setPickUpMinute(day: string, pickUpMinute: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].pickUpMinute = pickUpMinute
    }
  }

  public async submitForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.registrationCreator.register(this.formData)
      await this.fetchTableData()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public resetFormData(): void {
    this.formData = JSON.parse(JSON.stringify(initialFormData))
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default ClientAssignmentPresenter
