import { SCHEDULER_URL } from "../../constant"
import IFetcher from "../../Drivers/IFetcher"
import Notification from "../Notification"
import INotificationCreator from "./INotificationCreator"

class NotificationCreator implements INotificationCreator {
  constructor(private readonly fetcher: IFetcher) { }

  public async sendNotification(notification: Notification): Promise<void> {
    return await this.fetcher.fetch({
      body: notification,
      method: 'POST',
      url: `${SCHEDULER_URL}notification`
    })
  }
}

export default NotificationCreator