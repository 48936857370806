import ValidEmployeeLeavePeriodRecordsType, {
  earlyLeaveRecordType,
  lateArrivalRecordType,
  multipleDayRecordType,
  oneDayRecordType
} from '../../../Model/Records/LeavePeriodRecordsType'
import ValidLeavePeriodType, {
  earlyEndPeriodType,
  lateStartPeriodType,
  multipleDaysPeriodType,
  singleDayPeriodType
} from '../../../Model/Types/ValidLeavePeriodType'
import ILeaveTypeFormatter from './ILeavePeriodTypeFormatter'

const typesOfLeavePeriodType = [
  earlyEndPeriodType,
  lateStartPeriodType,
  singleDayPeriodType,
  multipleDaysPeriodType
]
class LeaveTypeFormatter implements ILeaveTypeFormatter {
  private leavePeriodTypeMap: Map<string, { record: ValidEmployeeLeavePeriodRecordsType }>

  constructor() {
    this.leavePeriodTypeMap = new Map()
    this.populatePeriodTypeMap()
  }

  private populatePeriodTypeMap(): void {
    for (let i = 0; i < typesOfLeavePeriodType.length; i++) {
      const type = typesOfLeavePeriodType[i]

      if (type === earlyEndPeriodType) {
        this.leavePeriodTypeMap.set(earlyEndPeriodType, { record: earlyLeaveRecordType })
      }

      if (type === lateStartPeriodType) {
        this.leavePeriodTypeMap.set(lateStartPeriodType, { record: lateArrivalRecordType })
      }

      if (type === singleDayPeriodType) {
        this.leavePeriodTypeMap.set(singleDayPeriodType, { record: oneDayRecordType })
      }

      if (type === multipleDaysPeriodType) {
        this.leavePeriodTypeMap.set(multipleDaysPeriodType, { record: multipleDayRecordType })
      }
    }
  }

  public getFormattedLeavePeriodType(
    leaveRecord: ValidEmployeeLeavePeriodRecordsType
  ): ValidLeavePeriodType {
    const periodType = Array.from(this.leavePeriodTypeMap.keys()).find((key) => {
      const record = this.leavePeriodTypeMap.get(key)

      if (record) {
        return record.record === leaveRecord
      }
    })

    if (periodType) {
      return periodType as ValidLeavePeriodType
    }

    return '' as ValidLeavePeriodType
  }

  public getLeavePeriodRecordByType(
    type: ValidLeavePeriodType
  ): ValidEmployeeLeavePeriodRecordsType {
    const periodType = this.leavePeriodTypeMap.get(type)

    if (periodType) {
      return periodType.record
    }

    return '' as ValidEmployeeLeavePeriodRecordsType
  }
}

export default LeaveTypeFormatter
