import { BASE_URL } from '../../constant'
import IFetcher from '../../Drivers/IFetcher'
import ApiEmployeeDto from '../ApiEmployeeDto'
import IEmployeePoster from './IEmployeePoster'

class EmployeePoster implements IEmployeePoster {
  constructor(private readonly fetcher: IFetcher) {}

  public async createEmployee(employee: ApiEmployeeDto): Promise<ApiEmployeeDto> {
    return await this.fetcher.fetch({
      body: employee,
      method: 'POST',
      url: `${BASE_URL}employee/create`
    })
  }

  public async updateEmployee(employee: ApiEmployeeDto): Promise<ApiEmployeeDto> {
    return await this.fetcher.fetch({
      body: employee,
      method: 'PUT',
      url: `${BASE_URL}employee/update`
    })
  }
}

export default EmployeePoster
