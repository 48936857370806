import ISubscribingView from "../../../../Model/Interfaces/ISubscribingViewPresenter"
import ReschedulePlanSessionDto from "../../../../Scheduling/Dtos/ReschedulePlanSessionDto"
import IReschedulerPresenter from '../../Presenter/IReschedulerPresenter'
import IClientRescheduleColumnPresenter from "./IClientRescheduleColumnPresenter"

class ClientRescheduleColumnPresenter implements IClientRescheduleColumnPresenter {
  private stagedSessions: ReschedulePlanSessionDto[]
  private sessions: ReschedulePlanSessionDto[]
  private view: ISubscribingView | null

  constructor(private readonly parentPresenter: IReschedulerPresenter) {
    this.stagedSessions = []
    this.sessions = []
    this.view = null
    this.initialize()
  }

  public getParentPresenter(): IReschedulerPresenter {
    return this.parentPresenter
  }

  public getSessions(): ReschedulePlanSessionDto[] {
    return this.sessions
  }

  public getStagedSessions(): ReschedulePlanSessionDto[] {
    return this.stagedSessions
  }

  public setSessions(sessions: ReschedulePlanSessionDto[]): void {
    this.sessions = sessions
    this.stagedSessions = []
  }

  public updateAction(action: string, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      if (action === 'DELETE') {
        const original = this.parentPresenter.getClientSessions().find(session => session.id === sessionId) || null
        if (original) {
          session.therapistId = original.therapistId
          session.bcbaId = original.bcbaId
          session.scheduledStart = original.scheduledStart
          session.scheduledEnd = original.scheduledEnd
          session.comments = original.comments
        }
      }
      session.action = action
    }
    this.updateView()
  }

  public updateScheduledStart(scheduledStart: string, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      session.scheduledStart = scheduledStart
    }
  }

  public updateScheduledEnd(scheduledEnd: string, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      session.scheduledEnd = scheduledEnd
    }
  }

  public updateTherapist(therapistId: string | null, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      session.therapistId = therapistId || null
      this.updateView()
    }
  }

  public updateBcba(bcbaId: string | null, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      session.bcbaId = bcbaId || null
    }
  }

  public updateComments(comments: string, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      session.comments = comments || null
    }
  }

  public updateSendSms(sendSms: boolean, sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      session.sendSms = sendSms || false
    }
  }

  public resetValues(sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      const original = this.parentPresenter.getTherapistSessions().find(session => session.id === sessionId) || null
      if (original) {
        session.therapistId = original.therapistId
        session.bcbaId = original.bcbaId
        session.scheduledStart = original.scheduledStart
        session.scheduledEnd = original.scheduledEnd
        session.comments = original.comments
      }
    }
    this.updateView()
  }

  public addToStage(sessionId: number): void {
    const session = this.sessions.find(session => session.id === sessionId)
    if (session) {
      if (session.action === 'DELETE' || session.therapistId) {
        this.stagedSessions.push({
          id: session.id,
          clientId: session.clientId,
          therapistId: session.therapistId,
          bcbaId: session.bcbaId,
          scheduledStart: session.scheduledStart,
          scheduledEnd: session.scheduledEnd,
          comments: session.comments,
          action: session.action,
          sendSms: session.sendSms
        })
        this.updateView()
        this.parentPresenter.setIsLoading(false)
      }
    }
  }

  public unstage(sessionId: number): void {
    const index = this.stagedSessions.findIndex(session => session.id === sessionId)
    if (index > -1) {
      this.stagedSessions.splice(index, 1)
      this.updateView()
      this.parentPresenter.setIsLoading(false)
    }
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private initialize(): void {
    this.updateView()
  }
}

export default ClientRescheduleColumnPresenter