import { DeleteFilled, ScheduleFilled, StarFilled } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Divider, Row, Segmented, Select, Tag } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment-timezone'
import React, { Component, Fragment, ReactElement } from 'react'

import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import ITherapistRescheduleColumnPresenter from './Presenter/ITherapistRescheduleColumnPresenter'

type Props = {
  presenter: ITherapistRescheduleColumnPresenter
}

class TherapistRescheduleColumn extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const { RangePicker } = DatePicker
    const { OptGroup, Option } = Select

    return (
      <div style={{ width: '45%' }}>
        <Divider orientation='center'>Appointments Needing Clients</Divider>
        <div style={{ overflowY: 'scroll', maxHeight: '62vh' }}>
          {presenter.getSessions().map(therapistSession => {
            const client = presenter.getParentPresenter().getClientOptions().find(client => client.uuid === therapistSession.clientId) || null
            const therapist = presenter.getParentPresenter().getTherapistOptions().find(therapist => therapist.uuid === therapistSession.therapistId) || null
            const clientSessionsInSameTimeframe = presenter.getParentPresenter()
              .getClientSessions()
              .filter(session => moment.utc(session.scheduledStart).isSame(moment.utc(therapistSession.scheduledStart), 'date'))
              .map(session => session.clientId)
            const allOtherClients = presenter.getParentPresenter().getClientOptions().filter(client => !clientSessionsInSameTimeframe.includes(client.uuid))

            return (
              <Card
                key={therapistSession.id}
                style={{ borderColor: '#dbdbdb', margin: '20px 15px' }}
                title={<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }}>
                  <Col>
                    Client: {
                      client ? <Tag color='cyan'>
                        {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                      </Tag> : <Tag>VACANT</Tag>
                    }
                    Therapist: {
                      therapist ? <Tag color='cyan'>
                        {`${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`}
                      </Tag> : <Tag>VACANT</Tag>
                    }
                  </Col>
                  <Col>
                    <Segmented
                      defaultValue='RESCHEDULE'
                      disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                      onChange={value => presenter.updateAction(value as string, therapistSession.id)}
                      options={[
                        {
                          label: (
                            <div style={{ padding: 4 }}>
                              <ScheduleFilled />
                              <div>Update Appointment</div>
                            </div>
                          ),
                          value: 'RESCHEDULE'
                        },
                        {
                          label: (
                            <div style={{ padding: 4 }}>
                              <DeleteFilled />
                              <div>Cancel Appointment</div>
                            </div>
                          ),
                          value: 'DELETE'
                        }
                      ]}
                      size='small'
                    />
                  </Col>
                </Row>}
              >
                {therapistSession.action === 'RESCHEDULE' ? (
                  <Fragment>
                    <p>Verify the appointment start and end times</p>
                    <Row gutter={8} style={{ marginBottom: '15px' }}>
                      <Col span={24}>
                        <RangePicker
                          allowClear={false}
                          defaultValue={[moment.tz(therapistSession.scheduledStart, 'America/Denver'), moment.tz(therapistSession.scheduledEnd, 'America/Denver')]}
                          disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                          disabledDate={date => {
                            const scheduledStart = moment.tz(therapistSession.scheduledStart, 'America/Denver')
                            return date.year() !== scheduledStart.year() || date.month() !== scheduledStart.month() || date.date() !== scheduledStart.date()
                          }}
                          format='MM/DD/YYYY hh:mm a'
                          onChange={dates => this.updateSelectedDateRange(dates, therapistSession.id)}
                          showTime={{ hideDisabledOptions: true, format: 'HH:mm' }}
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                    <p>Select a client and bcba to fill the appointment:</p>
                    <Row gutter={8} style={{ marginBottom: '15px' }}>
                      <Col span={12}>
                        <Select
                          showSearch
                          disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                          placeholder='Select a client'
                          optionFilterProp='key'
                          onChange={uuid => presenter.updateClient(uuid, therapistSession.id)}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.key as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          style={{ width: '100%' }}
                        >
                          <OptGroup label='Clients with vacancies'>
                            {clientSessionsInSameTimeframe.map(id => {
                              const client = presenter.getParentPresenter().getClientOptions().find(c => c.uuid === id) || null
                              return client ? (
                                <Option
                                  key={`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                                  value={client.uuid}
                                >
                                  {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                                  {(therapistSession as any).rtsClients.some((rts: any) => rts.uuid === client.uuid) ? (
                                    <StarFilled style={{ marginLeft: '5px', color: 'orange' }} />
                                  ) : null}
                                </Option>
                              ) : null
                            })}
                          </OptGroup>
                          <OptGroup label='All other clients'>
                            {allOtherClients.map(client => (
                              <Option
                                key={`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                                value={client.uuid}
                              >
                                {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`}
                                {(therapistSession as any).rtsClients.some((rts: any) => rts.uuid === client.uuid) ? (
                                  <StarFilled style={{ marginLeft: '5px', color: 'orange' }} />
                                ) : null}
                              </Option>
                            ))}
                          </OptGroup>
                        </Select>
                      </Col>
                      <Col span={12}>
                        <Select
                          allowClear
                          showSearch
                          defaultValue={therapistSession.bcbaId}
                          disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                          placeholder='Select a bcba'
                          optionFilterProp='children'
                          onChange={uuid => presenter.updateBcba(uuid, therapistSession.id)}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          style={{ width: '100%' }}
                        >
                          {presenter.getParentPresenter().getBcbaOptions().map(bcba => (
                            <Option key={bcba.uuid} value={bcba.uuid}>
                              {`${bcba.firstName || ''}${bcba.middleName ? ' ' + bcba.middleName : ''}${bcba.lastName ? ' ' + bcba.lastName : ''}`.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <p>Comments</p>
                    <TextArea
                      autoSize
                      defaultValue={therapistSession.comments || undefined}
                      disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                      onChange={e => presenter.updateComments(e.target.value, therapistSession.id)}
                      style={{ marginBottom: '15px' }}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <p>Comments</p>
                    <TextArea
                      autoSize
                      defaultValue={therapistSession.comments || undefined}
                      disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                      onChange={e => presenter.updateComments(e.target.value, therapistSession.id)}
                      style={{ marginBottom: '15px' }}
                    />
                  </Fragment>
                )}
                <Row style={{ justifyContent: 'space-between' }}>
                  <Checkbox
                    defaultChecked={therapistSession.sendSms}
                    disabled={presenter.getParentPresenter().getIsLoading() || this.isStaged(therapistSession.id)}
                    onChange={e => presenter.updateSendSms(e.target.checked, therapistSession.id)}
                  >
                    Send SMS?
                  </Checkbox>
                  <Button
                    danger={this.isStaged(therapistSession.id)}
                    disabled={presenter.getParentPresenter().getIsLoading()}
                    onClick={this.isStaged(therapistSession.id) ? () => presenter.unstage(therapistSession.id) : () => presenter.addToStage(therapistSession.id)}
                    type='primary'
                  >
                    {this.isStaged(therapistSession.id) ? 'Unstage' : 'Stage'}
                  </Button>
                </Row>
              </Card>
            )
          })}
        </div>
      </div >
    )
  }

  update(): void {
    this.setState({})
  }

  private isStaged = (sessionId: number) => {
    return this.props.presenter.getStagedSessions().some(session => session.id === sessionId)
  }

  private updateSelectedDateRange = (dates: any, sessionId: number): void => {
    const startDate = moment.tz(dates[0], 'America/Denver')
    const endDate = moment.tz(dates[1], 'America/Denver')
    this.props.presenter.updateScheduledStart(startDate.toISOString(), sessionId)
    this.props.presenter.updateScheduledEnd(endDate.toISOString(), sessionId)
  }
}

export default TherapistRescheduleColumn
