import ApiClientDto from "../../../../ClientCrud/ApiClientDto"
import ApiEmployeeDto from "../../../../EmployeeCrud/ApiEmployeeDto"
import ISubscribingView from "../../../../Model/Interfaces/ISubscribingViewPresenter"
import IRtsCreator from "../../../../RtsCrud/RtsCreator/IRtsCreator"
import IRtsPresenter from "../../Presenter/IRtsPresenter"
import IAddRtsFormPresenter from "./IAddRtsFormPresenter"

class AddRtsFormPresenter implements IAddRtsFormPresenter {
  private clientId: string | null
  private therapistId: string | null
  private isLoading: boolean
  private openModal: boolean
  private errorMessage: string | null
  private view: ISubscribingView | null

  constructor(private readonly rtsCreator: IRtsCreator, private readonly parentPresenter: IRtsPresenter) {
    this.clientId = null
    this.therapistId = null
    this.isLoading = false
    this.openModal = false
    this.errorMessage = null
    this.view = null
    this.initialize()
  }

  public getClientOptions(): ApiClientDto[] {
    return this.parentPresenter.getClientOptions()
  }

  public getTherapistOptions(): ApiEmployeeDto[] {
    return this.parentPresenter.getTherapistOptions()
  }

  public getClientId(): string | null {
    return this.clientId
  }

  public getTherapistId(): string | null {
    return this.therapistId
  }

  public getOpenModal(): boolean {
    return this.openModal
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public setClientId(clientId: string | null) {
    this.clientId = clientId
  }

  public setTherapistId(therapistId: string | null) {
    this.therapistId = therapistId
  }

  public setOpenModal(openModal: boolean): void {
    this.openModal = openModal
    this.updateView()
  }

  public async submitForm(): Promise<void> {
    this.errorMessage = null
    this.isLoading = true
    this.updateView()
    try {
      await this.rtsCreator.addRtsRecord(this.clientId as string, this.therapistId as string)
      this.parentPresenter.setTargetType('CLIENT')
      this.parentPresenter.setTargetUuid(this.clientId)
      await this.parentPresenter.fetchRtsRecords()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default AddRtsFormPresenter