import './style.css'

import { PlusOutlined, RollbackOutlined, SearchOutlined } from "@ant-design/icons"
import { Button, Calendar, Col, DatePicker, Divider, Form, FormInstance, Layout, Modal, Row, Select, Tag } from "antd"
import TextArea from 'antd/lib/input/TextArea'
import { Content } from "antd/lib/layout/layout"
import moment, { Moment } from "moment-timezone"
import React, { Component, Fragment, ReactElement } from "react"

import AppointmentDto from '../../AppointmentCrud/AppointmentDto'
import Footer from "../../Common/Footer/Footer"
import Header from "../../Common/Header/Header"
import { redirectIfNotAuthorized } from '../../constant'
import ISubscribingView from "../../Model/Interfaces/ISubscribingViewPresenter"
import AppointmentDetails from './AppointmentDetails/AppointmentDetails'
import ICalendarPresenter from "./Presenter/ICalendarPresenter"

type Props = {
  presenter: ICalendarPresenter
}

class AppointmentCalendar extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()
  addFormRef = React.createRef<FormInstance>()

  async componentDidMount(): Promise<void> {
    await redirectIfNotAuthorized()
    this.props.presenter.setView(this)
    const startDate = moment.tz('America/Denver').startOf('month').toISOString()
    const endDate = moment.tz('America/Denver').endOf('month').toISOString()
    this.props.presenter.setIsLoading(true)
    await Promise.all([this.props.presenter.fetchFilterOptions(), this.props.presenter.fetchAppointments(startDate, endDate)])
    this.props.presenter.setIsLoading(false)
  }

  componentDidUpdate() {
    if (this.formRef.current) {
      this.formRef.current.setFields([
        {
          name: ['client'],
          value: this.props.presenter.getSelectedClient()
        },
        {
          name: ['therapist'],
          value: this.props.presenter.getSelectedTherapist()
        }
      ])
    }
  }

  render(): ReactElement {
    const { presenter } = this.props
    const { Option } = Select

    return (
      <Layout>
        <Header id='dashboard-header' />
        <Content
          className='site-layout'
          style={{ backgroundColor: 'white', padding: '0 50px', overflowY: 'scroll' }}
        >
          {presenter.getShowAppointmentList() ? (
            <Fragment>
              <Divider orientation='center'>{moment.tz(presenter.getSelectedDate(), 'America/Denver').format('MM-DD-YYYY')} Appointments</Divider>
              <Button
                icon={<RollbackOutlined />}
                onClick={() => {
                  presenter.setShowAppointmentList(false)
                  this.update()
                }}
                style={{ marginBottom: '15px' }}
                type='primary'
              >
                Return to Calendar
              </Button>
              <AppointmentDetails presenter={presenter.getAppointmentDetailsPresenter()} />
            </Fragment>
          ) : (
            <Fragment>
              <Divider orientation='center'>Appointment Calendar</Divider>
              <Form name='searchAppointmentsForm' ref={this.formRef}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => presenter.setIsOpenModal(true)}
                    style={{ justifySelf: 'start' }}
                    type='primary'
                  >
                    Schedule New Appointment
                  </Button>
                  <Row gutter={8}>
                    <Col style={{ width: '300px' }}>
                      <Form.Item label='Client' name='client'>
                        <Select
                          allowClear
                          disabled={presenter.getIsLoading()}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          onChange={uuid => presenter.setSelectedClient(uuid || null)}
                          showSearch
                        >
                          {presenter.getClientOptions().map(client => (
                            <Option key={client.uuid} value={client.uuid}>
                              {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '300px' }}>
                      <Form.Item label='Therapist' name='therapist'>
                        <Select
                          allowClear
                          disabled={presenter.getIsLoading()}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          onChange={uuid => presenter.setSelectedTherapist(uuid || null)}
                          showSearch
                        >
                          {presenter.getTherapistOptions().map(therapist => (
                            <Option key={therapist.uuid} value={therapist.uuid}>
                              {`${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        disabled={presenter.getIsLoading()}
                        icon={<SearchOutlined />}
                        loading={presenter.getIsLoading()}
                        onClick={this.searchCalendar}
                        type='primary'
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
              <Calendar
                dateCellRender={this.dateCellRender}
                mode='month'
                onPanelChange={this.onPanelChange}
                onSelect={this.onSelect}
                value={presenter.getSelectedDate() ? moment.tz(presenter.getSelectedDate(), 'America/Denver') : undefined}
              />
              <Modal
                centered
                confirmLoading={presenter.getIsLoading()}
                okText='Schedule Appointment'
                onCancel={this.cancelForm}
                onOk={this.submitForm}
                title='Schedule New Appointment'
                visible={presenter.getIsOpenModal()}
                width={800}
              >
                {presenter.getErrorMessage() ? (
                  <div className='error'>* {presenter.getErrorMessage()} *</div>
                ) : null}
                <Form
                  initialValues={{
                    client: null,
                    therapist: null,
                    bcba: null,
                    start: null,
                    end: null,
                    comments: null
                  }}
                  name='addAppointmentForm'
                  ref={this.addFormRef}
                >
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item
                        label='Client'
                        name='client'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a client'
                          }
                        ]}
                      >
                        <Select
                          allowClear
                          disabled={presenter.getIsLoading()}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          onChange={uuid => presenter.setFormData({
                            ...presenter.getFormData(),
                            clientId: uuid
                          })}
                          showSearch
                          value={presenter.getFormData().clientId}
                        >
                          {presenter.getClientOptions().map(client => (
                            <Option key={client.uuid} value={client.uuid}>
                              {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label='Therapist'
                        name='therapist'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a therapist'
                          }
                        ]}
                      >
                        <Select
                          allowClear
                          disabled={presenter.getIsLoading()}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          onChange={uuid => presenter.setFormData({
                            ...presenter.getFormData(),
                            therapistId: uuid
                          })}
                          showSearch
                          value={presenter.getFormData().therapistId}
                        >
                          {presenter.getTherapistOptions().map(therapist => (
                            <Option key={therapist.uuid} value={therapist.uuid}>
                              {`${therapist.firstName || ''}${therapist.middleName ? ' ' + therapist.middleName : ''}${therapist.lastName ? ' ' + therapist.lastName : ''}`.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label='Bcba' name='bcba'>
                        <Select
                          allowClear
                          disabled={presenter.getIsLoading()}
                          filterOption={(input, option) => {
                            const isGroup = Array.isArray(option!.options)
                            if (isGroup) {
                              return false
                            }
                            return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }}
                          onChange={uuid => presenter.setFormData({
                            ...presenter.getFormData(),
                            bcbaId: uuid
                          })}
                          showSearch
                          value={presenter.getFormData().bcbaId}
                        >
                          {presenter.getBcbaOptions().map(bcba => (
                            <Option key={bcba.uuid} value={bcba.uuid}>
                              {`${bcba.firstName || ''}${bcba.middleName ? ' ' + bcba.middleName : ''}${bcba.lastName ? ' ' + bcba.lastName : ''}`.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label='Appointment Start Date'
                        name='start'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a start time'
                          }
                        ]}
                      >
                        <DatePicker
                          disabled={presenter.getIsLoading()}
                          format='MM/DD/YYYY hh:mm a'
                          onChange={this.updateStartDate}
                          showTime={{ format: 'HH:mm' }}
                          value={presenter.getFormData()!.scheduledStart ? moment.tz(presenter.getFormData()!.scheduledStart, 'America/Denver') : null}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label='Appointment End Date'
                        name='end'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a end time'
                          }
                        ]}
                      >
                        <DatePicker
                          disabled={presenter.getIsLoading()}
                          format='MM/DD/YYYY hh:mm a'
                          onChange={this.updateEndDate}
                          showTime={{ format: 'HH:mm' }}
                          value={presenter.getFormData()!.scheduledEnd ? moment.tz(presenter.getFormData()!.scheduledEnd, 'America/Denver') : null}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label='Comments' name='comments'>
                        <TextArea
                          disabled={presenter.getIsLoading()}
                          onChange={(e) => presenter.setFormData({
                            ...presenter.getFormData(),
                            comments: e.target.value
                          })}
                          value={presenter.getFormData().comments || undefined}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Fragment>
          )}
        </Content>
        <Footer />
      </Layout>
    )
  }

  update(): void {
    this.setState({})
  }

  private submitForm = async () => {
    await this.addFormRef.current!.validateFields()
    await this.props.presenter.submitAddForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.addFormRef.current!.resetFields()
      this.props.presenter.setFormData({
        id: null,
        clientId: null,
        therapistId: null,
        bcbaId: null,
        scheduledStart: null,
        scheduledEnd: null,
        status: null,
        comments: null
      })
      this.props.presenter.setIsOpenModal(false)
    }
  }

  private cancelForm = () => {
    this.addFormRef.current!.resetFields()
    this.props.presenter.setFormData({
      id: null,
      clientId: null,
      therapistId: null,
      bcbaId: null,
      scheduledStart: null,
      scheduledEnd: null,
      status: null,
      comments: null
    })
    this.props.presenter.setIsOpenModal(false)
  }

  private updateStartDate = (date: any) => {
    if (date) {
      const startDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(date.hour())
        .minute(date.minute())
        .second(0)
        .millisecond(0)
      this.props.presenter.setFormData({
        ...this.props.presenter.getFormData(),
        scheduledStart: startDate.toISOString()
      })
    } else {
      this.props.presenter.setFormData({
        ...this.props.presenter.getFormData(),
        scheduledStart: null
      })
    }
  }

  private updateEndDate = (date: any) => {
    if (date) {
      const endDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(date.hour())
        .minute(date.minute())
        .second(0)
        .millisecond(0)
      this.props.presenter.setFormData({
        ...this.props.presenter.getFormData(),
        scheduledEnd: endDate.toISOString()
      })
    } else {
      this.props.presenter.setFormData({
        ...this.props.presenter.getFormData(),
        scheduledEnd: null
      })
    }
  }

  private dateCellRender = (value: Moment) => {
    const date = moment.tz(value, 'America/Denver')
    const dateString = `${date.month() + 1}-${date.date()}-${date.year()}`
    const appointments = this.props.presenter.getCalendarData()[dateString] || []
    return appointments.length ? (
      <ul className='date-cell-list'>
        <li>
          <Tag color='purple' style={{ width: '90%', textAlign: 'center' }}>
            {appointments.filter((a: AppointmentDto) => a.status === 'PENDING_RESCHEDULE').length} pending reschedule
          </Tag>
        </li>
        <li>
          <Tag color='green' style={{ width: '90%', textAlign: 'center' }}>
            {appointments.filter((a: AppointmentDto) => a.status === 'SCHEDULED' || a.status === 'MODIFIED').length} scheduled
          </Tag>
        </li>
        <li>
          <Tag color='red' style={{ width: '90%', textAlign: 'center' }}>
            {appointments.filter((a: AppointmentDto) => a.status === 'CANCELLED').length} cancelled
          </Tag>
        </li>
        <li>
          <Tag color='blue' style={{ width: '90%', textAlign: 'center' }}>
            {appointments.filter((a: AppointmentDto) => a.status === 'COMPLETE').length} completed
          </Tag>
        </li>
      </ul>
    ) : null
  }

  private onPanelChange = async (date: Moment) => {
    const startDate = moment.tz(date, 'America/Denver').startOf('month').toISOString()
    const endDate = moment.tz(date, 'America/Denver').endOf('month').toISOString()
    this.props.presenter.setIsLoading(true)
    await this.props.presenter.fetchAppointments(startDate, endDate)
    this.props.presenter.setIsLoading(false)
  }

  private onSelect = async (selectedDate: Moment) => {
    const date = moment.tz(selectedDate, 'America/Denver').hour(0).minute(0).second(0).millisecond(0)
    this.props.presenter.setSelectedDate(date.toISOString())
    const selectedAppointments = this.props.presenter.getCalendarData()[`${date.month() + 1}-${date.date()}-${date.year()}`] || []
    this.props.presenter.setShowAppointmentList(selectedAppointments.length > 0)
    this.update()
  }

  private searchCalendar = async () => {
    const selectedDate = this.props.presenter.getSelectedDate()
    const startDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').startOf('month') : moment.tz('America/Denver').startOf('month')
    const endDate = selectedDate ? moment.tz(selectedDate, 'America/Denver').endOf('month') : moment.tz('America/Denver').endOf('month')
    this.props.presenter.setIsLoading(true)
    await this.props.presenter.fetchAppointments(startDate.toISOString(), endDate.toISOString())
    this.props.presenter.setIsLoading(false)
  }
}

export default AppointmentCalendar