import { observable } from 'mobx'

import ClientDto from '../ClientCrud/ClientDto'
import ClientGetter from '../ClientCrud/ClientGetter/ClientGetter'
import Fetcher from '../Drivers/Fetcher'

const clientGetter = new ClientGetter(new Fetcher())
const clientStore = observable({
  getClients: async (): Promise<void> => {
    clientStore.clients = await clientGetter.getClients()
  },
  getClientNameById: (id: string): string => {
    const client = clientStore.clients.find((client) => client.id === id)

    if (client) {
      return client.name
    }

    return 'Unknown client'
  },
  clients: [] as ClientDto[]
})

export default clientStore
