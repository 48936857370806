import ApiBcbaDto from '../../../../BcbaCrud/ApiBcbaDto'
import IBcbaGetter from '../../../../BcbaCrud/BcbaGetter/Interfaces/IBcbaGetter'
import ApiClientDto from '../../../../ClientCrud/ApiClientDto'
import IClientGetter from '../../../../ClientCrud/ClientGetter/IClientGetter'
import ApiEmployeeDto from '../../../../EmployeeCrud/ApiEmployeeDto'
import IEmployeeGetter from '../../../../EmployeeCrud/EmployeeGetter/IEmployeeGetter'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import { HourPreference } from '../../../../Registration/HourPreferencesDto'
import IRegistrationCreator from '../../../../Registration/RegistrationCreator/IRegistrationCreator'
import AssignmentFormData, { ScheduleData } from '../../Interfaces/AssignmentFormData'
import IClientAssignmentPresenter from '../../Presenter/IClientAssignmentPresenter'
import IEditAssignmentPresenter from './IEditAssignmentPresenter'

const initialFormData = {
  clientId: null,
  therapistId: null,
  bcbaId: null,
  therapyStartDate: null,
  schedule: {
    monday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    tuesday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    wednesday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    thursday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    },
    friday: {
      dropOffHour: 8,
      dropOffMinute: 0,
      pickUpHour: 15,
      pickUpMinute: 0
    }
  }
}

class EditAssignmentPresenter implements IEditAssignmentPresenter {
  private formData: AssignmentFormData
  private clientOptions: ApiClientDto[]
  private therapistOptions: ApiEmployeeDto[]
  private bcbaOptions: ApiBcbaDto[]
  private isLoading: boolean
  private openModal: boolean
  private openConfirmDeleteModal: boolean
  private errorMessage: string | null
  private originalSchedule: ScheduleData | null
  private view: ISubscribingView | null

  constructor(
    private readonly parentPresenter: IClientAssignmentPresenter,
    private readonly registrationCreator: IRegistrationCreator,
    private readonly clientGetter: IClientGetter,
    private readonly employeeGetter: IEmployeeGetter,
    private readonly bcbaGetter: IBcbaGetter
  ) {
    this.clientOptions = []
    this.therapistOptions = []
    this.bcbaOptions = []
    this.formData = JSON.parse(JSON.stringify(initialFormData))
    this.isLoading = false
    this.openModal = false
    this.openConfirmDeleteModal = false
    this.errorMessage = null
    this.originalSchedule = null
    this.view = null
    this.initialize()
  }

  public async deleteRecord(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.registrationCreator.deleteRegistrationRecord(
        this.formData.clientId as string,
        this.formData.therapistId as string,
        this.formData.bcbaId as string
      )
      this.setOpenConfirmDeleteModal(false)
      await this.parentPresenter.fetchTableData()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async fetchFormOptions(): Promise<void> {
    this.isLoading = true
    this.updateView()
    const responses = await Promise.all([
      this.clientGetter.searchClientProfiles(null, null, null, null, null, null, null, true),
      this.employeeGetter.searchEmployeeProfiles(
        null,
        null,
        null,
        'THERAPIST',
        null,
        null,
        null,
        null,
        null,
        true
      ),
      this.bcbaGetter.searchBcbaProfiles(null, null, null, null, null)
    ])
    this.clientOptions = responses[0]
    this.therapistOptions = responses[1]
    this.bcbaOptions = responses[2]
    this.isLoading = false
    this.updateView()
  }

  public getFormData(): AssignmentFormData {
    return this.formData
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getOpenModal(): boolean {
    return this.openModal
  }

  public getOpenConfirmDeleteModal(): boolean {
    return this.openConfirmDeleteModal
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public getTherapistOptions(): ApiEmployeeDto[] {
    return this.therapistOptions
  }

  public getBcbaOptions(): ApiBcbaDto[] {
    return this.bcbaOptions
  }

  public getOriginalSchedule(): ScheduleData | null {
    return this.originalSchedule
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public setOpenModal(openModal: boolean): void {
    this.openModal = openModal
    this.updateView()
  }

  public setOpenConfirmDeleteModal(openConfirmDeleteModal: boolean): void {
    this.openConfirmDeleteModal = openConfirmDeleteModal
    this.updateView()
  }

  public setErrorMessage(errorMessage: string | null): void {
    this.errorMessage = errorMessage || null
  }

  public setClientId(clientId: string | null): void {
    this.formData.clientId = clientId || null
  }

  public setTherapistId(therapistId: string | null): void {
    this.formData.therapistId = therapistId || null
  }

  public setBcbaId(bcbaId: string | null): void {
    this.formData.bcbaId = bcbaId || null
  }

  public setDaySchedule(day: string, daySchedule: HourPreference | null): void {
    ; (this.formData.schedule as any)[day] = daySchedule
  }

  public setDropOffHour(day: string, dropOffHour: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].dropOffHour = dropOffHour
    }
  }

  public setDropOffMinute(day: string, dropOffMinute: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].dropOffMinute = dropOffMinute
    }
  }

  public setPickUpHour(day: string, pickUpHour: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].pickUpHour = pickUpHour
    }
  }

  public setPickUpMinute(day: string, pickUpMinute: number): void {
    if ((this.formData.schedule as any)[day]) {
      ; (this.formData.schedule as any)[day].pickUpMinute = pickUpMinute
    }
  }

  public setFormData(formData: AssignmentFormData): void {
    this.formData = formData
  }

  public setOriginalSchedule(originalSchedule: ScheduleData): void {
    this.originalSchedule = originalSchedule
  }

  public resetFormData(): void {
    this.formData = JSON.parse(JSON.stringify(initialFormData))
  }

  public async submitForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.registrationCreator.updateSchedule(
        this.formData.clientId as string,
        this.formData.therapistId as string,
        this.formData.schedule
      )
      await this.parentPresenter.fetchTableData()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default EditAssignmentPresenter
