import { Tag } from 'antd'
import React from 'react'

const TherapistTableColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName'
  },
  {
    title: 'Id',
    dataIndex: 'uuid',
    key: 'uuid'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Service Center',
    dataIndex: 'serviceCenter',
    key: 'serviceCenter',
    render: (serviceCenter: string) => (
      <Tag
        color={serviceCenter === 'Aurora' ? 'cyan' : serviceCenter === 'Wheat Ridge' ? 'gold' : ''}
      >
        {serviceCenter}
      </Tag>
    )
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (active: boolean) => (
      <Tag color={active ? 'success' : 'error'}>{active ? 'Active' : 'Inactive'}</Tag>
    )
  }
]

export default TherapistTableColumns
