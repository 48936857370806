import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Layout,
  Row,
  Select,
  Table
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { Component, ReactElement } from 'react'

import Footer from '../../Common/Footer/Footer'
import Header from '../../Common/Header/Header'
import { redirectIfNotAuthorized } from '../../constant'
import ISubscribingView from '../../Model/Interfaces/ISubscribingViewPresenter'
import AddRtsForm from './AddRtsForm/AddRtsForm'
import IRtsPresenter from './Presenter/IRtsPresenter'

type Props = {
  presenter: IRtsPresenter
}

class Rts extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  async componentDidMount(): Promise<void> {
    this.props.presenter.setView(this)
    await this.props.presenter.fetchSelectOptions()
  }

  async componentDidUpdate(): Promise<void> {
    await redirectIfNotAuthorized()
  }

  render(): ReactElement {
    const { Option, OptGroup } = Select
    const { presenter } = this.props

    return (
      <Layout>
        <Header id='dashboard-header' />
        <Content
          className='site-layout'
          style={{ backgroundColor: 'white', padding: '0 50px', overflowY: 'scroll' }}
        >
          <Divider orientation='center'>Ready To Staff Lookup</Divider>
          <Form
            name='targetFilter'
            onFinish={() => presenter.fetchRtsRecords()}
          >
            <Row gutter={8}>
              <Col style={{ width: '400px' }}>
                <Form.Item label='Client/Therapist' name='target'>
                  <Select
                    disabled={presenter.getIsLoading()}
                    filterOption={(input, option) => {
                      const isGroup = Array.isArray(option!.options)
                      if (isGroup) {
                        return false
                      }
                      return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }}
                    onChange={uuid => {
                      presenter.setTargetUuid(uuid)
                      if (presenter.getClientOptions().some(client => client.uuid === uuid)) {
                        presenter.setTargetType('CLIENT')
                      } else {
                        presenter.setTargetType('THERAPIST')
                      }
                    }}
                    showSearch
                  >
                    <OptGroup label='Clients'>
                      {presenter.getClientOptions().map(client => (
                        <Option key={client.uuid} value={client.uuid}>
                          {`${client.firstName || ''}${client.middleName ? ' ' + client.middleName : ''}${client.lastName ? ' ' + client.lastName : ''}`.trim()}
                        </Option>
                      ))}
                    </OptGroup>
                    <OptGroup label='Therapists'>
                      {presenter.getTherapistOptions().map(therapist => (
                        <Option key={therapist.uuid} value={therapist.uuid}>
                          {`${therapist.firstName || ''} ${therapist.middleName ? ' ' + therapist.middleName : ''} ${therapist.lastName ? ' ' + therapist.lastName : ''}`.trim()}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    disabled={presenter.getIsLoading()}
                    icon={<SearchOutlined />}
                    loading={presenter.getIsLoading()}
                    type='primary'
                    htmlType='submit'
                  >
                    Seach RTS
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button
            type='primary'
            disabled={presenter.getIsLoading()}
            icon={<PlusOutlined />}
            onClick={this.openModal}
            style={{ marginBottom: '15px' }}
          >
            Add New RTS
          </Button>
          <Table
            columns={presenter.getTableColumns()}
            dataSource={presenter.getRtsRecords()}
            loading={presenter.getIsLoading()}
            pagination={{ position: ['bottomCenter'] }}
          />
          <AddRtsForm presenter={presenter.getAddRtsFormPresenter()} />
        </Content>
        <Footer />
      </Layout>
    )
  }

  update(): void {
    this.setState({})
  }

  private openModal = () => {
    this.props.presenter.getAddRtsFormPresenter().setOpenModal(true)
  }
}

export default Rts
