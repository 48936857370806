import './style.css'
import 'antd/dist/antd.css'

import { AutoComplete, Button, Checkbox, DatePicker, Form, Input, PageHeader, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import moment from 'moment-timezone'
import React, { Component, ReactElement } from 'react'

import { isPersonAuthorized } from '../../constant'
import ILeaveFormPresenter from '../../Model/Interfaces/ILeaveFormPresenter'
import ISubscribingView from '../../Model/Interfaces/ISubscribingViewPresenter'
import {
  earlyEndPeriodType,
  lateStartPeriodType,
  multipleDaysPeriodType,
  singleDayPeriodType
} from '../../Model/Types/ValidLeavePeriodType'

type Props = {
  presenter: ILeaveFormPresenter
}

class EmployeeLeaveRequest extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { RangePicker } = DatePicker
    const { Option } = Select
    const { presenter } = this.props

    return (
      <Content className='site-layout' style={{ backgroundColor: 'white', padding: '0 50px' }}>
        <div className='employeeLeaveRequest leaveForm'>
          {isPersonAuthorized() ? (
            <PageHeader
              className='site-page-header'
              onBack={() => history.back()}
              title='Employee Leave Request Form'
            />
          ) : (
            <PageHeader className='site-page-header' title='Employee Leave Request Form' />
          )}
          <Form
            name='leave request'
            onFinish={this.submitForm}
            layout='vertical'
            scrollToFirstError
          >
            <div className='success'>{presenter.successMessage}</div>
            <div className='error'>{presenter.errorMessage}</div>
            <Form.Item
              label='Your name'
              name='Your name'
              rules={[{ required: true, message: 'Please search for your name!' }]}
            >
              <AutoComplete
                disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                style={{ width: 200 }}
                onChange={this.updateEmployee}
                options={presenter.getAllPersonOptions()}
                placeholder='Please input your name'
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Form.Item
              name='Leave request reason'
              label='Reason of absence'
              rules={[{ required: true, message: 'Please select a reason of absence' }]}
            >
              <Select
                disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                showSearch
                placeholder='Select a leave request reason'
                onChange={this.updateLeaveRequestReason}
                filterOption={(input, option) => {
                  const opt = option!.item || option!.children || ''
                  return opt.toLowerCase().includes(input.toLowerCase())
                }}
              >
                {presenter.getLeaveReasons().map((r, i) => {
                  return (
                    <Option key={i} value={r.value}>
                      {r.value}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item name='Leave request detail' label='Leave request detail'>
              <Input.TextArea onChange={this.updateDetails} showCount maxLength={100} />
            </Form.Item>

            <Form.Item
              name='Leave request time period'
              label='Leave request time period'
              rules={[{ required: true, message: 'Please select a time period' }]}
            >
              <Select
                disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                showSearch
                placeholder='Select leave request time period type'
                onChange={this.updateLeaveRequestTimePeriod}
                filterOption={(input, option) => {
                  const opt = option!.item || option!.children || ''
                  return opt.toLowerCase().includes(input.toLowerCase())
                }}
              >
                {presenter.getLeaveTimePeriods().map((r, i) => {
                  return (
                    <Option key={i} value={r.value}>
                      {r.value}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            {presenter.getSelectedTimePeriod() === lateStartPeriodType && (
              <Form.Item name='Late Start Date' label='When are you coming into office?'>
                <DatePicker
                  disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                  disabledDate={date => {
                    const dateMoment = moment.tz(date, 'America/Denver')
                    const now = moment.tz('America/Denver')
                    if (dateMoment.isBefore(now.endOf('day'))) {
                      return true
                    }
                    return false
                  }}
                  showTime={{ format: 'hh:mm' }}
                  format='YYYY-MM-DD hh:mm a'
                  onChange={this.updateLateArrival}
                />
              </Form.Item>
            )}

            {presenter.getSelectedTimePeriod() === earlyEndPeriodType && (
              <Form.Item name='Early End Date Range' label='When are you leaving office?'>
                <DatePicker
                  disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                  disabledDate={date => {
                    const dateMoment = moment.tz(date, 'America/Denver')
                    const now = moment.tz('America/Denver')
                    if (dateMoment.isBefore(now.endOf('day'))) {
                      return true
                    }
                    return false
                  }}
                  showTime={{ format: 'hh:mm' }}
                  format='YYYY-MM-DD hh:mm a'
                  onChange={this.updateEarlyLeave}
                />
              </Form.Item>
            )}

            {presenter.getSelectedTimePeriod() === singleDayPeriodType && (
              <Form.Item name='Leave Date' label='Leave date'>
                <DatePicker
                  disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                  disabledDate={date => {
                    const dateMoment = moment.tz(date, 'America/Denver')
                    const now = moment.tz('America/Denver')
                    if (dateMoment.isBefore(now.endOf('day'))) {
                      return true
                    }
                    return false
                  }}
                  onChange={this.updateLeaveSingleDay}
                />
              </Form.Item>
            )}

            {presenter.getSelectedTimePeriod() === multipleDaysPeriodType && (
              <Form.Item name='Leave Date' label='Leave duration'>
                <RangePicker
                  disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                  disabledDate={date => {
                    const dateMoment = moment.tz(date, 'America/Denver')
                    const now = moment.tz('America/Denver')
                    if (dateMoment.isBefore(now.endOf('day'))) {
                      return true
                    }
                    return false
                  }}
                  onChange={this.updateLeaveMultipleDays}
                />
              </Form.Item>
            )}

            <Form.Item name='sendSms'>
              <Checkbox
                disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                onChange={e => presenter.updateSendSms(e.target.checked)}
              >
                Send SMS?
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={presenter.isLoading() || presenter.hasSubmittedForm()}
                loading={presenter.isLoading()}
                type='primary'
                htmlType='submit'
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    )
  }

  update = (): void => {
    this.setState({})
  }

  private updateEmployee = (userName: string): void => {
    this.props.presenter.updateSelectedPerson(userName)
  }

  private updateDetails = (event: any): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateAdditionalComment(target.value)
  }

  private updateLeaveRequestReason = (reason: string): void => {
    this.props.presenter.updateLeaveRequestReason(reason)
  }

  private updateLeaveRequestTimePeriod = (timePeriod: string): void => {
    this.props.presenter.updateLeaveRequestTimePeriod(timePeriod)
  }

  // @ts-ignore
  private updateLeaveSingleDay = (dates: any): void => {
    if (dates) {
      const startDate = moment
        .tz('America/Denver')
        .year(dates.year())
        .month(dates.month())
        .date(dates.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      const returnDate = moment
        .tz('America/Denver')
        .year(dates.year())
        .month(dates.month())
        .date(dates.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'days')
      this.props.presenter.updateLeaveRequestStartDate(startDate)
      this.props.presenter.updateLeaveRequestEndDate(returnDate)
    }
  }

  // @ts-ignore
  private updateLeaveMultipleDays = (dates: any): void => {
    if (dates && dates.length === 2 && dates.every((date: moment.Moment) => date)) {
      const startDate = moment
        .tz('America/Denver')
        .year(dates[0].year())
        .month(dates[0].month())
        .date(dates[0].date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      const returnDate = moment
        .tz('America/Denver')
        .year(dates[1].year())
        .month(dates[1].month())
        .date(dates[1].date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'days')
      this.props.presenter.updateLeaveRequestStartDate(startDate)
      this.props.presenter.updateLeaveRequestEndDate(returnDate)
    }
  }

  // @ts-ignore
  private updateLateArrival = (dates: any): void => {
    if (dates) {
      const startDate = moment
        .tz('America/Denver')
        .year(dates.year())
        .month(dates.month())
        .date(dates.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      const returnDate = moment
        .tz('America/Denver')
        .year(dates.year())
        .month(dates.month())
        .date(dates.date())
        .hour(dates.hour())
        .minute(0)
        .second(0)
        .millisecond(0)
      this.props.presenter.updateLeaveRequestStartDate(startDate)
      this.props.presenter.updateLeaveRequestEndDate(returnDate)
    }
  }

  // @ts-ignore
  private updateEarlyLeave = (dates: any): void => {
    if (dates) {
      const startDate = moment
        .tz('America/Denver')
        .year(dates.year())
        .month(dates.month())
        .date(dates.date())
        .hour(dates.hour())
        .minute(0)
        .second(0)
        .millisecond(0)
      const returnDate = moment
        .tz('America/Denver')
        .year(dates.year())
        .month(dates.month())
        .date(dates.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'days')
      this.props.presenter.updateLeaveRequestStartDate(startDate)
      this.props.presenter.updateLeaveRequestEndDate(returnDate)
    }
  }

  private submitForm = (): void => {
    this.props.presenter.submitForm()
  }
}

export default EmployeeLeaveRequest
