import './style.css'

import { SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Divider, Empty, Form, FormInstance, Layout, Row, Space, Spin } from 'antd'
import moment from 'moment-timezone'
import React, { Component, Fragment, ReactElement } from 'react'

import Footer from '../../Common/Footer/Footer'
import Header from '../../Common/Header/Header'
import { redirectIfNotAuthorized } from '../../constant'
import ISubscribingView from '../../Model/Interfaces/ISubscribingViewPresenter'
import ClientRescheduleColumn from './ClientRescheduleColumn/ClientRescheduleColumn'
import IReschedulerPresenter from './Presenter/IReschedulerPresenter'
import StagingModal from './StagingModal/StagingModal'
import TherapistRescheduleColumn from './TherapistRescheduleColumn/TherapistRescheduleColumn'

type Props = {
  presenter: IReschedulerPresenter
}

class Rescheduler extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  async componentDidMount(): Promise<void> {
    await redirectIfNotAuthorized()
    this.props.presenter.setView(this)
    this.formRef.current!.setFields([
      {
        name: ['rangePicker'],
        value: [
          this.props.presenter.getSelectedDateRange().startDate ? moment.tz(this.props.presenter.getSelectedDateRange().startDate, 'America/Denver') : null,
          this.props.presenter.getSelectedDateRange().endDate ? moment.tz(this.props.presenter.getSelectedDateRange().endDate, 'America/Denver') : null
        ]
      }
    ])
    await this.props.presenter.fetchPendingRescheduleAppointments()
  }

  render(): ReactElement {
    const { presenter } = this.props
    const { Content } = Layout
    const { RangePicker } = DatePicker

    return (
      <Layout className='rescheduler'>
        <Header id='dashboard-header' />
        <Content
          className='site-layout'
          style={{ backgroundColor: 'white', padding: '0 50px' }}
        >
          <Divider orientation='center'>Rescheduler</Divider>
          <p>Select a date range that you want to reschedule</p>
          <Form id='rescheduler-filter' ref={this.formRef}>
            <Row>
              <Form.Item
                label='Date Range'
                name='rangePicker'
                rules={[
                  {
                    required: true,
                    message: 'Please select a date range'
                  }
                ]}
              >
                <RangePicker
                  disabled={presenter.getIsLoading()}
                  format='MM/DD/YYYY hh:mm a'
                  onChange={this.updateSelectedDateRange}
                />
              </Form.Item>
              <Button
                disabled={presenter.getIsLoading() || presenter.hasNoCompleteSelectedDateRange()}
                loading={presenter.getIsLoading()}
                type='primary'
                onClick={() => presenter.fetchPendingRescheduleAppointments()}
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Row>
          </Form>
          {!presenter.getClientSessions().length && presenter.getTherapistSessions.length ? (
            <Empty
              description={
                <span>
                  There are currently no appointments pending reschedules in the selected date
                  range.
                </span>
              }
            />
          ) : presenter.getIsLoading() ? (
            <Fragment>
              <Divider orientation='center'>Loading results</Divider>
              <div className='rescheduler-loadingContent'>
                <Space size='middle'>
                  <Spin size='large' />
                </Space>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {presenter.getErrorMessage() && <p className='error'>Error: {presenter.getErrorMessage()}</p>}
              <Row className='rescheduler-needSubsColumnWrapper'>
                <ClientRescheduleColumn presenter={presenter.getClientRescheduleColumnPresenter()} />
                <TherapistRescheduleColumn presenter={presenter.getTherapistReschedulerColumnPresenter()} />
              </Row>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {presenter.hasStagedAppointments() ? (
                  <Button
                    className='rescheduler-submitButton'
                    disabled={presenter.getIsLoading()}
                    loading={presenter.getIsLoading()}
                    onClick={this.openStagingModal}
                    type='primary'
                  >
                    Reschedule Appointments
                  </Button>
                ) : null}
              </div>
            </Fragment>
          )}
          <StagingModal presenter={presenter.getStagingModalPresenter()} />
        </Content>
        <Footer />
      </Layout>
    )
  }

  update = (): void => {
    this.setState({})
  }

  // @ts-ignore
  private updateSelectedDateRange = (dates: any): void => {
    if (dates) {
      const startDate = moment
        .tz('America/Denver')
        .year(dates[0].year())
        .month(dates[0].month())
        .date(dates[0].date())
        .startOf('day')
      const endDate = moment
        .tz('America/Denver')
        .year(dates[1].year())
        .month(dates[1].month())
        .date(dates[1].date())
        .endOf('day')
      this.formRef.current!.setFields([
        {
          name: ['rangePicker'],
          value: [moment.tz(startDate, 'America/Denver'), moment.tz(endDate, 'America/Denver')]
        }
      ])
      this.props.presenter.setSelectedDateRange({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      })
    } else {
      this.props.presenter.setSelectedDateRange({
        startDate: null,
        endDate: null
      })
    }
  }

  private openStagingModal = () => {
    this.props.presenter.getStagingModalPresenter().setStagingData({
      clientSessions: this.props.presenter.getClientRescheduleColumnPresenter().getStagedSessions(),
      therapistSessions: this.props.presenter.getTherapistReschedulerColumnPresenter().getStagedSessions()
    })
    this.props.presenter.getStagingModalPresenter().setIsOpenModal(true)
    this.update()
  }
}

export default Rescheduler
