import { Col, DatePicker, Form, FormInstance, Input, Modal, Row, Select } from 'antd'
import moment from 'moment-timezone'
import React, { Component, ReactElement } from 'react'

import AmericanStates from '../../../../Common/StateOptions'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import IAddPersonFormPresenter from '../Presenter/IAddPersonFormPresenter'

type Props = {
  presenter: IAddPersonFormPresenter
}

class TherapistForm extends Component<Props> implements ISubscribingView {
  formRef = React.createRef<FormInstance>()

  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { Option } = Select
    const { presenter } = this.props

    return (
      <Modal
        centered
        title='Create New Therapist Profile'
        visible={presenter.getOpenModal()}
        okText='Create Therapist Profile'
        onOk={this.submitForm}
        confirmLoading={presenter.getIsLoading()}
        onCancel={this.cancelForm}
        width={800}
      >
        {presenter.getErrorMessage() ? (
          <div className='error'>* {presenter.getErrorMessage()} *</div>
        ) : null}
        <Form
          initialValues={presenter.getFormData()}
          layout='vertical'
          name='therapistDetailsForm'
          scrollToFirstError
          ref={this.formRef}
        >
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label='First Name'
                name='therapistFirstName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a first name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setFirstName(e.target.value)}
                  value={presenter.getFormData().firstName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Middle Name' name='therapistMiddleName'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setMiddleName(e.target.value)}
                  value={presenter.getFormData().middleName || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='Last Name'
                name='therapistLastName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a last name'
                  }
                ]}
              >
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setLastName(e.target.value)}
                  value={presenter.getFormData().lastName || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item label='Street 1' name='therapistStreet1'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setStreet1(e.target.value)}
                  value={presenter.getFormData().street1 || ''}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label='Street 2' name='therapistStreet2'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setStreet2(e.target.value)}
                  value={presenter.getFormData().street2 || ''}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='City' name='therapistCity'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setCity(e.target.value)}
                  value={presenter.getFormData().city || ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item label='State' name='clientState'>
                <Select
                  disabled={presenter.getIsLoading()}
                  onChange={(state) => presenter.setState(state)}
                  value={presenter.getFormData().state}
                >
                  {AmericanStates.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Zip Code' name='therapistZipCode'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setZipCode(e.target.value)}
                  value={presenter.getFormData().zipCode || ''}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Service Center'
                name='therapistServiceCenter'
                rules={[
                  {
                    required: true,
                    message: 'Please select a service center'
                  }
                ]}
              >
                <Select
                  disabled={presenter.getIsLoading()}
                  onChange={(serviceCenter) => presenter.setServiceCenter(serviceCenter)}
                  value={presenter.getFormData().serviceCenter}
                >
                  <Option value='Aurora'>Aurora</Option>
                  <Option value='Wheat Ridge'>Wheat Ridge</Option>
                  <Option value='Both'>Both</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Start Date' name='therapistStartDate'>
                <DatePicker allowClear onChange={this.updateStartDate} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label='Position' name='position'>
                <Select disabled value={presenter.getFormData().position || ''}>
                  <Option value='THERAPIST'>Therapist</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Phone' name='therapistPhone'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setPhone(e.target.value)}
                  value={presenter.getFormData().phone || ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Email' name='therapistEmail'>
                <Input
                  disabled={presenter.getIsLoading()}
                  onChange={(e) => presenter.setEmail(e.target.value)}
                  value={presenter.getFormData().email || ''}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }

  update(): void {
    this.setState({})
  }

  private updateStartDate = (date: any): void => {
    if (date) {
      const startDate = moment
        .tz('America/Denver')
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      this.props.presenter.setStartDate(startDate.toISOString())
    } else {
      this.props.presenter.setStartDate(null)
    }
  }

  private submitForm = async () => {
    await this.formRef.current!.validateFields()
    await this.props.presenter.submitTherapistForm()
    if (!this.props.presenter.getErrorMessage()) {
      this.cancelForm()
    }
  }

  private cancelForm = () => {
    this.props.presenter.resetFormData()
    this.formRef.current!.resetFields()
    this.props.presenter.setOpenModal(false)
  }
}

export default TherapistForm
