import { ColumnsType } from "antd/lib/table"

import ApiClientDto from "../../../ClientCrud/ApiClientDto"
import IClientGetter from "../../../ClientCrud/ClientGetter/IClientGetter"
import ApiEmployeeDto from "../../../EmployeeCrud/ApiEmployeeDto"
import IEmployeeGetter from "../../../EmployeeCrud/EmployeeGetter/IEmployeeGetter"
import ISubscribingView from "../../../Model/Interfaces/ISubscribingViewPresenter"
import IRtsCreator from "../../../RtsCrud/RtsCreator/IRtsCreator"
import IRtsGetter from "../../../RtsCrud/RtsGetter/IRtsGetter"
import AddRtsFormPresenter from "../AddRtsForm/Presenter/AddRtsFormPresenter"
import IAddRtsFormPresenter from "../AddRtsForm/Presenter/IAddRtsFormPresenter"
import RtsRecord from "../Interfaces/RtsRecord"
import ClientRtsColumns from "../TableColumnTypes/ClientRtsColumns"
import TherapistRtsColumns from "../TableColumnTypes/TherapistRtsColumns"
import IRtsPresenter from "./IRtsPresenter"

class RtsPresenter implements IRtsPresenter {
  private clientOptions: ApiClientDto[]
  private therapistOptions: ApiEmployeeDto[]
  private targetUuid: string | null
  private targetType: string | null
  private rtsRecords: RtsRecord[]
  private isLoading: boolean
  private addRtsFormPresenter: IAddRtsFormPresenter
  private view: ISubscribingView | null

  constructor(
    private readonly rtsGetter: IRtsGetter,
    private readonly rtsCreator: IRtsCreator,
    private readonly clientGetter: IClientGetter,
    private readonly employeeGetter: IEmployeeGetter
  ) {
    this.clientOptions = []
    this.therapistOptions = []
    this.targetUuid = null
    this.targetType = null
    this.rtsRecords = []
    this.isLoading = false
    this.addRtsFormPresenter = new AddRtsFormPresenter(this.rtsCreator, this)
    this.view = null
    this.initialize()
  }

  public async fetchSelectOptions(): Promise<void> {
    this.isLoading = true
    this.updateView()
    const options = await Promise.all([
      this.clientGetter.searchClientProfiles(null, null, null, null, null, null, null, true),
      this.employeeGetter.searchEmployeeProfiles(null, null, null, 'THERAPIST', null, null, null, null, null, true)
    ])
    this.clientOptions = options[0]
    this.therapistOptions = options[1]
    this.isLoading = false
    this.updateView()
  }

  public async fetchRtsRecords(): Promise<void> {
    if (!this.targetUuid || !this.targetType) {
      return
    }
    this.isLoading = true
    this.updateView()
    if (this.targetType === 'CLIENT') {
      this.rtsRecords = (await this.rtsGetter.getRtsForClient(this.targetUuid)).map(therapist => {
        let fullName = therapist.firstName || ''
        if (therapist.middleName) {
          fullName = fullName + ' ' + therapist.middleName
        }
        if (therapist.lastName) {
          fullName = fullName + ' ' + therapist.lastName
        }
        return {
          fullName,
          uuid: therapist.uuid as string,
          initials: null,
          phone: therapist.phone,
          email: therapist.email,
          serviceCenter: therapist.serviceCenter as string,
          key: therapist.uuid
        }
      })
    } else if (this.targetType === 'THERAPIST') {
      this.rtsRecords = (await this.rtsGetter.getRtsForTherapist(this.targetUuid)).map(client => {
        let fullName = client.firstName || ''
        if (client.middleName) {
          fullName = fullName + ' ' + client.middleName
        }
        if (client.lastName) {
          fullName = fullName + ' ' + client.lastName
        }
        return {
          fullName,
          uuid: client.uuid as string,
          initials: client.initials,
          phone: null,
          email: null,
          serviceCenter: client.serviceCenter as string,
          key: client.uuid
        }
      })
    }
    this.isLoading = false
    this.updateView()
  }

  public getRtsRecords(): RtsRecord[] {
    return this.rtsRecords
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getTableColumns(): ColumnsType<RtsRecord> {
    return this.targetType === 'CLIENT' ? ClientRtsColumns(this.targetUuid as string, this) : TherapistRtsColumns(this.targetUuid as string, this)
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public getTherapistOptions(): ApiEmployeeDto[] {
    return this.therapistOptions
  }

  public getAddRtsFormPresenter(): IAddRtsFormPresenter {
    return this.addRtsFormPresenter
  }

  public setTargetUuid(uuid: string | null): void {
    this.targetUuid = uuid
  }

  public setTargetType(type: string | null): void {
    this.targetType = type
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public async deleteRts(clientId: string, therapistId: string): Promise<void> {
    await this.rtsCreator.deleteRtsRecord(clientId, therapistId)
    await this.fetchRtsRecords()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private async initialize(): Promise<void> {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default RtsPresenter