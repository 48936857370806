import moment from 'moment-timezone'

import ApiBcbaDto from '../../../BcbaCrud/ApiBcbaDto'
import IBcbaGetter from '../../../BcbaCrud/BcbaGetter/Interfaces/IBcbaGetter'
import ApiClientDto from '../../../ClientCrud/ApiClientDto'
import IClientGetter from '../../../ClientCrud/ClientGetter/IClientGetter'
import ApiEmployeeDto from '../../../EmployeeCrud/ApiEmployeeDto'
import IEmployeeGetter from '../../../EmployeeCrud/EmployeeGetter/IEmployeeGetter'
import DateRangeDto from '../../../Model/Dtos/DateRangeDto'
import ISubscribingView from '../../../Model/Interfaces/ISubscribingViewPresenter'
import INotificationCreator from '../../../Notifications/NotificationCreator/INotificationCreator'
import ApiReschedulerSessionDto from '../../../Scheduling/Dtos/ApiReschedulerSessionDto'
import NeedSubsDto from '../../../Scheduling/Dtos/NeedSubsDto'
import IRescheduleCreator from '../../../Scheduling/RescheduleCreator/IRescheduleCreator'
import IReschedulerGetter from '../../../Scheduling/ReschedulerGetter/IReschedulerGetter'
import ClientRescheduleColumnPresenter from '../ClientRescheduleColumn/Presenter/ClientRescheduleColumnPresenter'
import IClientRescheduleColumnPresenter from '../ClientRescheduleColumn/Presenter/IClientRescheduleColumnPresenter'
import IStagingModalPresenter from '../StagingModal/Presenter/IStagingModalPresenter'
import StagingModalPresenter from '../StagingModal/Presenter/StagingModalPresenter'
import ITherapistRescheduleColumnPresenter from '../TherapistRescheduleColumn/Presenter/ITherapistRescheduleColumnPresenter'
import TherapistRescheduleColumnPresenter from '../TherapistRescheduleColumn/Presenter/TherapistRescheduleColumnPresenter'
import IReschedulerPresenter from './IReschedulerPresenter'

class ReschedulerPresenter implements IReschedulerPresenter {
  private clientRescheduleColumnPresenter: IClientRescheduleColumnPresenter
  private therapistRescheduleColumnPresenter: ITherapistRescheduleColumnPresenter
  private stagingModalPresenter: IStagingModalPresenter
  private clientOptions: ApiClientDto[]
  private therapistOptions: ApiEmployeeDto[]
  private bcbaOptions: ApiBcbaDto[]
  private clientSessions: ApiReschedulerSessionDto[]
  private therapistSessions: ApiReschedulerSessionDto[]
  private selectedDateRange: DateRangeDto
  private errorMessage: string | null
  private isLoading: boolean
  private view: ISubscribingView | null

  constructor(
    private readonly reschedulerGetter: IReschedulerGetter,
    private readonly rescheduleCreator: IRescheduleCreator,
    private readonly notificationCreator: INotificationCreator,
    private readonly clientGetter: IClientGetter,
    private readonly employeeGetter: IEmployeeGetter,
    private readonly bcbaGetter: IBcbaGetter
  ) {
    this.clientRescheduleColumnPresenter = new ClientRescheduleColumnPresenter(this)
    this.therapistRescheduleColumnPresenter = new TherapistRescheduleColumnPresenter(this)
    this.stagingModalPresenter = new StagingModalPresenter(this.rescheduleCreator, this.notificationCreator, this)
    this.clientOptions = []
    this.therapistOptions = []
    this.bcbaOptions = []
    this.clientSessions = []
    this.therapistSessions = []
    this.selectedDateRange = {
      startDate: moment.tz('America/Denver').startOf('day').toISOString(),
      endDate: moment.tz('America/Denver').endOf('day').toISOString()
    }
    this.isLoading = false
    this.errorMessage = null
    this.view = null
    this.initialize()
  }

  public async fetchPendingRescheduleAppointments(): Promise<void> {
    const { endDate, startDate } = this.selectedDateRange
    this.setIsLoading(true)
    try {
      const [clients, therapists, bcbas] = await Promise.all([
        this.clientGetter.searchClientProfiles(null, null, null, null, null, null, null, true, true),
        this.employeeGetter.searchEmployeeProfiles(null, null, null, 'THERAPIST', null, null, null, null, null, true),
        this.bcbaGetter.searchBcbaProfiles(null, null, null, null, null)
      ])
      this.clientOptions = clients
      this.therapistOptions = therapists
      this.bcbaOptions = bcbas
      const result = await this.reschedulerGetter.getAllSubs({
        startDate,
        endDate
      }) as NeedSubsDto
      this.clientSessions = result.clientSessions
      this.therapistSessions = result.therapistSessions
      this.clientRescheduleColumnPresenter.setSessions(result.clientSessions.map(session => ({
        id: session.id,
        clientId: session.clientId,
        therapistId: session.therapistId,
        bcbaId: session.bcbaId,
        scheduledStart: session.scheduledStart,
        scheduledEnd: session.scheduledEnd,
        comments: session.comments,
        action: 'RESCHEDULE',
        rtsTherapists: session.rtsTherapists,
        sendSms: true
      })))
      this.therapistRescheduleColumnPresenter.setSessions(result.therapistSessions.map(session => ({
        id: session.id,
        clientId: session.clientId,
        therapistId: session.therapistId,
        bcbaId: session.bcbaId,
        scheduledStart: session.scheduledStart,
        scheduledEnd: session.scheduledEnd,
        comments: session.comments,
        action: 'RESCHEDULE',
        rtsClients: session.rtsClients,
        sendSms: true
      })))
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.setIsLoading(false)
  }

  public getClientRescheduleColumnPresenter(): IClientRescheduleColumnPresenter {
    return this.clientRescheduleColumnPresenter
  }

  public getTherapistReschedulerColumnPresenter(): ITherapistRescheduleColumnPresenter {
    return this.therapistRescheduleColumnPresenter
  }

  public getStagingModalPresenter(): IStagingModalPresenter {
    return this.stagingModalPresenter
  }

  public getClientSessions(): ApiReschedulerSessionDto[] {
    return this.clientSessions
  }

  public getTherapistSessions(): ApiReschedulerSessionDto[] {
    return this.therapistSessions
  }

  public getSelectedDateRange(): DateRangeDto {
    return this.selectedDateRange
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public getTherapistOptions(): ApiEmployeeDto[] {
    return this.therapistOptions
  }

  public getBcbaOptions(): ApiBcbaDto[] {
    return this.bcbaOptions
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public hasNoCompleteSelectedDateRange(): boolean {
    return !this.selectedDateRange.startDate || !this.selectedDateRange.endDate
  }

  public hasStagedAppointments(): boolean {
    return this.clientRescheduleColumnPresenter.getStagedSessions().length > 0 || this.therapistRescheduleColumnPresenter.getStagedSessions().length > 0
  }

  public setSelectedDateRange(dateRange: DateRangeDto): void {
    this.selectedDateRange = dateRange
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
    this.updateView()
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private initialize(): void {
    this.updateView()
  }
}

export default ReschedulerPresenter
