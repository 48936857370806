import IBcbaCreator from '../../../../BcbaCrud/BcbaCreator/IBcbaCreator'
import ApiClientDto from '../../../../ClientCrud/ApiClientDto'
import IClientCreator from '../../../../ClientCrud/ClientCreator/IClientCreator'
import IClientGetter from '../../../../ClientCrud/ClientGetter/IClientGetter'
import GuardianRelationshipDto from '../../../../ClientCrud/GuardianRelationshipDto'
import IEmployeePoster from '../../../../EmployeeCrud/EmployeePoster/IEmployeePoster'
import ApiGuardianDto from '../../../../GuardianCrud/ApiGuardianDto'
import IGuardianGetter from '../../../../GuardianCrud/GuardianGetter/IGuardianGetter'
import IGuardianPoster from '../../../../GuardianCrud/GuardianPoster/IGuardianPoster'
import ISubscribingView from '../../../../Model/Interfaces/ISubscribingViewPresenter'
import FormData from '../Interfaces/FormData'
import IAddPersonFormPresenter from './IAddPersonFormPresenter'

const initialFormState = {
  firstName: null,
  middleName: null,
  lastName: null,
  initials: null,
  position: 'THERAPIST',
  phone: null,
  email: null,
  street1: null,
  street2: null,
  city: null,
  state: null,
  zipCode: null,
  serviceCenter: null,
  therapyStartDate: null,
  therapyEndDate: null,
  startDate: null,
  terminationDate: null,
  guardians: [],
  clients: []
}

class AddPersonFormPresenter implements IAddPersonFormPresenter {
  private formData: FormData
  private clientOptions: ApiClientDto[]
  private guardianOptions: ApiGuardianDto[]
  private openModal: boolean
  private isLoading: boolean
  private view: ISubscribingView | null
  private errorMessage: string | null

  constructor(
    private readonly clientGetter: IClientGetter,
    private readonly clientPoster: IClientCreator,
    private readonly employeePoster: IEmployeePoster,
    private readonly guardianGetter: IGuardianGetter,
    private readonly guardianPoster: IGuardianPoster,
    private readonly bcbaCreator: IBcbaCreator
  ) {
    this.formData = JSON.parse(JSON.stringify(initialFormState))
    this.clientOptions = []
    this.guardianOptions = []
    this.openModal = false
    this.isLoading = false
    this.view = null
    this.errorMessage = null
    this.initialize()
  }

  public getFormData(): FormData {
    return this.formData
  }

  public getGuardianOptions(): ApiGuardianDto[] {
    return this.guardianOptions
  }

  public getClientOptions(): ApiClientDto[] {
    return this.clientOptions
  }

  public async fetchGuardianOptions(): Promise<void> {
    this.guardianOptions = await this.guardianGetter.searchGuardianProfiles(
      null,
      null,
      null,
      null,
      null
    )
  }

  public async fetchClientOptions(): Promise<void> {
    this.clientOptions = await this.clientGetter.searchClientProfiles(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }

  public setFirstName(firstName: string | null): void {
    this.formData.firstName = firstName || null
  }

  public setMiddleName(middleName: string | null): void {
    this.formData.middleName = middleName || null
  }

  public setLastName(lastName: string | null): void {
    this.formData.lastName = lastName || null
  }

  public setPhone(phone: string | null): void {
    this.formData.phone = phone || null
  }

  public setEmail(email: string | null): void {
    this.formData.email = email || null
  }

  public setStreet1(street1: string | null): void {
    this.formData.street1 = street1 || null
  }

  public setStreet2(street2: string | null): void {
    this.formData.street2 = street2 || null
  }

  public setCity(city: string | null): void {
    this.formData.city = city || null
  }

  public setState(state: string | null): void {
    this.formData.state = state || null
  }

  public setZipCode(zipCode: string | null): void {
    this.formData.zipCode = zipCode || null
  }

  public setServiceCenter(serviceCenter: string | null): void {
    this.formData.serviceCenter = serviceCenter || null
  }

  public setInitials(initials: string | null): void {
    this.formData.initials = initials || null
  }

  public setStartDate(startDate: string | null): void {
    this.formData.startDate = startDate || null
  }

  public setTherapyStartDate(therapyStartDate: string | null): void {
    this.formData.therapyStartDate = therapyStartDate || null
  }

  public setGuardians(guardians: GuardianRelationshipDto[]): void {
    this.formData.guardians = guardians
  }

  public getOpenModal(): boolean {
    return this.openModal
  }

  public setOpenModal(openModal: boolean): void {
    this.resetFormData()
    this.openModal = openModal
    this.updateView()
  }

  public getIsLoading(): boolean {
    return this.isLoading
  }

  public getErrorMessage(): string | null {
    return this.errorMessage
  }

  public resetFormData(): void {
    this.formData = JSON.parse(JSON.stringify(initialFormState))
    this.errorMessage = null
  }

  public async submitClientForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.validateInitials()
      await this.clientPoster.createClient({
        uuid: null,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        initials: this.formData.initials,
        street1: this.formData.street1,
        street2: this.formData.street2,
        city: this.formData.city,
        state: this.formData.state,
        zipCode: this.formData.zipCode,
        serviceCenter: this.formData.serviceCenter,
        therapyStartDate: this.formData.therapyStartDate,
        therapyEndDate: null,
        active: null,
        guardians: this.formData.guardians
      })
      await Promise.all([this.fetchClientOptions, this.fetchGuardianOptions])
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async submitTherapistForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.employeePoster.createEmployee({
        uuid: null,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        position: this.formData.position,
        phone: this.formData.phone,
        email: this.formData.email,
        serviceCenter: this.formData.serviceCenter,
        street1: this.formData.street1,
        street2: this.formData.street2,
        city: this.formData.city,
        state: this.formData.state,
        zipCode: this.formData.zipCode,
        startDate: this.formData.startDate,
        terminationDate: null,
        active: null
      })
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async submitGuardianForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      const newGuardian = await this.guardianPoster.createGuardian({
        uuid: null,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email
      })
      await Promise.all(
        this.formData.clients.map(async (client) => {
          return await this.guardianPoster.attachGuardianToClients(
            client.clientId as string,
            newGuardian.uuid as string,
            client.relationship as string
          )
        })
      )
      await this.fetchGuardianOptions()
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public async submitBcbaForm(): Promise<void> {
    this.isLoading = true
    this.errorMessage = null
    this.updateView()
    try {
      await this.bcbaCreator.createBcba({
        uuid: null,
        firstName: this.formData.firstName,
        middleName: this.formData.middleName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email
      })
    } catch (error) {
      this.errorMessage = JSON.stringify(error)
    }
    this.isLoading = false
    this.updateView()
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private initialize(): void {
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private async validateInitials(): Promise<void> {
    const clients = await this.clientGetter.searchClientProfiles(
      null,
      null,
      null,
      this.formData.initials,
      null,
      null,
      null,
      null
    )
    if (clients.length > 0) {
      throw `A client with the initials: ${this.formData.initials} already exists`
    }
  }
}

export default AddPersonFormPresenter
