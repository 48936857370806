import './style.css'
import 'antd/dist/antd.css'

import { Col, Layout, Row } from 'antd'
import moment from 'moment-timezone'
import React, { Component, ReactElement } from 'react'

import Footer from '../../Common/Footer/Footer'
import Header from '../../Common/Header/Header'
import { redirectIfNotAuthorized } from '../../constant'
import LeaverDashboard from './LeaverDashboard/LeaverDashboard'
import IDashboardPresenterFactory from './Presenter/IDashboardPresenterFactory'

type Props = {
  presenter: IDashboardPresenterFactory
}

class Dashboard extends Component<Props> {
  state = {
    startDate: moment.tz('America/Denver').hour(0).minute(0).second(0).millisecond(0),
    endDate: moment.tz('America/Denver').hour(0).minute(0).second(0).millisecond(0).add(1, 'days')
  }

  async componentDidMount(): Promise<void> {
    await redirectIfNotAuthorized()
  }

  render(): ReactElement {
    const { Content } = Layout
    const { presenter } = this.props

    return (
      <Layout>
        <Header id='dashboard-header' />
        <Content className='site-layout' style={{ backgroundColor: 'white', padding: '0 50px' }}>
          <Row gutter={32}>
            <Col span={12}>
              <LeaverDashboard
                formTitle='Employee Leave Requests'
                presenter={presenter.getEmployeeLeaverPresenter()}
                requestStatusUpdateHandler={presenter.getLeaveRequestStatusUpdater()}
              />
            </Col>
            <Col span={12}>
              <LeaverDashboard
                formTitle='Client Absence Notifications'
                presenter={presenter.getClientLeaverPresenter()}
                requestStatusUpdateHandler={presenter.getLeaveRequestStatusUpdater()}
              />
            </Col>
          </Row>
        </Content>
        <Footer />
      </Layout>
    )
  }
}

export default Dashboard
