import { observable } from 'mobx'

import Fetcher from '../Drivers/Fetcher'
import EmployeeDto from '../EmployeeCrud/EmployeeDto'
import EmployeeGetter from '../EmployeeCrud/EmployeeGetter/EmployeeGetter'

const employeeGetter = new EmployeeGetter(new Fetcher())
const employeeStore = observable({
  getEmployees: async (): Promise<void> => {
    employeeStore.employees = await employeeGetter.getEmployees()
  },
  getEmployeeNames: (): { value: string }[] => {
    return employeeStore.employees.map((e) => {
      return {
        value: e.name
      }
    })
  },
  getEmployeeNameById: (id: string): string => {
    const employee = employeeStore.employees.find((emp) => emp.id === id)

    if (!employee) {
      return 'Unknown employee'
    }

    return employee.name
  },
  employees: [] as EmployeeDto[]
})

export default employeeStore
