import Cookies from 'js-cookie'

import { acceptedHttpCode, badRequestHttpCode, noContentHttpCode } from '../constant'
import RequestResponse from '../Model/Dtos/RequestResponse'
import IFetcher from './IFetcher'

class Fetcher implements IFetcher {
  public async fetch(params: { body: any; method: string; url: string }): Promise<any> {
    const { body, method, url } = params

    const headers: any = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('auth')}`
    }

    const result = await fetch(url, {
      method,
      headers,
      body: method === 'GET' ? undefined : JSON.stringify(body)
    })

    if (result) {
      if (result.status === acceptedHttpCode) {
        return this.getAcceptedRequestResponse()
      }
      if (result.status === noContentHttpCode) {
        return this.getNoContentRequestResponse()
      }
      if (result.status === badRequestHttpCode) {
        throw (await result.json()).message
      }
      const jsonString = await result.text()
      return jsonString === '' ? null : JSON.parse(jsonString)
    }

    return null
  }

  private getAcceptedRequestResponse(): RequestResponse {
    return {
      code: acceptedHttpCode,
      message: 'Accepted'
    }
  }

  private getNoContentRequestResponse(): RequestResponse {
    return {
      code: noContentHttpCode,
      message: 'Success. no content.'
    }
  }
}

export default Fetcher
